import { VAT } from './Constants'

export const randomInteger = (min, max) => {
    var rand = min + Math.random() * (max + 1 - min);
    rand = Math.floor(rand);
    return rand;
}

export const  declOfNum = (number, titles) => {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}

export const toFormatRollLength = (number) => {
  // let array = formatFloat(number).toString().split('.');
  let array = number.toString().split('.');
  // console.log('array',array);
  if (array[1]) {
    return declOfNum(+array[1],['метр', 'метра', 'метров']);
  } else {
    return declOfNum(+array[0],['метр', 'метра', 'метров']);
  }
  // return number;
}

export const declOfPrice = (length,type) => {
  switch (type) {
    case 1:
      return declOfNum(length, ['доллар', 'доллара', 'долларов'])
    case 2:
      return declOfNum(length, ['рос. рубль', 'рос. рубля', 'рос. рублей'])
    case 3:
      return declOfNum(length, ['рубль', 'рубля', 'рублей'])
    default:
      return 'не выбрана валюта'
  }
}

export const httpRequest = {
  post : async (url = '', data = {}, headers = {}) => {
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: headers,
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      // body: JSON.stringify(data) // body data type must match "Content-Type" header
      body: data // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
  }
}

export const formatFloat = (number) => {
  return parseFloat(number.toFixed(2))
}

export const formatPhoneNeo = (phoneNumber) => {
  // var lenPhone = phoneNumber.length;
  // console.log(lenPhone);
  if (phoneNumber.charAt(0) === "7") {
    // Russian number
    return phoneNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
    // other var
    // return phoneNumber.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '$1-$2-$3');
  } else {
    // Belarus number
    return phoneNumber.replace(/(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
  }
}

export const addVAT = (price) => {
  return price+(price/100*VAT);
}

export const formatPrice = (price) => {
  price = price/100
  return price.toFixed(2)
}

export const getVAT = (price) => {
  return (price/(100+VAT)*VAT);
}

// check and format for 10.00
export const checkValue = (value) => {
  // eslint-disable-next-line
  let prepareStr = value.replace(/[^.\d]+/g,"").replace(/^([^\.]*\.)|\./g, '$1' )
  let arrFields = prepareStr.split('.');

  // I tried to victory the regexp to format number 10.00 or 100.00
  // Unfortunately, I am using a crutch
  if (arrFields[1]) {
    if (arrFields[1] !== '' && arrFields[1].length <= 2) {
      return prepareStr
    } else {
      return `${arrFields[0]}.${arrFields[1][0]}${arrFields[1][1]}`
    }
  } else {
    return prepareStr
  }
}

// Prices regexp

// Requires a decimal and commas
// ^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?\.\d{1,2}$

// Allows a decimal, requires commas
// (?=.*\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?(\.\d{1,2})?$

// Decimal and commas optional
// (?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$

// Decimals required, commas optional
// ^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?\.\d{1,2}$
