import React, { Component } from 'react';
import axios from 'axios'
// import { Link } from 'react-router-dom';
import { DOMAIN_NAME_API } from '../../Constants'
import userLogo from '../../user.svg'
import * as localeSelect from '../../common/permissions.json'
const localeSelectPermissons = localeSelect.default;

class ListItem extends Component {
  constructor(props) {
      super(props);
      this.state = {
        pinCode: this.props.user.pinCode,
        pinCodeChange: false
      };
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
// _handleClick(){
//
//   console.log("signin",this.state);
//   // axios.post(Generals.DOMAIN_NAME_API + '/categories',
//   //   {
//   //     nameRU: this.state.form1,
//   //     nameEN: this.state.form2
//   //   })
//   // .then(
//   //   () => {
//   //     this.setState({ form1: '', form2: '' });
//   //     this.props.updateData('ADD_CATEGORY');
//   //   }
//   // )
//
// }
// newStock
_handlePinCodeForm = (event) => {
  // eslint-disable-next-line
  let newPinCode = event.target.value.replace(/[^.\d]+/g,"").replace( /^([^\.]*\.)|\./g, '$1' );
  this.setState({ pinCode: newPinCode });
}
_pinCodeChange = (event) => {
  event.persist();
  this.setState({
    pinCodeChange: true,
    pinCode: ''
  })
}
updatePinCode(){
  axios.put(DOMAIN_NAME_API + '/users/'+this.props.user.id, {pinCode: this.state.pinCode })
  .then((res) => {
    console.log(res)
    if (res.data.status === 'ok') {
      this.setState({
        pinCodeChange: false
      })
    }
  })
  .catch((error)=>{console.log(error)})
}
render() {
  // const interfaceLocale = this.props.locale;
  let user = this.props.user;
  let lang = this.state.language || 'ru';
  // console.log(user);
  // let index = this.props.key;
  return (
    <div className="staff-block ml-4">
        <div className="staff-item-photo">
          <img src={userLogo} alt="profile" className="circle float-left profile-photo" width={50} height="auto" />
        </div>
        <div className="staff-item-description">
            <h5>{user.nameRU}</h5>
            <p>{user.nameEN}</p>
        </div>
        <div className="staff-item-message">
            <p>Права: {localeSelectPermissons.permissionsList[user.permission][lang]}</p>
            {
              this.state.pinCodeChange
              ?
              <>
                <p className="mb-2">Новый пинкод</p>
                <div className="input-group">
                  <input type="text" className="input-main" style={{fontSize: 1+'rem', maxWidth: 4+'rem'}} value={this.state.pinCode} onChange={this._handlePinCodeForm.bind(this)} maxLength="4" />
                  <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.updatePinCode()}}>Обновить</button>
                </div>
              </>
              :
              <>
                <p className="mb-1">Доступ к складу:</p>
                {
                  user.pinCode === '0'
                  ? <p className="pinCodeButton" onClick={this._pinCodeChange.bind(this)}>Пин код не установлен</p> : <p className="pinCodeButton" onClick={this._pinCodeChange.bind(this)}>Пинкод установлен</p>
                }
              </>
            }
        </div>
        <div className="staff-item-controls">

        </div>
    </div>
  );
  }
}

export default ListItem;
