import React from 'react';
import { CDN_SERVER } from '../Constants'

const ImageStandart = ({ url, height, customClass }) => {
  if (url) {
    url = url.replace('.webp','.jpg');
  }
  return url ?
  <img className={`img-fluid ${customClass}`} src={CDN_SERVER + url + '?h=' + height} alt="" />
  : <img src={CDN_SERVER + '/static/crm/images/no-image.jpg'} className={`img-fluid ${customClass}`} alt="" />
}

export default ImageStandart
