export default function(state,action){
  switch (action.type) {
    case "SET_SUPPLIERS":
      return {
        ...state, suppliers: action.payload
      }
    case "INITIAL_DELIVERIES":
      return {
        ...state, deliveries: action.payload
      }
    case "SELECT_CLIENT":
      return {
        ...state, selectedSupplier: action.payload
      }
    case "ADD_FABRIC":
      return {
        ...state, suppliedFabrics: [...state.suppliedFabrics, action.payload]
      }
    case "DELETE_FABRIC":
      // assigning the list to temp variable
      const temp = [...state.suppliedFabrics];
      // removing the element using splice
      temp.splice(action.payload.rowID, 1);

      return {
        ...state, suppliedFabrics: temp
      }
    case "SELECT_CURRENCY":
      return {
        ...state, selectedCurrency: action.payload
      }
    default:
      return state
  }
}
