import React, { Component } from 'react';
import Layout from '../Layout';
import SearchCollections from './SearchCollections';
import CollectionItem from './CollectionItem';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { DOMAIN_NAME_API } from '../../Constants'
import svgPlaceholder from '../../svg-placeholder.svg';

import axios from 'axios'
import * as localeRU from './locale_ru.json'
import * as localeEN from './locale_en.json'
const locale = {
    "ru":localeRU.default,
    "en":localeEN.default
  }

class Collections extends Component {
  constructor(props) {
      super(props);
      this.state = {
          collections: [],
          sortedCollections: [],
          searchStatus: false,
          loaded: false
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
    this.reloadColections();
  }
  reloadColections(){
    axios.get(DOMAIN_NAME_API + '/collections?options=ORDER BY id DESC')
    .then(res => {
      var collections = res.data.collections;
      this.setState({
        collections: collections,
        loaded: true
      })
    });
  }
  updateData = (value,data) => {
    console.log(value,data);
    switch(value) {
      case 'ADD_COLLECTION':
        this.reloadColections();
        console.log('ADD_COLLECTION');
        break;
      case 'UPDATE_COLLECTION':
        this.reloadColections();
        console.log('UPDATE_CATEGORY');
        break;
      case 'DELETE_CATEGORY':
        this.reloadColections();
        console.log('DELETE_CATEGORY');
        break;
      case 'SEARCH_COLLECTION':
      if(data.category){
        this.setState({sortedCollections: data.sortedData, searchStatus: true})
      } else if(data.searchValue !== ''){
        this.setState({sortedCollections: data.sortedData, searchStatus: true})
      } else if( data.category && data.searchValue !== ''){
        this.setState({sortedCollections: data.sortedData, searchStatus: true})
      } else {
        this.setState({sortedCollections: [], searchStatus: false})
      }
        console.log('SEARCH_COLLECTION',data);
        break;
      default:
        break;
    }
  }
    render() {
      const selectedLanguage = localStorage.getItem('lang');
      const interfaceLocale = locale[selectedLanguage];
      let helmetTitle = 'Коллекции - CRM Фабрика Фурнитуры';
      let collectionItems = '';
      if (this.state.collections){
        // if(this.state.searchValue !== '') {
        //   collectionItems = this.state.sortedCollections.map((collection, index) => {
        //       return (
        //         <CollectionItem key={index} locale={interfaceLocale} updateData={this.updateData} collection={collection} />
        //       )
        //   });
        // } else
        if (this.state.searchStatus){
          collectionItems = this.state.sortedCollections.map((collection, index) => {
              return (
                <CollectionItem key={index} locale={interfaceLocale} updateData={this.updateData} collection={collection} />
              )
          });
        } else {
          collectionItems = this.state.collections.map((collection, index) => {
              return (
                <CollectionItem key={index} locale={interfaceLocale} updateData={this.updateData} collection={collection} />
              )
          });
        }
      }
      return (
        <Layout match={this.props.match} history={this.props.history}>
          <Helmet>
            <title>{helmetTitle}</title>
          </Helmet>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-8">
                <SearchCollections locale={interfaceLocale} updateData={this.updateData} collections={this.state.collections} />
              </div>
              <div className="col-md-4 text-right">
                <Link className={"btn btn-secondary"} to={"/collections/new"}>Создать</Link>
              </div>
              <div className="col-md-12">
              {
                this.state.loaded ?
                <div className="row">
                  {collectionItems}
                </div> :
                (
                  <div className="row">
                    <div className="col-md-3">
                      <div className="collection-item">
                          <img src={svgPlaceholder} className="img-fluid rounded" alt="alt" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="collection-item">
                          <img src={svgPlaceholder} className="img-fluid rounded" alt="alt" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="collection-item">
                          <img src={svgPlaceholder} className="img-fluid rounded" alt="alt" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="collection-item">
                          <img src={svgPlaceholder} className="img-fluid rounded" alt="alt" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="collection-item">
                          <img src={svgPlaceholder} className="img-fluid rounded" alt="alt" />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="collection-item">
                          <img src={svgPlaceholder} className="img-fluid rounded" alt="alt" />
                      </div>
                    </div>
                  </div>
                )
              }
              </div>
            </div>
          </div>
        </Layout>
      );
    }
}

export default Collections;

// <div className="col-md-3 collection-item">
//   <Link to={"/collections/2"}>
//   <img src="http://ffm.by/uploads/fdf7e7b349a4432a0461189ff1966bbb.jpg" className="img-fluid rounded" alt="image" />
//   <div className="collection-img-overlay">
//     <h5 className="collection-title">Collection title</h5>
//   </div>
//   </Link>
// </div>

// <AddCategory locale={interfaceLocale} updateData={this.updateData} />
