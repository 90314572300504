import React, { Component } from 'react';

class UniversalSelect extends Component {
  constructor(props) {
      super(props);
      this.state = {
          // items: [],
          activeID: 0,
          display: 'none',
          newItem: '',
          sortedItems: []
      };
      this.setWrapper = this.setWrapper.bind(this);
      this.setWrapperInput = this.setWrapperInput.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount(){
    document.addEventListener('mousedown', this.handleClickOutside);
    this.setState({
      // items: this.props.items,
      sortedItems: this.props.items
    });
  }
  setWrapper(node) {
  this.wrapper = node;
  }
  setWrapperInput(node){
    this.wrapperInput = node;
  }
  handleClickOutside(event) {
  if (this.wrapperInput && this.wrapperInput.contains(event.target)){

  } else if (this.wrapper && !this.wrapper.contains(event.target)) {
    if (this.state.display === 'block') {
      this.setState({
        display: 'none'
      })
    }
  }

}
  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.sortData();
    }
  }
  componentWillUnmount() {
  document.removeEventListener('mousedown', this.handleClickOutside);
}
  _handleClicked = (index,item) => {
    this.setState({
      display: 'none',
      activeID: -1,
      newItem: ''
    });
    this.props.updateData('ADD_ITEM',item);
  }
  _handleNewItem = (event) => {
    let display = 'none';
    if (event.target.value !== ''){
      display = 'block';
      this.setState({
        newItem: event.target.value,
        display: display
      },()=>{this.sortData()});
    } else {
      this.setState({
        newItem: '',
        display: display,
        activeID: -1
      },()=>{this.sortData()});
    }

  }
  _handleFocusInput = () => {
    this.setState({
      newItem: '',
      display: 'block',
      activeID: -1
    });
  }
  // Input Key Down
  _handleDownKey(){
    if (!this.state.sortedItems) {
      return;
    } else if (this.state.activeID !== this.state.sortedItems.length-1) {
      this.setState({
        activeID: this.state.activeID+1
      });
    }
  }
  _handleUpKey(){
    if (!this.state.sortedItems) {
      return;
    } else if (this.state.activeID !== 0) {
      this.setState({
        activeID: this.state.activeID-1
      });
    }
  }
  _handleEscapeKey(){
    if (!this.state.sortedItems) {
      return;
    }
    this.setState({
      newItem: '',
      display: 'none',
      activeID: -1
    });
  }
  _handleInputKeyDown(event){
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this._handleClicked(this.state.activeID,this.state.sortedItems[this.state.activeID]);
        break;
      case 'ArrowDown':
        event.preventDefault(); // prevent the cursor from moving
        this._handleDownKey();
        break;
      case 'ArrowUp':
        event.preventDefault(); // prevent the cursor from moving
        this._handleUpKey();
        break;
      case 'Escape':
        event.preventDefault();
        this._handleEscapeKey();
        // console.log('Escape');
        break;
      default:
        break;
    }
  }
  sortData(){
    // SEARCH BLOCK
    let searchData = this.state.newItem,
        arraySearch = searchData.split(' '),
        // sortedItems = this.state.items,
        sortedItems = this.props.items,
        dataForSearch = this.props.items;

    if(arraySearch.length === 1) {
      sortedItems = dataForSearch.filter( item => (item.nameRU.toLowerCase().indexOf(searchData.toLowerCase()) !== -1 ) );
    } else if (arraySearch.length >= 2) {
        sortedItems = dataForSearch.filter(
         item =>
             item.nameRU.toLowerCase().indexOf(arraySearch[0].toLowerCase()) !== -1 &&
             item.nameRU.toLowerCase().indexOf(arraySearch[1].toLowerCase()) !== -1
        );
      }
      this.setState({
        sortedItems: sortedItems
      })
  }
  render() {
    let renderedItems = this.state.sortedItems.map((item, index) => {
          return (
            <div className={this.state.activeID === index ? "autocomplete-item active" : "autocomplete-item"} key={index} onClick={this._handleClicked.bind(this,index,item)} ><div><strong>{item.nameRU}</strong></div></div>
          )
        }
      );
    // console.log('this.state.sortedItems', this.state.sortedItems);
    return (
      <div className="row">
        <div className="col-12 mb-4">
          <input type="text" className="input-main-collection-page" placeholder={this.props.placeholder} value={this.state.newItem} onChange={this._handleNewItem.bind(this)} onFocus={this._handleFocusInput.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} />
          <div className="autocomplete-container" style={{ display: this.state.display}} ref={this.setWrapper}>
            {
              this.state.sortedItems.length === 0 &&  this.state.newItem !== '' ?
              <div className="autocomplete-item"><div>Не найдено</div></div>
              : ''
            }
            { renderedItems }
          </div>
        </div>
      </div>
    )
  }
}

export default UniversalSelect;
