import React, { Component } from 'react';
import Layout from '../Layout';
import {Helmet} from "react-helmet";

class Orders extends Component {
  constructor(props) {
      super(props);
      this.state = {
        orders: []
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');

  }
  render() {
    let helmetTitle = 'Заказы - CRM Фабрика Фурнитуры';
    return (
      <Layout match={this.props.match} history={this.props.history} title="Заказы">
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <h1>Заказы</h1>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Orders;
