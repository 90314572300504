import React, { Component } from 'react';
import axios from 'axios'
import { DOMAIN_NAME_API } from '../../Constants'
import SearchFabrics from './SearchFabrics';
import FabricItem from './FabricItem';

class SelectFabrics extends Component {
  constructor(props) {
      super(props);
      this.state = {
          fabrics: [],
          itemsLimit: 16,
          sortedCollections: [],
          searchStatus: false,
          loaded: false
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');

    axios.all([
      axios.get(DOMAIN_NAME_API + '/collections?options=ORDER BY nameEN'),
      axios.get(DOMAIN_NAME_API + '/fabrics?options=ORDER BY collectionID')
    ])
    .then(axios.spread((collectionsRes, fabricsRes) => {
      let collections = collectionsRes.data.collections;
      let fabrics = fabricsRes.data;
      let formattedFabrics = [];

      collections.forEach((collection, i) => {
        fabrics.forEach((fabric, i) => {
          if (collection.id === fabric.collectionID) {
            fabric.collection = collection;
            fabric.requestedLength = 0;
            formattedFabrics.push(fabric);
          }
        });
      });

      // Update State with fabrics and collections
      this.setState({
        fabrics: formattedFabrics
      })

    }))
    .catch( error => {
      // handle error
      console.log(error);
    });

    document.addEventListener('scroll', this.trackScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }
  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }
  trackScrolling = () => {
    const wrappedElement = document.getElementById('root');
    if (this.isBottom(wrappedElement)) {
      this.setState({
        itemsLimit: this.state.itemsLimit + 8
      })
      // console.log('header bottom reached');
      // document.removeEventListener('scroll', this.trackScrolling);
    }
  }
  updateData = (value,data) => {
    switch(value) {
      case 'SELECTED_FABRIC':
        // console.log('SELECTED_FABRIC');
        // this.setState({sortedCollections: [], searchStatus: false})
        this.props.updateData('ADD_FABRIC',data);
        break;
      case 'SEARCH_STOCK':
      if(data.searchValue !== ''){
        this.setState({sortedCollections: data.sortedData, searchStatus: true})
      } else if( data.category && data.searchValue !== ''){
        this.setState({sortedCollections: data.sortedData, searchStatus: true})
      } else {
        this.setState({sortedCollections: [], searchStatus: false})
      }
        console.log('SEARCH_STOCK',data);
        break;
      default:
        break;
    }
  }
  render() {
    let template = '',
        prevCollection = 'start';
    if (this.state.searchStatus) {
      template = this.state.sortedCollections.slice(0,this.state.itemsLimit).map((item,index)=>{
        if (item.collection.nameEN !== prevCollection) {
          prevCollection = item.collection.nameEN;
          return (
            <FabricItem key={index} updateData={this.updateData} item={item} preItem={<div className="col-md-12 mb-2"><h4>{item.collection.nameEN}</h4></div>} />
          )
        } else {
          return (
            <FabricItem key={index} updateData={this.updateData} item={item} />
          )
        }
      });
    } else {
      template = this.state.fabrics.slice(0,this.state.itemsLimit).map((item,index)=>{
        if (item.collection.nameEN !== prevCollection) {
          prevCollection = item.collection.nameEN;
          return (
            <FabricItem key={index} updateData={this.updateData} item={item} preItem={<div className="col-md-12 mb-2"><h4>{item.collection.nameEN}</h4></div>} />
          )
        } else {
          return (
            <FabricItem key={index} updateData={this.updateData} item={item} />
          )
        }
      });
    }
    return (
      <div className={this.props.customClass ? "col-md-4 " + this.props.customClass : "col-md-4"}>
        <div className="row">
          <div className="col-md-8 mb-2">
            <SearchFabrics updateData={this.updateData} items={this.state.fabrics} searchStatus={this.state.searchStatus} />
          </div>
          <div className="col-md-12">
            <div className="row">
              { template }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectFabrics;
