import React, { useEffect, useReducer } from 'react'
import { Helmet } from "react-helmet"
// import { Link } from 'react-router-dom'
import axios from 'axios'
import { DOMAIN_NAME_API } from '../Constants'
import HandlingContext from '../context/HandlingContext'
import handlingReducer from '../reducers/HandlingReducer'
import LockScreen from '../components/handling/LockScreen'
import Navbar from '../components/Navbar';
import HandlingItem from '../components/handling/HandlingItem2'

function HandlingsPage(props){
  const [state, dispatch] = useReducer(handlingReducer,
    {
      requests: [],
      user: window.localStorage.getItem('handlingUser') ? JSON.parse(window.localStorage.getItem('handlingUser')) : null
    })
  let helmetTitle = 'Отгрузка - CRM Фабрика Фурнитуры'

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${DOMAIN_NAME_API}/requests?options=ORDER BY created DESC`)
      dispatch({
        type: 'INITIAL_REQUESTS',
        payload: response.data.requests
      })
    }
    fetchData()
  },[])

  if (state.user) {
    return (
      <HandlingContext.Provider value={{ state, dispatch }}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="layout" style={{minHeight: 100+'vh'}}>
          <div className="container-fluid">
            <Navbar match={props.match} history={props.history} user={state.user} title={'Отгрузка'} handlingStatus={1} />
            <div className="row">
              <div className="col-md-12">
                <h2>Последние заявки</h2>
              </div>
              <div className="col-md-12">
                <div className="row">
                  {
                    state.requests.map((item, index) => <HandlingItem key={index} arrayIndex={index} item={item} />)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </HandlingContext.Provider>
    )
  }
  return (
    <HandlingContext.Provider value={{ state, dispatch }}>
      <LockScreen />
    </HandlingContext.Provider>
  )
}

export default HandlingsPage
