import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

class Logout extends Component {
    // eslint-disable-next-line
    constructor(props) {
        super(props);
        // let settings = JSON.parse(window.localStorage.getItem('settings'));
        // this.state = {
        //     settings: settings || false,
        // };
    }
    componentDidMount(){
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('user');
      localStorage.setItem('referer',document.referrer);
    }
    render() {
        return (
          <Redirect to={{ pathname: '/auth' }}/>
        )
    }
}

export default Logout;
