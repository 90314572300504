import React, { Component } from 'react';
import FabricItem from './FabricItem';
import { DOMAIN_NAME_API} from '../../Constants'

import axios from 'axios'
import update from 'immutability-helper';

class FabricsEditor extends Component {
  constructor(props) {
      super(props);
      this.state = {
          fabrics: [],
          downloaded: false,
          random: ''
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
    this.loadFabrics();
  }
  loadFabrics(){
    axios.get(DOMAIN_NAME_API + '/fabrics/byCollection/' +this.props.defaultID)
    .then(res => {
      var fabrics = res.data.fabrics;
      this.setState({
        fabrics: fabrics,
        downloaded: true
      })
    });
  }
  updateData = (value,data) => {
    // console.log(value,data);
    switch(value) {
      case 'ADD_FABRIC':
        // this.loadFabrics();
        console.log('ADD_FABRIC',data);
         let initialArray = this.state.fabrics;
         let newArray = update(initialArray, {$push: [data]});
         this.setState({
           fabrics: newArray,
         });
        break;
      case 'EDIT_FABRIC':
        console.log('EDIT_FABRIC');
        break;
      case 'DELETE_FABRIC':
        // worked success
        this.setState((prevState) => ({
                fabrics: update(prevState.fabrics, {$splice: [[data, 1]]})
              }));
        break;
      default:
        break;
    }
  }
    render() {
      const interfaceLocale = this.props.locale;
      let template = '';
        template = this.state.fabrics.map((fabric, index) => {
              return (
                <FabricItem key={fabric.id} fabricIndex={index} locale={interfaceLocale} updateData={this.updateData} fabric={fabric} type="edit" />
              )
          });
      // if (this.state.fabrics){
      //   template = this.state.fabrics.map((fabric, index) => {
      //         return (
      //           <FabricItem key={index} fabricIndex={index} locale={interfaceLocale} updateData={this.updateData} fabric={fabric} type="edit" />
      //         )
      //     });
      // }
      return (
        <div className="row">
          <div className="col-md-12">
          {
            this.state.downloaded ? (
              <>
              <div className="row"><div className="col-md-12 mb-2"><h3>Ткани</h3></div>
                {template}
              </div>
              <div className="row">
                <FabricItem locale={interfaceLocale} updateData={this.updateData} collectionID={this.props.defaultID} type="new" />
              </div>
              </>
            ) : ''
          }
          </div>
        </div>
      );
    }
}

export default FabricsEditor;
