import React, { Component } from 'react';
import Layout from '../Layout';
import update from 'immutability-helper';
import {Helmet} from "react-helmet";

import * as localeRU from './locale_ru.json'
import * as localeEN from './locale_en.json'
const LOCALE = {
    "ru":localeRU.default,
    "en":localeEN.default
  }

class Settings extends Component {
  constructor(props) {
      super(props);
      this.state = {
        settings:{
            'language': window.localStorage.getItem('lang') || 'ru'
          }
      };
  }
  _handleChangeLanguage = (event,value) => {
    const initialData = this.state.settings;
    const newData = update(initialData, {language: {$set: value}});
    this.setState({
      settings: newData
    });
    localStorage.setItem('lang',value);
  }
  render() {
    let lang = this.state.settings.language || 'ru';
    let locale = LOCALE[lang];
    let helmetTitle = locale.title + ' - CRM Фабрика Фурнитуры';
    return (
      <Layout match={this.props.match} history={this.props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12" style={{minHeight: 10+'rem'}}>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className='col-md-6'>

                  <div className="form-group row">
                    <label className="col-md-6 col-form-label">{locale.mainSettings.interfaceLang}</label>
                    <div className="col-md-6">
                      <div className="btn-group">
                        <button value={this.state.settings['language']} data-toggle="dropdown" className="btn btn-secondary dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>{this.state.settings['language'] === 'ru' ? 'Русский' : 'English'} <span className="caret" /></button>
                        <ul className="dropdown-menu" x-placement="bottom-start" >
                          <li><button className="dropdown-item" style={{cursor:'pointer'}} onClick={ (e) => {this._handleChangeLanguage(e,"en")} }>{locale.mainSettings.interfaceLangOptins.en}</button></li>
                          <li><button className="dropdown-item" style={{cursor:'pointer'}} onClick={ (e) => {this._handleChangeLanguage(e,"ru")} }>{locale.mainSettings.interfaceLangOptins.ru}</button></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>


              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Settings;
