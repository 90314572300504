import React, { useState, useContext } from 'react'
import Image from '../Image'
import { formatFloat, declOfNum, declOfPrice } from '../../Functions'
import DeliveryContext from '../../context/DeliveryContext'

// import { VAT } from '../../Constants'

function declOfMeters(length){
  return declOfNum(length, ['метр', 'метра', 'метров'])
}

// function declOfRolls(qty){
//   return declOfNum(qty, ['рулон', 'рулона', 'рулонов'])
// }

// function declOfRubles(length){
//   return declOfNum(length, ['рубль', 'рубля', 'рублей'])
// }

const FabricItem = (props) => {
  const [form, setForm] = useState({
    length: props.item.length || 0,
    price: props.item.price || 0
  })

  const { state, dispatch } = useContext(DeliveryContext)

  const changeHandler = (event) => {
    // eslint-disable-next-line
    setForm({ ...form, [event.target.name]: event.target.value.replace(/[^.\d]+/g,"").replace( /^([^\.]*\.)|\./g, '$1' )})
  }

  const handleRemove = (event) => {
    dispatch({
      type: 'DELETE_FABRIC',
      payload: { rowID: props.rowID }
    })
    // props.updateData("DELETE_FABRIC", {rowID: props.rowID});
  }

  let item = props.item;

  let totalPrice = formatFloat((+form.length)*(+form.price))

  let template = '';

  if (totalPrice > 0 &&  +form.length > 0) {
    template = `${form.length} ${declOfMeters(formatFloat(+form.length))} ${+totalPrice} ${declOfPrice(+totalPrice,state.selectedCurrency)}`
  } else if (+form.length > 0) {
    template = `${form.length} ${declOfMeters(formatFloat(+form.length))}`
  }

  return <div className="col-md-12">
      <div className="suppliedFabrics-block">
        <div className="suppliedFabrics-image" style={{minWidth: 100+'px'}}>
          <Image url={item.image} height={75} customClass="ml-3" />
        </div>
        <div className="suppliedFabrics-title ml-4">
          <p>{`${item.collection.nameEN} ${item.nameEN}`}</p>
        </div>
        <div className="suppliedFabrics-item-with-input">
          <input
            type="text"
            className="input-cut"
            name="length"
            placeholder={0}
            value={form.length}
            onChange={changeHandler}
          />
        </div>
        <div className="suppliedFabrics-item-with-input">
          <input
            type="text"
            className="input-cut"
            name="price"
            placeholder={0}
            value={form.price}
            onChange={changeHandler}
          />
        </div>
        <div className="suppliedFabrics-text">
          <p>
            { template }
            <button className="btn btn-secondary ml-2" onClick={handleRemove}>X</button>
          </p>
        </div>
      </div>
    </div>
}

function SuppliedFabrics(props){
  return <div className={props.customClass ? "col-md-12 " + props.customClass : "col-md-12"}>
    <div className="row">
      {
        props.items.map( (item,index)=>{
          return <FabricItem updateData={props.updateData} item={item} key={index} rowID={index} />
        })
      }
    </div>
  </div>
}

export default SuppliedFabrics
