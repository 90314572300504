import React, { useReducer, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet"
import { DOMAIN_NAME_API } from '../Constants'
import axios from 'axios'
import HandlingContext from '../context/HandlingContext'
import handlingReducer from '../reducers/HandlingReducer'
import Navbar from '../components/Navbar'
import LockScreen from '../components/handling/LockScreen'
import HandlingBlock from '../components/handling/HandlingBlock2'
import moment from 'moment'
// eslint-disable-next-line
import ru from 'moment/locale/ru'

function NewHandlingPage(props) {
  const [state, dispatch] = useReducer(handlingReducer,
    {
      stock: [],
      request: null,
      user: window.localStorage.getItem('handlingUser') ? JSON.parse(window.localStorage.getItem('handlingUser')) : null
    })
  let helmetTitle = 'Отгрузка - CRM Фабрика Фурнитуры'

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${DOMAIN_NAME_API}/requests/${props.match.params.id}`)
      dispatch({
        type: 'INITIAL_REQUEST_DATA',
        payload: response.data.request
      })
    }
    fetchData()
  },[props.match.params.id])

  let date = moment.unix(state.request ? state.request.created : new Date().getSeconds())
  moment.locale('ru')

  if (!state.user) {
    return (
      <HandlingContext.Provider value={{ state, dispatch }}>
        <LockScreen />
      </HandlingContext.Provider>
    )
  }

  return (
    <HandlingContext.Provider value={{ state, dispatch }}>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>
      <div className="layout" style={{minHeight: 100+'vh'}}>
        <div className="container-fluid">
          <Navbar match={props.match} history={props.history} user={state.user} title={<Link className={"btn btn-secondary"} to={"/handling"}>Вернуться к списку отгрузок</Link>}  handlingStatus={1} />
          {
            state.request ?
            <div className="row">
              <div className="col-md-12">
                <h2>{state.request.client.nameRU}</h2>
                <p>
                    <i className="fa fa-clock-o"></i>&nbsp;&nbsp;{date.format('HH:mm')}
                    &nbsp;&nbsp;
                    <i className="fa fa-calendar"></i>&nbsp;&nbsp;{date.format('DD MMMM YYYY')}
                </p>
                <p>{state.request.comments}</p>
              </div>
              <div className="col-md-12">
                <div className="row handling-row">
                  {
                    state.request.fabrics.map((fabricItem,index)=>{
                      return <HandlingBlock key={index} item={fabricItem} />
                    })
                  }
                </div>
              </div>
            </div>
            : ''
          }
        </div>
      </div>
    </HandlingContext.Provider>
  )
}

export default NewHandlingPage
