import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toFormatRollLength, formatFloat } from '../../Functions'
import moment from 'moment'
// eslint-disable-next-line
import ru from 'moment/locale/ru'

const statusColors = ["#BDC3C7","#3FC380","#3498db"]

function HandlingItem(props) {
  const item = props.item
  const [isVisible, setVisible] = useState(false)
  const [totalBalance, setTotalBalance] = useState(0)

  const handleVisivle = () => {
    setVisible(isVisible ? false : true)
  }

  useEffect(() => {
    for (let fabric of item.fabrics) {
      setTotalBalance(prev => prev + fabric.length)
    }
  },[item.fabrics])

  let date = moment.unix(item.created)
  moment.locale('ru')

  return(
    <div className="requests-block ml-4">
      <div className="requests-item-title">
        <h5><em className="fa fa-circle pr-2" style={{color: statusColors[item.status]}} />{item.client.nameRU}</h5>
        <p>
            <i className="fa fa-clock-o"></i>&nbsp;&nbsp;{date.format('HH:mm')}
            &nbsp;&nbsp;
            <i className="fa fa-calendar"></i>&nbsp;&nbsp;{date.format('DD MMMM YYYY')}
        </p>
        <p>{item.comments}</p>
      </div>
      <div className="requests-item-description">
        {
          isVisible ?
          <>
            <p onClick={handleVisivle}>{formatFloat(totalBalance/100) + ' ' + toFormatRollLength(totalBalance/100)}</p>
            <table className="table table-borderless table-sm" style={{marginLeft: 1+'rem'}}>
              <tbody>
              {
                item.fabrics.map((fabricItem,index) =>
                  <tr key={index}>
                    <td>{fabricItem.collection.nameEN} {fabricItem.description.nameEN}</td>
                    <td>{+fabricItem.length/100 + ' ' + toFormatRollLength(+fabricItem.length/100)}</td>
                  </tr>
                )
              }
              </tbody>
            </table>
          </>
          : <p onClick={handleVisivle}>{formatFloat(totalBalance/100) + ' ' + toFormatRollLength(totalBalance/100)} {'('+item.fabrics.length+')'}</p>
        }
      </div>
      <div className="requests-item-message text-right">
        {
          item.status !== 0 ?
          <>
          <Link className={"btn btn-secondary"} to={"/handling/"+ item.id}>Начать отгрузку</Link>
          </>
          : <p>Отгружено</p>
        }
      </div>
    </div>
  )
}

export default HandlingItem
