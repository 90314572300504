import React, { Component } from 'react';
import UniversalSelect from './UniversalSelect';
import axios from 'axios'
import update from 'immutability-helper';
import { DOMAIN_NAME_API } from '../../Constants'
// import * as caresJSON from './cares.json'
// const cares = caresJSON.default.cares;

class caresEditor extends Component {
  constructor(props) {
      super(props);
      this.state = {
          cares: [],
          caresList: [],
          downloaded: true,
          random: ''
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
    // axios.get(DOMAIN_NAME_API + '/cares/' + this.props.collectionID)
    // .then(res => {
    //   let formattedcares = [];
    //   res.data.cares.forEach((item, i) => {
    //     let result = cares.filter( care => (care.id === item.careID ) );
    //     let care = result[0];
    //     care.careID = item.id;
    //     formattedcares.push(care);
    //   });
    //   this.setState({
    //     cares: formattedcares
    //   })
    // });

    axios.all([
      axios.get(DOMAIN_NAME_API + '/data/caresList'),
      axios.get(DOMAIN_NAME_API + '/cares/' + this.props.collectionID)
    ])
    .then(axios.spread((caresListRes, caresRes) => {
      // data = {
      //   originalUrl: req.originalUrl,
      //   categories: categoriesRes.data.categories,
      //   collections: collectionsRes.data.collections,
      // };

      let formattedCares = [];
      // let cares = this.props.data;
      const cares = caresListRes.data.cares;
      caresRes.data.cares.forEach((item, i) => {
        let result = cares.filter( care => (care.id === item.careID ) );
        let care = result[0];
        let newFeature = {
          id: care.id,
          nameRU: care.nameRU,
          url: care.url,
          careID: item.id
        }
        // care.careID = item.id;
        formattedCares.push(newFeature);
        // formattedcares.push(care);
      });
      this.setState({
        caresList: cares,
        cares: formattedCares
      })
    }))
  }
  _handleRemove = (event,index) => {
    axios.delete(DOMAIN_NAME_API + '/cares/' + this.state.cares[index]['careID'])
    .then(res => {
      this.setState((prevState) => ({
        cares: update(prevState.cares, {$splice: [[index, 1]]})
      }));
    })
    // console.log(this.state.cares[index]);
  }
  updateData = (value,data) => {
    if(data){
      axios.post(DOMAIN_NAME_API + '/cares',
      {careID: data.id, collectionID: this.props.collectionID})
      .then(res => {
        data.careID = res.data.id;
        this.setState({
          cares: update(this.state.cares, {$push: [data]})
        });
      });
    }
  }
    render() {
      let template = '',
          excludeCares = [];
      if (this.state.cares.length !== 0){
        template = this.state.cares.map((care, index) => {
            return (
              <span key={index} className="badge badge-secondary care-item">{care.nameRU}<span className="care-item-remove" onClick={(e)=>{this._handleRemove(e,index)}} >x</span></span>
            )
        });

        this.state.caresList.forEach((item, i) => {
          let result = this.state.cares.filter( care => (care.id === item.id ) );
          if (result.length === 0) {
            let newCare = {
              id: item.id,
              nameRU: item.nameRU,
              url: item.url
            }
            excludeCares.push(newCare);
          }
        });

      } else {
        excludeCares = this.state.caresList;
      }
      return (
        <div className="row">
          <div className="col-md-12">
            {
              this.state.downloaded ? (
                <div className="row">
                <div className="col-md-12 mb-2"><h3>Уход</h3></div>
                  <div className="col-md-12 mb-2">
                    { template }
                    <UniversalSelect updateData={this.updateData} items={excludeCares}  placeholder="Добавить новую рекомендацию по уходу" />
                  </div>
                </div>
              ) : ''
            }
          </div>
        </div>
      );
    }
}

export default caresEditor;
