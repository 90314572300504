import React, { Component } from 'react';
import Layout from '../Layout';
import {Helmet} from "react-helmet";
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'
// import RequestItem from './RequestItem';
import AddRequest from './AddRequest';
// import moment from 'moment'
// eslint-disable-next-line
// import ru from 'moment/locale/ru'

class NewRequest extends Component {
  constructor(props) {
      super(props);
      this.state = {
        clients: [],
        collections: [],
        fabrics: [],
        loaded: false
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
    axios.all([
      axios.get(DOMAIN_NAME_API + '/clients'),
      axios.get(DOMAIN_NAME_API + '/collections'),
      axios.get(DOMAIN_NAME_API + '/fabrics')
    ])
    .then(axios.spread((clientsRes, collectionsRes, fabricsRes) => {
      let clients = clientsRes.data.clients;
      let collections = collectionsRes.data.collections;
      let fabrics = fabricsRes.data;

      // Update State with clients
      this.setState({
        clients: clients,
        collections: collections,
        fabrics: fabrics,
        loaded: true
      });

    }))
    .catch( error => {
      // handle error
      console.log(error);
    });
  }
  render() {
    let helmetTitle = 'Новая заявка - CRM Фабрика Фурнитуры';
    return (
      <Layout match={this.props.match} history={this.props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12" style={{minHeight: 25+'rem'}}>
          {
            this.state.loaded ?
            <AddRequest match={this.props.match} history={this.props.history} clients={this.state.clients} />
            : ''
          }
        </div>
      </Layout>
    );
  }
}

export default NewRequest;
