import React from 'react';
import { Link } from 'react-router-dom';
// import { DOMAIN_NAME } from '../Constants'
// import userLogo from './../user.svg'
import { USER_BASE64 } from '../Constants'
import LogoutButton from './handling/LogoutButton'
import * as localeSelect from '../common/permissions.json'
const localeSelectPermissons = localeSelect.default;

function Navbar(props) {
  let user = props.user,
      title = props.title || '';
  let lang = props.language || 'ru';
    return (
      <header className="page-header row justify-center">
        <div className="col-md-6 col-lg-7">
            <h1 className="float-left text-md-left">{title}</h1>
        </div>
        <div className="dropdown user-dropdown col-md-6 col-lg-5 text-md-right"><a className="btn btn-stripped dropdown-toggle" href="https://example.com" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src={USER_BASE64} alt="profile" className="circle float-left profile-photo" width={50} height="auto" />
            <div className="username mt-1">
                <h4 className="mb-1">{user.nameRU}</h4>
                <h6 className="text-muted" style={{textTransform: 'none'}}>{localeSelectPermissons.permissionsList[user.permission][lang]}</h6>
            </div>
        </a>
            <div className="dropdown-menu dropdown-menu-right" style={{marginRight: '0'}} aria-labelledby="dropdownMenuLink">
              {
                props.handlingStatus ?
                <LogoutButton />
                : <><Link to={'/settings'} className="dropdown-item"><em className="fas fa-sliders-h mr-1" /> {'settings'}</Link>
                <a className="dropdown-item" href="/logout"><em className="fa fa-power-off mr-1" /> {'logout'}</a></>
              }
            </div>
        </div>
        <div className="clear" />
      </header>
    )
}

// class Navbar extends Component {
//   // eslint-disable-next-line
//   constructor(props) {
//     super(props);
//   }
//     render() {
//       let user = this.props.user,
//           title = this.props.title || '';
//       let lang = this.props.language || 'ru';
//         return (
//           <header className="page-header row justify-center">
//             <div className="col-md-6 col-lg-7">
//                 <h1 className="float-left text-md-left">{title}</h1>
//             </div>
//             <div className="dropdown user-dropdown col-md-6 col-lg-5 text-md-right"><a className="btn btn-stripped dropdown-toggle" href="https://example.com" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                 <img src={USER_BASE64} alt="profile" className="circle float-left profile-photo" width={50} height="auto" />
//                 <div className="username mt-1">
//                     <h4 className="mb-1">{user.nameRU}</h4>
//                     <h6 className="text-muted" style={{textTransform: 'none'}}>{localeSelectPermissons.permissionsList[user.permission][lang]}</h6>
//                 </div>
//             </a>
//                 <div className="dropdown-menu dropdown-menu-right" style={{marginRight: '0'}} aria-labelledby="dropdownMenuLink">
//                   {
//                     this.props.handlingStatus ?
//                     <LogoutButton updateData={this.props.updateData} />
//                     : <><Link to={'/settings'} className="dropdown-item"><em className="fas fa-sliders-h mr-1" /> {'settings'}</Link>
//                     <a className="dropdown-item" href="/logout"><em className="fa fa-power-off mr-1" /> {'logout'}</a></>
//                   }
//                 </div>
//             </div>
//             <div className="clear" />
//           </header>
//         );
//     }
// }

export default Navbar;

// <Link to={'/profile'} className="dropdown-item"><em className="fa fa-user mr-1" /> {'My profile'}</Link>

// <div className="col-md-12" style={{minHeight: 5+'rem'}}>
//   <div className="row">
//     <div className="col-md-9"></div>
//     <div className="col-md-3"></div>
//   </div>
// </div>

// <em className="fa fa-circle notification-active" />
// <Link className={this.props.match.path === '/clients' ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"} to={"/clients"}>{locale.routes['/clients']}</Link>
// <Link className={this.props.match.path === '/gallery' ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"} to={"/gallery"}>{locale.routes['/gallery']}</Link>
// <Link className={this.props.match.path === '/fabrics' ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action"} to={"/fabrics"}>Ткани</Link>
