import React from 'react';
import { CDN_SERVER } from '../Constants'

const Image = ({ url, height, customClass }) => {
  let webpSource = '',
      jpgSource = '';
  if (url) {
    webpSource = url;
    jpgSource = url.replace('.webp','.jpg');
  }
  return (
    <picture className="intrinsic intrinsic--4x3 rounded loading-placeholder">
      {
        url ?
        <>
          <source srcSet={CDN_SERVER + webpSource + '?h=' + height} type="image/webp" />
          <source srcSet={CDN_SERVER + jpgSource + '?h=' + height} type="image/jpeg" />
          <img className="intrinsic-item rounded" src={CDN_SERVER + jpgSource + '?h=' + height} alt="Collection" />
        </>
        : <img src={CDN_SERVER + '/static/crm/images/no-image.jpg'} className={"intrinsic-item rounded "+ customClass} alt="" />
      }
    </picture>
  )
}

export default Image
