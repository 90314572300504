import React, { Component } from 'react';
// import { DOMAIN_NAME_API } from '../../Constants'
import { formatFloat, toFormatRollLength } from '../../Functions'
import axios from 'axios'
import Image from '../Image'
// import svgPlaceholder from '../../svg-placeholder.svg';

class StockItem extends Component {
  constructor(props) {
      super(props);
      this.state = {
        rollsVisible: false
      }
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
  // newStock
  // _handleForm1 = (event) => {
  //   // eslint-disable-next-line
  //   let newStock = event.target.value.replace(/[^.\d]+/g,"").replace( /^([^\.]*\.)|\./g, '$1' );
  //   this.setState({ newStock: newStock });
  // }
  _handleInputKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        this.createStock();
        break;
      default:
        break;
    }
  }
  // createStock(){
  //   this.setState({ newStock: '', newBalance: +this.state.newStock },()=>{
  //     axios.get(DOMAIN_NAME_API + '/stock/status/byFabric/'+this.props.item.id)
  //     .then(() => {
  //         let data = {fabricID: this.props.item.id, balance: this.state.newBalance};
  //         axios.post(DOMAIN_NAME_API + '/stock', data)
  //         .then((res) => {
  //           this.setState({ stock: res.data});
  //           }
  //         );
  //     });
  //   });
  // }
  openRolls(){
    this.setState({
      rollsVisible: this.state.rollsVisible ? false : true
    })
  }
render() {
  let item = this.props.item;
  let totalBalance = 0;
  let template = item.stock.map((stockItem,index)=>{
    totalBalance = totalBalance + stockItem.balance;
    // console.log(stockItem.balance, toFormatRollLength(stockItem.balance));
    return (<p key={index} style={{paddingLeft: 1+'rem'}}>{stockItem.balance/100 + ' ' + toFormatRollLength(stockItem.balance)}</p>)
  })
  totalBalance = totalBalance/100;
  // console.log(this.props.item.id,totalBalance);
  return (
    <div className="col-md-12 mb-3">
      <div className="row">
        {this.props.preItem}
        <div className="col-md-3">
          <div className="row">
            <div className="col-md-6">
              <div className="imageStockItem">
                <Image url={item.image} height={75} customClass="ml-3" />
              </div>
            </div>
            <div className="col-md-6 pl-0">
              <p className="" style={{marginTop: 1+'rem'}}>{item.nameEN}</p>
            </div>
          </div>
        </div>
        <div className="col-md-3 pl-0">
          {
            item.stock.length > 0 ?
            <>
            {
              this.state.rollsVisible ?
              <>
                <p style={{marginTop: 1+'rem'}} onClick={()=>{this.openRolls()}}>{formatFloat(totalBalance) + ' ' + toFormatRollLength(totalBalance)}</p>
                {template}
              </>
              : <p style={{marginTop: 1+'rem'}} onClick={()=>{this.openRolls()}}>{formatFloat(totalBalance) + ' ' + toFormatRollLength(totalBalance)} {'('+item.stock.length+')'}</p>
            }
            </>
            : <p style={{marginTop: 1+'rem'}}>Нет в наличии</p>
          }
        </div>
        <div className="col-md-3">

        </div>
      </div>
    </div>

  );
  }
}

export default StockItem;

// { item.stock || this.state.stock ?
//   <>
//   <p className="mb-1">
//     <strong> {balance} </strong>
//     {' ' + declOfNum(balance*10, ['метр', 'метра', 'метров'])}
//   </p>
//   <div className="input-group">
//     <input type="text" className="input-main" placeholder={'Обновить остаток'} style={{fontSize: 1+'rem'}} value={this.state.newStock} onChange={this._handleForm1.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} />
//     <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.createStock()}}>Обновить</button>
//   </div>
//   </>
// : <div className="input-group">
//     <input type="text" className="input-main" placeholder={'Добавить остаток'} style={{fontSize: 1+'rem'}} value={this.state.newStock} onChange={this._handleForm1.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} />
//     <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.createStock()}}>Добавить</button>
//   </div>
// }

// <img className="rounded ml-3" src={item.image ? CDN_SERVER + item.image + '?w=120&h=90' : svgPlaceholder} width='100' height='auto' alt=""/>


// <div className="input-group">
//   <input type="text" className="input-main" placeholder={'Обновить остаток'} style={{fontSize: 1+'rem'}} value={this.state.newStock} onChange={this._handleForm1.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} />
//   <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.createStock()}}>Обновить</button>
// </div>
//  <div className="input-group">
//   <input type="text" className="input-main" placeholder={'Добавить остаток'} style={{fontSize: 1+'rem'}} value={this.state.newStock} onChange={this._handleForm1.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} />
//   <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.createStock()}}>Добавить</button>
// </div>

// <tr>
//   <td style={{borderTop: 'none'}}><img className="rounded" src={CDN_SERVER + item.image + '?w=120&h=90'} width='100' height='auto' /></td>
//   <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}>{item.collectionID}</td>
//   <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}>{item.nameEN}</td>
//   <td style={{borderTop: 'none',verticalAlign:'middle'}}>
//     <button className="btn btn-secondary btn-sm">Кнопка</button>
//   </td>
// </tr>
