import React, { Component } from 'react';
import Layout from '../Layout';

import { DOMAIN_NAME_API, CDN_SERVER } from '../../Constants'
import { randomInteger, declOfNum } from '../../Functions'
import axios from 'axios'
import update from 'immutability-helper';
import {Helmet} from "react-helmet";
import svgPlaceholder from '../../svg-placeholder.svg';

import * as localeRU from './locale_ru.json'
import * as localeEN from './locale_en.json'
const LOCALE = {
    "ru":localeRU.default,
    "en":localeEN.default
  }

class Home extends Component {
  constructor(props) {
      super(props);
      this.state = {
        categories: [],
        collections: [],
        fabrics: [],
        loaded: {
          "categories":false,
          "collections":false,
          "fabrics":false
        }
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
    axios.get(DOMAIN_NAME_API + '/categories')
    .then(res => {
      let categories = res.data.categories;
      const initialData = this.state.loaded;
      let newData = update(initialData, {categories: {$set: true}});
      this.setState({
        categories: categories,
        loaded:newData
      })
    });

    axios.get(DOMAIN_NAME_API + '/collections')
    .then(res => {
      let collections = res.data.collections;
      const initialData = this.state.loaded;
      let newData = update(initialData, {collections: {$set: true}});
      this.setState({
        collections: collections,
        loaded:newData
      })
    });

    axios.get(DOMAIN_NAME_API + '/fabrics')
    .then(res => {
      let fabrics = res.data;
      const initialData = this.state.loaded;
      let newData = update(initialData, {fabrics: {$set: true}});
      this.setState({
        fabrics: fabrics,
        loaded:newData
      })
    });

  }
  _handleCheckboxChangeNew = (event,object) => {
    const initialData = this.state.collections;
    let index = initialData.indexOf(object);
    let newData = initialData;
    initialData[index]['new'] = 0;
    const url = DOMAIN_NAME_API + '/collections/new/'+object.id;
      axios.put(url, {new:0})
      .then(res => {
        console.log(res);
        this.setState( { collection: newData } )
      })
  }
  _handleCheckboxChangeSale = (event,object) => {
    const initialData = this.state.collections;
    let index = initialData.indexOf(object);
    let newData = initialData;
    initialData[index]['sale'] = 0;
    const url = DOMAIN_NAME_API + '/collections/sale/'+object.id;
      axios.put(url, {sale:0})
      .then(res => {
        this.setState( { collection: newData } )
      })
  }
  render() {
    let lang = localStorage.getItem('lang') || 'ru'
    let locale = LOCALE[lang];
    let helmetTitle = 'Главная - CRM Фабрика Фурнитуры';
    let sales = [],
        newCollections = [],
        salesRendered = '',
        newCollectionsRendered = '';
    sales = this.state.collections.filter( collection => collection.sale.toString() === '1' );
    newCollections = this.state.collections.filter( collection => collection.new.toString() === '1' );

    if(newCollections.length !== 0) {
      newCollectionsRendered = newCollections.map((collection, index) => {
        let jpgSource = collection.image.replace('.webp','.jpg');
          return (
            <tr key={index}>
              <td style={{borderTop: 'none'}}>
                <img className="rounded" src={CDN_SERVER + jpgSource + '?w=100&h=75'} width='100' height='auto' alt="" />
              </td>
              <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}>{collection.nameEN}</td>
              <td style={{borderTop: 'none',verticalAlign:'middle'}}>
                <button className="btn btn-secondary btn-sm" onClick={(e)=>{this._handleCheckboxChangeNew(e,collection)}}>{locale.removeItem}</button>
              </td>
            </tr>
          )
      })
    }

    if(sales.length !== 0) {
      salesRendered = sales.map((collection, index) => {
        let jpgSource = collection.image.replace('.webp','.jpg');
        return (
          <tr key={index}>
            <td style={{borderTop: 'none'}}>
              <img className="rounded" src={CDN_SERVER + jpgSource + '?w=100&h=75'} width='100' height='auto' alt="" />
            </td>
            <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}>{collection.nameEN}</td>
            <td style={{borderTop: 'none',verticalAlign:'middle'}}>
              <button className="btn btn-secondary btn-sm" onClick={(e)=>{this._handleCheckboxChangeSale(e,collection)}}>{locale.removeItem}</button>
            </td>
          </tr>
        )
      })
    }

    return (
      <Layout match={this.props.match} history={this.props.history} navbarSettings={{visible:true}}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {
                  this.state.loaded.categories ?
                  (this.state.categories.length !== 0 ?
                  <div className="col-md-3 pricing-table">
                    <div className="col-pricing-table mb-5">
                      <h2 className="plan-price pb-0 text-center">{this.state.categories.length}</h2>
                      <p className="emphasis mt-0 mb-3 text-center">{lang === 'ru' ? declOfNum(this.state.categories.length, locale.total.categories) : locale.total.categories}</p>
                    </div>
                  </div>
                  : '' ) : (
                    <div className="col-md-3 pricing-table">
                      <div className="col-pricing-table mb-5">
                        <h2 className="plan-price pb-0 text-center "><i className="fas fa-circle loading-placeholder"></i></h2>
                        <p className="emphasis mt-0 mb-3 text-center">
                          <i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,6)} />
                        </p>
                      </div>
                    </div>
                  )
                }
                {
                  this.state.loaded.collections ?
                  (this.state.collections.length !== 0 ?
                  <div className="col-md-3 pricing-table">
                    <div className="col-pricing-table mb-5">
                      <h2 className="plan-price pb-0 text-center">{this.state.collections.length}</h2>
                      <p className="emphasis mt-0 mb-3 text-center">{lang === 'ru' ? declOfNum(this.state.collections.length, locale.total.collections) : locale.total.collections}</p>
                    </div>
                  </div>
                  : '' ) : (
                    <div className="col-md-3 pricing-table">
                      <div className="col-pricing-table mb-5">
                        <h2 className="plan-price pb-0 text-center "><i className="fas fa-circle loading-placeholder"></i></h2>
                        <p className="emphasis mt-0 mb-3 text-center">
                          <i className={"loading-placeholder fas fa-square-full-"+randomInteger(4,7)} />
                        </p>
                      </div>
                    </div>)
                }
                {
                  this.state.loaded.fabrics ?
                  (this.state.fabrics.length !== 0 ?
                  <div className="col-md-3 pricing-table">
                    <div className="col-pricing-table mb-5">
                      <h2 className="plan-price pb-0 text-center">{this.state.fabrics.length}</h2>
                      <p className="emphasis mt-0 mb-3 text-center">{lang === 'ru' ? declOfNum(this.state.fabrics.length, locale.total.fabrics) : locale.total.collections}</p>
                    </div>
                  </div>
                  : '' ) : (
                    <div className="col-md-3 pricing-table">
                      <div className="col-pricing-table mb-5">
                        <h2 className="plan-price pb-0 text-center "><i className="fas fa-circle loading-placeholder"></i></h2>
                        <p className="emphasis mt-0 mb-3 text-center">
                          <i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,6)} />
                        </p>
                      </div>
                    </div>)
                }
                {
                  this.state.loaded.collections ?
                  (newCollections.length !== 0 ?
                  <div className="col-md-5 pricing-table">
                    <div className="col-pricing-table mb-5">
                      <h4 className="plan-price pb-0 text-center">{locale.blockNew}</h4>
                      <table className="table">
                        <tbody>
                          { newCollectionsRendered }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : '' ) : (
                    <div className="col-md-5 pricing-table">
                      <div className="col-pricing-table mb-5">
                        <h4 className="plan-price pb-0 text-center"><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,6)} /></h4>
                        <table className="table">
                          <tbody>
                            <tr>
                              <td style={{borderTop: 'none'}}>
                                <img className="loading-placeholder-svg img-fluid rounded" src={svgPlaceholder} width='100' height='auto' alt="" />
                              </td>
                              <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}><i className={"loading-placeholder fas fa-square-full-"+randomInteger(5,7)} /></td>
                              <td style={{borderTop: 'none',verticalAlign:'middle'}}>
                                <i className={"loading-placeholder fas fa-square-full-"+randomInteger(4,4)} />
                              </td>
                            </tr>
                            <tr>
                              <td style={{borderTop: 'none'}}>
                                <img className="loading-placeholder-svg img-fluid rounded" src={svgPlaceholder} width='100' height='auto' alt="" />
                              </td>
                              <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}><i className={"loading-placeholder fas fa-square-full-"+randomInteger(5,7)} /></td>
                              <td style={{borderTop: 'none',verticalAlign:'middle'}}>
                                <i className={"loading-placeholder fas fa-square-full-"+randomInteger(4,4)} />
                              </td>
                            </tr>
                            <tr>
                              <td style={{borderTop: 'none'}}>
                                <img className="loading-placeholder-svg img-fluid rounded" src={svgPlaceholder} width='100' height='auto' alt="" />
                              </td>
                              <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}><i className={"loading-placeholder fas fa-square-full-"+randomInteger(5,7)} /></td>
                              <td style={{borderTop: 'none',verticalAlign:'middle'}}>
                                <i className={"loading-placeholder fas fa-square-full-"+randomInteger(4,4)} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>)
                }
                {
                  this.state.loaded.collections ?
                  (sales.length !== 0 ?
                  <div className="col-md-5 pricing-table">
                    <div className="col-pricing-table mb-5">
                      <h4 className="plan-price pb-0 text-center">{locale.blockSale}</h4>
                      <table className="table">
                        <tbody>
                          { salesRendered }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  : '' ) : (
                    <div className="col-md-5 pricing-table">
                      <div className="col-pricing-table mb-5">
                        <h4 className="plan-price pb-0 text-center"><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,6)} /></h4>
                        <table className="table">
                          <tbody>
                            <tr>
                              <td style={{borderTop: 'none'}}>
                                <img className="loading-placeholder-svg img-fluid rounded" src={svgPlaceholder} width='100' height='auto' alt="" />
                              </td>
                              <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}><i className={"loading-placeholder fas fa-square-full-"+randomInteger(5,7)} /></td>
                              <td style={{borderTop: 'none',verticalAlign:'middle'}}>
                                <i className={"loading-placeholder fas fa-square-full-"+randomInteger(4,4)} />
                              </td>
                            </tr>
                            <tr>
                              <td style={{borderTop: 'none'}}>
                                <img className="loading-placeholder-svg img-fluid rounded" src={svgPlaceholder} width='100' height='auto' alt="" />
                              </td>
                              <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}><i className={"loading-placeholder fas fa-square-full-"+randomInteger(5,7)} /></td>
                              <td style={{borderTop: 'none',verticalAlign:'middle'}}>
                                <i className={"loading-placeholder fas fa-square-full-"+randomInteger(4,4)} />
                              </td>
                            </tr>
                            <tr>
                              <td style={{borderTop: 'none'}}>
                                <img className="loading-placeholder-svg img-fluid rounded" src={svgPlaceholder} width='100' height='auto' alt="" />
                              </td>
                              <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}><i className={"loading-placeholder fas fa-square-full-"+randomInteger(5,7)} /></td>
                              <td style={{borderTop: 'none',verticalAlign:'middle'}}>
                                <i className={"loading-placeholder fas fa-square-full-"+randomInteger(4,4)} />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>)
                }
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Home;

// <img className="rounded" src={CDN_SERVER + collection.image + '?w=120&h=90'} width='100' height='auto' alt="" />
