export default function(state,action){
  switch (action.type) {
    case 'INITIAL_REQUESTS':
      return {
        ...state, requests: action.payload
      }
    case 'INITIAL_REQUEST_DATA':
      return {
        ...state, request: action.payload
      }
    case 'LOG_IN':
      let time = Date.now()
      localStorage.setItem('handlingUser',JSON.stringify(action.payload))
      localStorage.setItem('handlingUserLogged',time/1000)

      return {
        ...state, user: action.payload
      }
    case 'LOG_OUT':
      localStorage.removeItem('handlingUser')
      localStorage.removeItem('handlingUserLogged')

      return {
        ...state, user: null
      }
    default:
      return state
  }
}
