import React, { Component } from 'react';
import CategorySelectSearch from './CategorySelectSearch';
// import Generals from '../../common/Generals'
// import axios from 'axios'

class SearchCollections extends Component {
  constructor(props) {
      super(props);
      this.state = {
          searchValue: ''
      };
  }
_handleSearchForm = (event) => {
  this.setState({searchValue: event.target.value});
  this.sortedCollections({searchValue: event.target.value});
}
updateData = (value,data) => {
    // console.log(value,data);
    switch(value) {
      // case 'ADD_COLLECTION':
      //   this.reloadColections();
      //   console.log('ADD_COLLECTION');
      //   break;
      case 'CHANGE_CATEGORY':
        // const initialData = this.state.collection;
        // let newData = update(initialData, {categoryID: {$set: data.id}});
        // this.setState({ collection: newData },()=> { this.saveCollection() });
        // console.log('CHANGE_CATEGORY',data);
        this.sortedCollections({searchValue: this.state.searchValue, category: data});
        break;
        case 'DELETE_CATEGORY':
          this.reloadColection();
          console.log('DELETE_CATEGORY');
          break;
      default:
        break;
    }
  }
sortedCollections(object){
    // console.log(object);
  let filteredData,
      searchData = object.searchValue,
      arraySearch = searchData.split(' '),
      dataForSearch = this.props.collections;

    if(object.category){
      if (object.category.id !== null){
        dataForSearch = this.props.collections.filter( collection => collection.categoryID.toString() === object.category.id.toString() );
      }
    }

    if (searchData !== ''){
      if(arraySearch.length === 1) {
        filteredData = dataForSearch.filter( collection => (collection.nameEN.toLowerCase().indexOf(searchData.toLowerCase()) !== -1 || collection.id.toString().indexOf(searchData.toString()) !== -1 ) );
      } else if (arraySearch.length === 2) {
            filteredData = dataForSearch.filter(
               collection =>
                   collection.nameEN.toLowerCase().indexOf(arraySearch[0].toLowerCase()) !== -1 &&
                   collection.nameEN.toLowerCase().indexOf(arraySearch[1].toLowerCase()) !== -1
                 );
          }
      this.props.updateData("SEARCH_COLLECTION",{ searchValue: object.searchValue, sortedData: filteredData });
    } else if(object.category){
      filteredData = dataForSearch;
      this.props.updateData("SEARCH_COLLECTION",{ searchValue: object.searchValue, sortedData: filteredData, category: object.category });
    } else {
      this.props.updateData("SEARCH_COLLECTION",{ searchValue: '', sortedData: [] });
    }
}
render() {
  const interfaceLocale = this.props.locale;
  return (
    <div className="row">
      <div className="col-md-4">
        <input type="text" className="input-main-collection-page mb-4" placeholder={interfaceLocale['searchForm']} value={this.state.searchValue} onChange={this._handleSearchForm.bind(this)} />
      </div>
      <div className="col-md-3">
        <CategorySelectSearch locale={interfaceLocale} updateData={this.updateData} />
      </div>
    </div>
  )
  }
}

export default SearchCollections;

// <CategorySelectSearch locale={interfaceLocale} updateData={this.updateData} />
