import React, { Component } from 'react';
import axios from 'axios'
import { DOMAIN_NAME_API } from '../../Constants'
class AddCategory extends Component {
  constructor(props) {
      super(props);
      this.state = {
          form1: '',
          form2: '',
          form1Class: '',
          form2Class: ''
      };
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
_handleForm1 = (event) => {
this.setState({form1: event.target.value});
}
_handleForm2 = (event) => {
this.setState({form2: event.target.value});
}
_handleKeyPress = (e) => {
  if (e.key === 'Enter') {
      this._handleClick();
  }
};
_handleClick(){
  if (this.state.form1 !== '' && this.state.form2 !== ''){
    axios.post(DOMAIN_NAME_API + '/categories',
      {
        nameRU: this.state.form1,
        nameEN: this.state.form2,
        image: null,
        description: 'Описание категории'
      })
    .then(
      () => {
        this.setState({ form1: '', form2: '' });
        this.props.updateData('ADD_CATEGORY');
      }
    )

  } else if (this.state.form2 === '') {
    this.setState({
      form2Class: 'animated shake fast'
    },()=>{
      setTimeout(
        () => {
          this.setState({
            form2Class: ''
          })
        },400
      )
    })
  } else if (this.state.form1 === '') {
    this.setState({
      form1Class: 'animated shake fast'
    },()=>{
      setTimeout(
        () => {
          this.setState({
            form1Class: ''
          })
        },400
      )
    })
  }
}
render() {
  const interfaceLocale = this.props.locale;
  return (
    <div className="row">
      <div className="col-md-12 mb-4">
        <input type="text" className={"input-main-collection-page  "+ this.state.form1Class} placeholder={interfaceLocale['addCategory']['form1']} value={this.state.form1} onChange={this._handleForm1.bind(this)} onKeyPress={this._handleKeyPress} required autoFocus />
        <input type="text" className={"input-main-collection-page "+ this.state.form2Class} placeholder={interfaceLocale['addCategory']['form2']} value={this.state.form2} onChange={this._handleForm2.bind(this)} onKeyPress={this._handleKeyPress} required />
        <button type="submit" className="btn btn-secondary " onClick={this._handleClick.bind(this)}>{interfaceLocale['addCategory']['button']}</button>
      </div>
    </div>
  )
  }
}

export default AddCategory;

// <div className="col-md-3">
//   <input type="text" className={"input-main-collection-page mb-4 "+ this.state.form1Class} placeholder={interfaceLocale['addCategory']['form1']} value={this.state.form1} onChange={this._handleForm1.bind(this)} onKeyPress={this._handleKeyPress} required autoFocus />
// </div>
// <div className="col-md-3">
//   <input type="text" className={"input-main-collection-page mb-4 "+ this.state.form2Class} placeholder={interfaceLocale['addCategory']['form2']} value={this.state.form2} onChange={this._handleForm2.bind(this)} onKeyPress={this._handleKeyPress} required />
// </div>
// <div className="col-md-2">
//   <button type="submit" className="btn btn-secondary mb-4" onClick={this._handleClick.bind(this)}>{interfaceLocale['addCategory']['button']}</button>
// </div>
