import React from 'react';

export default function PDF(props) {
  console.log(props.location.search.split('='));
  // console.log(props.match.params.categoryName);
  return (
    <div className="container-fluid">
      <div class="row">
        <div class="col-md-2">
          <img width='128' height="128" src={`https://storage.ffm.by/qr?url=http://catalog2.ffm.by/collections/${props.match.params.categoryName}/${props.match.params.collectionName}`} alt="" />
        </div>
        <div class="col-md-6">
          <h1>{props.match.params.collectionName}</h1>
        </div>
        <div class="col-md-4"></div>
      </div>
      <div className="text-center"></div>
    </div>
  );
};

 // QR;
