import React from 'react';
import { Link } from 'react-router-dom';

function ClientItem (props){
  let name = props.client.nameRU;
  if (props.client.alias !== '') {
    name = `${props.client.alias} (${props.client.nameRU})`;
  }
  // console.log(props);
  return (
    <div className="col-md-12">
      <div className="client-block">
        <div className="client-title">
          <Link to={`/clients/${props.client.id}`}>{name} {props.client.providerStatus ? <>&nbsp;<i className="fas fa-industry"></i></> : ''}</Link>
          <p>{props.client.taxNumber ? props.client.taxNumber : 'Не резидент Республики Беларусь'}</p>
        </div>
      </div>
    </div>
  )
}

export default ClientItem;

// <h5>{props.client.nameRU}</h5>

// class ClientItem extends Component {
//   constructor(props) {
//       super(props);
//       this.state = {
//         form1: this.props.client.nameRU || '',
//         form2: this.props.client.taxNumber || '',
//         clickToConfirm: {
//           updateButton: false,
//           deleteButton: false
//         }
//       };
//   }
//   componentDidMount(){
//     axios.defaults.headers.common['token'] = localStorage.getItem('token');
//   }
// render() {
//   const interfaceLocale = this.props.locale;
//   let client = this.props.client;
//   return (
//     <div className="col-md-12 mb-3">
//       <div className="row">
//         <div className="col-md-4">
//           <input type="text" className={"input-main-collection-page full-width mb-1 "+ this.state.form1Class} placeholder={interfaceLocale['addClient']['form1']} value={this.state.form1} onChange={(e)=>{this._handleForm1(e,client.id)}} />
//         </div>
//         <div className="col-md-2">
//           <input type="text" className={"input-main-collection-page full-width mb-1 "+ this.state.form2Class} placeholder={interfaceLocale['addClient']['form2']} value={this.state.form2} onChange={(e)=>{this._handleForm2(e,client.id)}} maxLength="9" />
//         </div>
//         <div className="col-md-5">
//         </div>
//       </div>
//     </div>
//   );
//   }
// }
//
// export default ClientItem;

// {
//   this.state.clickToConfirm.deleteButton ?
//   <button type="submit" className="btn btn-secondary form-button mb-1" onClick={(e)=>{ this._handleClickToConfirm(e,"confirmDelete",client.id) }}>{interfaceLocale['clientItem']['buttonConfirm']}</button> :
//   <button type="submit" className="btn btn-secondary form-button mb-1" onClick={(e)=>{ this._handleClickToConfirm(e,"buttonDelete",client.id) }}>{interfaceLocale['clientItem']['buttonDelete']}</button>
// }
// <Link className={"btn btn-secondary form-button mb-1"} to={"/clients/"+ client.id}>{interfaceLocale['clientItem']['buttonEdit']}</Link>
