import React, { Component } from 'react';
import Layout from '../Layout';
import {Helmet} from "react-helmet";
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'
// import moment from 'moment'
// eslint-disable-next-line
// import ru from 'moment/locale/ru'
import DataTable from 'react-data-table-component';

const customStyles = {
  rows: {
    style: {
      minHeight: '50px', // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: '15px', // override the cell padding for head cells
      paddingRight: '15px',
      fontSize: '1rem'
    },
  },
  cells: {
    style: {
      paddingLeft: '15px', // override the cell padding for data cells
      paddingRight: '15px',
      fontSize: '1rem'
    },
  },
};

const columns = [
  {
    name: 'Коллекция',
    selector: 'nameEN',
    sortable: true,
  },
  {
    name: 'Просмотров',
    selector: 'total',
    sortable: true,
    right: true,
  },
];

// const MyComponent = () => (
//   <DataTable
//     title="Arnold Movies"
//     columns={columns}
//     data={data}
//     customStyles={customStyles}
//   />
// );

class Analytics extends Component {
  constructor(props) {
      super(props);
      this.state = {
        categories:[],
        collections:[],
        visits: [],
        formattedCollections: [],
        loaded: false
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
    // axios.get(DOMAIN_NAME_API + '/analytics/visits')
    //   .then((response) => {
    //     let visits = response.data.visits;
    //     this.setState({
    //       visits: visits,
    //       loaded: true
    //     });
    //  })
    // .catch(function (error) {
    //     console.log(error);
    // });

    axios.all([
      axios.get(DOMAIN_NAME_API + '/categories'),
      axios.get(DOMAIN_NAME_API + '/collections?options=ORDER BY nameEN'),
      axios.get(DOMAIN_NAME_API + '/analytics/visits')
    ])
    .then(axios.spread((categoriesRes, collectionsRes, visitsRes) => {
      let categories = categoriesRes.data.categories,
          collections = collectionsRes.data.collections,
          visits = visitsRes.data.visits,
          formattedCollections = new Map();

      collections.forEach((collection, i) => {
        categories.forEach((category, i) => {
          if (category.id === collection.categoryID) {
            let url = `/collections/${category.slug}/${collection.slug}`;

            collection.category = category;
            collection.url = url;
            collection.total = 0;
            collection.unique = 0;


            visits.forEach((visit, i) => {
              if (visit.url === url) {
                // console.log(collection,visit);
                collection.total = collection.total + 1;
              }
            });

            formattedCollections.set(url, collection);
          }
        });
      });

      this.setState({
        categories: categories,
        collections: collections,
        visits: visitsRes.data.visits,
        formattedCollections: formattedCollections,
        loaded: true
      });
    }))
    .catch( error => {
      // handle error
      console.log(error);
    });

  }
  render() {
    let helmetTitle = 'Аналитика - CRM Фабрика Фурнитуры',
        // template = '',
        preparedCollections = [];

    for (let element of this.state.formattedCollections) {
      preparedCollections.push(element[1])
    }
    // if (this.state.loaded) {
    //   let items = preparedCollections.map((collection,index)=>{
    //       return <tr key={index}><td>{collection.nameEN}</td><td>{collection.unique}</td><td>{collection.total}</td></tr>
    //     })
    //   template = <tbody>{items}</tbody>
    // }
    // if (this.state.preparedCollections.length > 0) {
    //
    //   template = preparedCollections.map((collection,index)=>{
    //     console.log(collection);
    //     return (
    //       <tr key={index}>
    //         <th scope="row">{collection.id}</th>
    //         <td>{collection.nameEN}</td>
    //         <td>{collection.unique}</td>
    //         <td>{collection.total}</td>
    //       </tr>
    //     )
    //   })
    // }
    return (
      <Layout match={this.props.match} history={this.props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-6">
              {
                this.state.loaded ?
                <DataTable
                  // noTableHead={true}
                  className="data-table-main"
                  columns={columns}
                  data={preparedCollections}
                  customStyles={customStyles}
                />
                : ''
              }

            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Analytics;

// <table className="table table-striped">
//   <thead>
//     <tr>
//       <th scope="col">Коллекция</th>
//       <th scope="col">Уникальных просмотров</th>
//       <th scope="col">Всего просмотров</th>
//     </tr>
//   </thead>
//   {template}
// </table>
