import React, { Component } from 'react';
import Layout from '../Layout';
import {Helmet} from "react-helmet";
// import { DOMAIN_NAME_API } from '../../Constants'
import AddUser from './AddUser';

// import axios from 'axios'
// import moment from 'moment'
// eslint-disable-next-line
// import ru from 'moment/locale/ru'

import * as localeRU from './locale_ru.json'
import * as localeEN from './locale_en.json'
const locale = {
    "ru":localeRU.default,
    "en":localeEN.default
  }

class NewStaff extends Component {
  constructor(props) {
      super(props);
      this.state = {
        clients: [],
        collections: [],
        fabrics: [],
        loaded: false
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');

  }
  render() {
    let helmetTitle = 'Новый сотрудник - CRM Фабрика Фурнитуры';
    const selectedLanguage = localStorage.getItem('lang');
    const interfaceLocale = locale[selectedLanguage];
    return (
      <Layout match={this.props.match} history={this.props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12" style={{minHeight: 15+'rem'}}>
          <AddUser locale={interfaceLocale} match={this.props.match} history={this.props.history} />
        </div>
      </Layout>
    );
  }
}

export default NewStaff;
