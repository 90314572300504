import React, { Component } from 'react';
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'
import update from 'immutability-helper';

class CategorySelectSearch extends Component {
  constructor(props) {
      super(props);
      this.state = {
          categories: [],
          activeID: 0,
          display: 'none',
          value: 'Все'
      };
      this.setWrapper = this.setWrapper.bind(this);
      this.setWrapperInput = this.setWrapperInput.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount(){
    document.addEventListener('mousedown', this.handleClickOutside);

    axios.get(DOMAIN_NAME_API + '/categories')
    .then(res => {
      var response = res.data.categories;
      const newArray = update(response, {$unshift: [{
          nameRU: 'Все',
          nameEN: 'All',
          id:null
        }]
      });
      this.setState({
        categories: newArray,
      });
    });
  }
  setWrapper(node) {
  this.wrapper = node;
  }
  setWrapperInput(node){
    this.wrapperInput = node;
  }
  handleClickOutside(event) {
  if (this.wrapperInput && this.wrapperInput.contains(event.target)){

  } else if (this.wrapper && !this.wrapper.contains(event.target)) {
    if (this.state.display === 'block') {
      this.setState({
        display: 'none'
      })
    }
  }

}
  componentWillUnmount() {
  document.removeEventListener('mousedown', this.handleClickOutside);
}
  _handleClicked = (index,category) => {
    // console.log('category',index,category);
    if (category) {
      this.setState({
        display: 'none',
        activeID: index,
        value: category.nameRU,
      });
      this.props.updateData('CHANGE_CATEGORY',category);
    } else {
      this.setState({
        display: 'none',
        activeID: index
      });
    }
  }
  _handleSelectCategory = () => {
    this.setState({
      display: 'block'
    });
  }
  render() {
    let items = this.state.categories.map((category, index) => {
          return (
            <div className={this.state.activeID === index ? "autocomplete-item active" : "autocomplete-item"} key={index}  onClick={this._handleClicked.bind(this,index,category)} ><div><strong>{category.nameRU}</strong></div></div>
          )
        }
      );
    // const interfaceLocale = this.props.locale;
    return (
      <div className="row">
        <div className="col-12 mb-4">
          <button className="btn btn-secondary " onClick={this._handleSelectCategory.bind(this)}>{this.state.value}</button>
          <div className="autocomplete-container" style={{ display: this.state.display}} ref={this.setWrapper}>
            { items }
          </div>
        </div>
      </div>
    )
    }
}

export default CategorySelectSearch;
