import React, { Component } from 'react';
import Layout from '../Layout';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'
import RequestItem from './RequestItem';
import moment from 'moment'
// eslint-disable-next-line
import ru from 'moment/locale/ru'

class Requests extends Component {
  constructor(props) {
      super(props);
      this.state = {
        clients: [],
        requests: [],
        fabrics: [],
        loaded: false
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
    axios.all([
      axios.get(DOMAIN_NAME_API + '/clients'),
      axios.get(DOMAIN_NAME_API + '/requests?options=ORDER BY created DESC'),
      axios.get(DOMAIN_NAME_API + '/collections'),
      axios.get(DOMAIN_NAME_API + '/fabrics')
    ])
    .then(axios.spread((clientsRes, requestsRes, collectionsRes, fabricsRes) => {
      let clients = clientsRes.data.clients;
      let requests = requestsRes.data.requests;
      let collections = collectionsRes.data.collections;
      let fabrics = fabricsRes.data;

      // Update State with clients
      this.setState({
        clients: clients,
        requests: requests,
        collections: collections,
        fabrics: fabrics,
        loaded: true
      });

    }))
    .catch( error => {
      // handle error
      console.log(error);
    });
  }
  render() {
    let helmetTitle = 'Заявки - CRM Фабрика Фурнитуры',
        template;
    if (this.state.loaded) {
      template = this.state.requests.map((item, index) => {
        item.date = moment.unix(item.created);
        moment.locale('ru');
        item.client = this.state.clients.filter( client => (client.id.toString().indexOf(item.clientID.toString()) !== -1 ) )[0];
        let formattedFabrics = [],
            fabricsList = this.state.fabrics;

        item.fabrics.forEach((fabric, i) => {
          fabricsList.forEach((fabricFromList, i) => {
            if (fabricFromList.id === fabric.fabricID) {
              fabricFromList.collection = this.state.collections.filter( collection => (collection.id.toString().indexOf(fabricFromList.collectionID.toString()) !== -1 ) )[0];
              fabric.description = fabricFromList;
              formattedFabrics.push(fabric);
            }
          });
        });

        item.fabrics = formattedFabrics;

        return <RequestItem key={index} item={item} />
      });
    }
    return (
      <Layout match={this.props.match} history={this.props.history} title={<Link to={'/requests/new'} className="btn btn-secondary ml-2">{'Новая заявка'}</Link>}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row">
            {
              this.state.loaded ?
              template
              : ''
            }
          </div>
        </div>
      </Layout>
    );
  }
}

export default Requests;
