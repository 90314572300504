import React, { useState, useEffect } from 'react'
import { formatPhoneNeo } from '../../Functions'
import { daysList, hoursList } from '../../Constants'

const AddStore = (props) => {
  const [store, updateStore] = useState({
    nameRU:'', city: '', address: '', phoneNumber: '', prefix: '375'
  })
  const [days, setDays] = useState({ start: 0, end: 0 })
  const [hours, setHours] = useState({ start: 0, end: 0 })
  const [phones, updatePhones] = useState([])
  const [workingHours, updateWorkingHours] = useState([])

  const formHandler = (event) => {
    updateStore({ ...store, [event.target.name]: event.target.value})
  }

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      pushPhone();
    }
  }

  const handleChangePrefix = (e, newPrefix) => {
    updateStore({ ...store, prefix: newPrefix})
  }

  const pushPhone = () => {
    if (store.phoneNumber !== '' && store.phoneNumber.length > 8) {
      updatePhones([...phones, {number: store.prefix+store.phoneNumber}]);
      updateStore({ ...store, phoneNumber: ''})
    }
  }

  const pushWorkingHours = () => {
    if (days.start !== 0 && hours.start !== 0) {
      updateWorkingHours([...workingHours, {
        days: `${days.start},${days.end}`,
        hours: `${hours.start},${hours.end}`
      }]);
      setDays({ start: 0, end: 0 })
      setHours({ start: 0, end: 0 })
    }
  }

  const addStoreHandler = () => {
    console.log({
      nameRU: store.nameRU,
      city: store.city,
      address: store.address,
      phones: phones,
      workingHours: workingHours
    });
  }

  return <div className="col-md-12">
    <div className="row">
      <div className="col-md-12 mb-4">
        <input type="text" name="nameRU" className={"input-main-collection-page"} placeholder={"Название"} value={store.nameRU} onChange={formHandler} />
        <input type="text" name="city" className={"input-main-collection-page"} placeholder={"Город"} value={store.city} onChange={formHandler} />
        <input type="text" name="address" className={"input-main-collection-page"} placeholder={"Адрес"} value={store.address} onChange={formHandler} />
      </div>
      <div className="col-md-4">
        {
          phones.map((phone, index) => <p key={index}><i className="fa fa-phone fa-fw" />&nbsp;&nbsp;{formatPhoneNeo(phone.number)}</p>)
        }
        <div className="row">
          <div className="col-3 pr-0">
            <div className="btn-group">
              <button value={store.prefix} data-toggle="dropdown" className="btn btn-secondary btn-sm dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>+{store.prefix}<span className="caret" /></button>
              <ul className="dropdown-menu" x-placement="bottom-start" >
                <li><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { handleChangePrefix(e,"375") }}>+375</button></li>
                <li><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { handleChangePrefix(e,"7") }}>+7</button></li>
              </ul>
            </div>
          </div>
          <div className="col-6 pr-0">
          <input type="text" name="phoneNumber" className="input-phone input-phone-sm" placeholder={"Телефон"} value={store.phoneNumber} onChange={formHandler} onKeyDown={handleInputKeyDown} />

          </div>
          <div className="col-2 pr-0">
          <button className="btn btn-secondary btn-sm" onClick={pushPhone}><i className="fas fa-check" /></button>

          </div>
        </div>

      </div>
      <div className="col-md-8">
        {
          workingHours.map((workingHour, index) => {
            let daysFormatted = `${daysList[+workingHour.days.charAt(0)]}`;
            if (+workingHour.days.charAt(2) !== 0) {
              daysFormatted = daysFormatted + `-${daysList[+workingHour.days.charAt(2)]}`
            }

            let hoursFormatted = `${hoursList[+workingHour.hours.charAt(0)]}`;
            let arrFields = workingHour.hours.split(',');
            if (+arrFields[1] !== 0) {
              hoursFormatted = hoursFormatted + `-${hoursList[+arrFields[1]]}`
            }
            console.log(workingHour)
            return <p key={index}><i className="fa fa-calendar" />&nbsp;&nbsp;{daysFormatted}&nbsp;&nbsp;<i className="fa fa-clock-o" />&nbsp;&nbsp;{hoursFormatted}</p>
          })
        }
        <div className="btn-group">
          <button value={days.start} data-toggle="dropdown" className="btn btn-secondary btn-sm dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>{daysList[days.start]}<span className="caret" /></button>
          <ul className="dropdown-menu" x-placement="bottom-start" >
            {
              daysList.map((day,index) => {
                return <li key={index}><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { setDays({ ...days, start:index }) }}>{daysList[index]}</button></li>
              })
            }
          </ul>
        </div>
        <div className="btn-group ml-2">
          <button value={days.end} data-toggle="dropdown" className="btn btn-secondary btn-sm dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>{daysList[days.end]}<span className="caret" /></button>
          <ul className="dropdown-menu" x-placement="bottom-start" >
            {
              daysList.map((day,index) => {
                return <li key={index}><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { setDays({ ...days, end:index }) }}>{daysList[index]}</button></li>
              })
            }
          </ul>
        </div>

        <div className="btn-group ml-4">
          <button value={hours.start} data-toggle="dropdown" className="btn btn-secondary btn-sm dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>{hoursList[hours.start]}<span className="caret" /></button>
          <ul className="dropdown-menu" x-placement="bottom-start" >
            {
              hoursList.map((day,index) => {
                return <li key={index}><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { setHours({ ...hours, start:index }) }}>{hoursList[index]}</button></li>
              })
            }
          </ul>
        </div>
        {
          hours.start !== 1 ?
          <div className="btn-group ml-2">
            <button value={hours.end} data-toggle="dropdown" className="btn btn-secondary btn-sm dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>{hoursList[hours.end]}<span className="caret" /></button>
            <ul className="dropdown-menu" x-placement="bottom-start" >
              {
                hoursList.map((day,index) => {
                  return <li key={index}><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { setHours({ ...hours, end:index }) }}>{hoursList[index]}</button></li>
                })
              }
            </ul>
          </div> : ''
        }
        <button className="btn btn-secondary btn-sm ml-2" onClick={pushWorkingHours}><i className="fas fa-check" /></button>
      </div>
      <div className="col-md-12 mt-3">
        <button className="btn btn-secondary" onClick={addStoreHandler}>Добавить магазин</button>
      </div>
    </div>
  </div>
}
// <button className="btn btn-secondary btn-sm" onClick={clickButton}><i className="fas fa-check" /></button>

const ListStores = (props) => {
  const [stores, updateStores] = useState([])

  useEffect(() => {
    async function fetchData() {
      // const response = await axios.get(`${DOMAIN_NAME_API}/contacts?options=WHERE clientID=${props.clientID}`)
      // setContacts(response.data.contacts)
      await updateStores([
        { id:1, nameRU:'ТЦ "Озерцо"', city: 'Минск', address: 'Меньковский тракт, 43', phones: [{id: 12, number: "375447711681"},{id: 13, number: "79142532875"}], workingHours: [{id: 1, days: "пн-вс",hours: "10:00 - 20:00"}] }
      ])
    }
    fetchData();
  },[props.clientID]);

  return <table className="table table-borderless table-sm"><tbody>
    {
      stores.map((store,index)=>{
        return  <tr key={store.id}>
                  <td>{store.nameRU}</td>
                  <td><i className="fa fa-map-marker-alt" />&nbsp;&nbsp;{`${store.city}, ${store.address}`}</td>
                  <td>
                    {
                      store.phones.map((phone) => <p key={phone.id}><i className="fa fa-phone fa-fw" />&nbsp;&nbsp;{formatPhoneNeo(phone.number)}</p>)
                    }
                  </td>
                  <td>
                    {
                      store.workingHours.map((item) => <p key={item.id}><i className="fa fa-calendar" />&nbsp;&nbsp;{item.days}&nbsp;&nbsp;<i className="fa fa-clock-o" />&nbsp;&nbsp;{item.hours}</p>)
                    }
                  </td>
                </tr>
      })
    }
    </tbody></table>
}

function Stores(){
  return <div className="col-md-12">
    <ListStores />
    <AddStore />
  </div>
}

export default Stores

// <li><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { handleChangePrefix(e,"375") }}>+375</button></li>
// <li><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { handleChangePrefix(e,"7") }}>+7</button></li>
// <p>Нет магазинов</p>
// <h5>Нет магазинов</h5>
