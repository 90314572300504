import React, { Component } from 'react';
import Layout from '../Layout';
import {Helmet} from "react-helmet";
import { DOMAIN_NAME_API } from '../../Constants'
import { randomInteger } from '../../Functions'
import axios from 'axios'
import moment from 'moment'
// eslint-disable-next-line
import ru from 'moment/locale/ru'
// import update from 'immutability-helper';

const statusColors = {
    "new":"#3FC380",
    "processed":"#BDC3C7"
};

class Leads extends Component {
  constructor(props) {
      super(props);
      this.state = {
        leads: [],
        loaded: false
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');

    axios.get(DOMAIN_NAME_API + '/leads')
      .then((response) => {
        let leads = response.data.leads;
        this.setState({
          leads: leads,
          loaded: true
        });
     })
    .catch(function (error) {
        console.log(error);
    });

  }
  _handleClickChangeStatus = (event, index, lead, status) => {
    event.preventDefault();

    lead.status = status;

    const url = DOMAIN_NAME_API + '/leads/'+lead.id;
    axios.put(url, lead)
      .then( (response) => {
          console.log(response);
          const leads = this.state.leads;
          leads[index]['status'] = status;
          this.setState({
            leads: leads
          })
        }
      );
  }
  render() {
    let helmetTitle = 'Лиды - CRM Фабрика Фурнитуры';
    let template;
    if (!this.state.loaded) {
        template =
        <>
          <div className="lead-block">
              <div className="lead-item-title">
                <h5><em className="fa fa-circle pr-2 loading-placeholder"/><i className={"loading-placeholder fas fa-square-full-"+randomInteger(2,2)} /></h5>
              </div>
              <div className="lead-item-description">
                  <h5><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></h5>
                  <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                  <p>
                      <i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} />
                      &nbsp;&nbsp;
                      <i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} />
                  </p>
              </div>
              <div className="lead-item-message">
                  <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(5,7)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(6,10)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} /></p>
              </div>
              <div className="lead-item-controls">
              </div>
          </div>
          <div className="lead-block">
              <div className="lead-item-title">
                <h5><em className="fa fa-circle pr-2 loading-placeholder"/><i className={"loading-placeholder fas fa-square-full-"+randomInteger(2,2)} /></h5>
              </div>
              <div className="lead-item-description">
                  <h5><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></h5>
                  <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                  <p>
                      <i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} />
                      &nbsp;&nbsp;
                      <i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} />
                  </p>
              </div>
              <div className="lead-item-message">
                <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(5,7)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(6,10)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} /></p>
              </div>
              <div className="lead-item-controls">
              </div>
          </div>
          <div className="lead-block">
              <div className="lead-item-title">
                <h5><em className="fa fa-circle pr-2 loading-placeholder"/><i className={"loading-placeholder fas fa-square-full-"+randomInteger(2,2)} /></h5>
              </div>
              <div className="lead-item-description">
                  <h5><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></h5>
                  <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                  <p>
                      <i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} />
                      &nbsp;&nbsp;
                      <i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} />
                  </p>
              </div>
              <div className="lead-item-message">
                <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(5,7)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(6,10)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} />&nbsp;<i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,15)} /></p>
              </div>
              <div className="lead-item-controls">
              </div>
          </div>
        </>

    } else {
      if (this.state.leads.length === 0) {
          template = <h2>Заявок нет</h2>
      }
      else {
        let dataForSearch = this.state.leads.filter( lead => lead.status !== 'deleted' );
        template = dataForSearch.map((lead, index) => {
          lead.date = moment.unix(lead.created);
          moment.locale('ru');
            return (
              <div className="lead-block ml-4" key={index}>
                  <div className="lead-item-title">
                    <h5><em className="fa fa-circle pr-2" style={{color: statusColors[lead.status]}} />#{lead.id}</h5>
                  </div>
                  <div className="lead-item-description">
                      <h5>{lead.name}</h5>
                      <p>{lead.contacts}</p>
                      <p>
                          <i className="fa fa-clock-o"></i>&nbsp;&nbsp;{lead.date.format('HH:mm')}
                          &nbsp;&nbsp;
                          <i className="fa fa-calendar"></i>&nbsp;&nbsp;{lead.date.format('DD MMMM YYYY')}
                      </p>
                  </div>
                  <div className="lead-item-message">
                      <p>{lead.message}</p>
                  </div>
                  <div className="lead-item-controls">
                    {
                      lead.status === 'new' ? <button type="button" className="btn btn-secondary button-process" onClick={(e)=>{ this._handleClickChangeStatus(e, index, lead, 'processed') } }><i className="fas fa-check"></i></button> : ''
                    }
                    <button type="button" className="btn btn-secondary button-process" onClick={(e)=>{ this._handleClickChangeStatus(e, index, lead, 'deleted') } }><i className="fas fa-trash"></i></button>
                  </div>
              </div>
            )
        });
      }
    }
    return (
      <Layout match={this.props.match} history={this.props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row">
            {template}
          </div>
        </div>
      </Layout>
    );
  }
}

export default Leads;
