import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { DOMAIN_NAME_API } from '../../Constants'
// import Image from '../Image'
import ImageStandart from '../../common/ImageStandart'
// import { useScrollPosition } from '../../hooks/useScrollPosition.hook.jsx'
import DeliveryContext from '../../context/DeliveryContext'

const FabricItemInTable = (props) => {
  const [form, setForm] = useState({
    length: '',
    price: ''
  })

  const { dispatch } = useContext(DeliveryContext)

  const changeHandler = (event) => {
    // eslint-disable-next-line
    let prepareStr = event.target.value.replace(/[^.\d]+/g,"").replace(/^([^\.]*\.)|\./g, '$1' )
    let arrFields = prepareStr.split('.');

    // I tried to victory the regexp to format number 10.00 or 100.00
    // Unfortunately, I am using a crutch
    if (arrFields[1]) {
      if (arrFields[1] !== '' && arrFields[1].length <= 2) {
        setForm({ ...form, [event.target.name]: prepareStr })
      }
    } else {
      setForm({ ...form, [event.target.name]: prepareStr })
    }
  }

  const handleClicked = (item) => {
    dispatch({
      type: 'ADD_FABRIC',
      payload: { ...item, length: form.length, price: form.price }
    })
    // props.updateData('SELECTED_FABRIC',{ ...item, length: form.length, price: form.price })
    setForm({
      length: '',
      price: ''
    })
  }
  let item = props.item
  if (props.preItem) {
    return <>
      <tr key={item.id + '-title'}>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
        <td>&nbsp;</td>
      </tr>
      <tr key={item.id}>
        <th className="image-cell"><ImageStandart url={item.image} height={50} customClass="rounded-12"/></th>
        <td>{item.collection.nameEN} {item.nameEN}</td>
        <td>
          <input
            type="text"
            className="input-cut fabric-item-input rounded-12"
            name="length"
            placeholder={0}
            value={form.length}
            onChange={changeHandler}
          />
        </td>
        <td>
          <input
            type="text"
            className="input-cut fabric-item-input rounded-12"
            name="price"
            placeholder={0}
            value={form.price}
            onChange={changeHandler}
          />
        </td>
        <td><button className="btn btn-secondary" onClick={(e)=>{ handleClicked(item) }}><i className="fas fa-check" /></button></td>
      </tr>
    </>
  }
  return (
    <tr key={item.id}>
      <th className="image-cell"><ImageStandart url={item.image} height={50} customClass="rounded-12"/></th>
      <td>{item.collection.nameEN} {item.nameEN}</td>
      <td>
        <input
          type="text"
          className="input-cut fabric-item-input rounded-12"
          name="length"
          placeholder={0}
          value={form.length}
          onChange={changeHandler}
        />
      </td>
      <td>
        <input
          type="text"
          className="input-cut fabric-item-input rounded-12"
          name="price"
          placeholder={0}
          value={form.price}
          onChange={changeHandler}
        />
      </td>
      <td><button className="btn btn-secondary" onClick={(e)=>{ handleClicked(item) }}><i className="fas fa-check" /></button></td>
    </tr>
  )
}

const SearchFabrics = (props) => {
  const [searchValue, updateSearchValue] = useState('')

  const changeHandler = (event) => {
    updateSearchValue(event.target.value)
    sortData(event.target.value)
  }

  const resetSearch = (e) => {
    updateSearchValue('')
    sortData('')
  }

  const sortData = (value) => {
    let filteredData = [],
        arraySearch = value.split(' '),
        dataForSearch = props.items;

    if (value !== '') {
        switch (arraySearch.length) {
          case 1:
            filteredData = dataForSearch.filter(
              item => (
                item.collection.nameEN.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                item.collection.nameRU.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                item.id.toString().indexOf(value.toString()) !== -1
              )
            )
            break;
          case 2:
          filteredData = dataForSearch.filter(
             item =>
                 item.nameEN.toLowerCase().indexOf(arraySearch[0].toLowerCase()) !== -1 &&
                 item.nameEN.toLowerCase().indexOf(arraySearch[1].toLowerCase()) !== -1
               );
            break;
          default:
            break;
        }
        props.updateData("SEARCH_FABRICS",{ searchValue: value, sortedData: filteredData })
    } else {
      props.updateData("SEARCH_FABRICS",{ searchValue: '', sortedData: [] })
    }
  }

  return (
    <div className="col-md-12">
      <div className="input-group">
        {
          searchValue === '' ?
          ( <span className="input-group-addon input-main-icon darkgray"><i className="fa fa-search" aria-hidden="true"></i></span> ) :
          (<span className="input-group-addon input-main-icon" onClick={resetSearch} style={{cursor: 'pointer'}}><i className="fa fa-times" aria-hidden="true"></i></span>)
        }
        <input autoFocus
        type="text"
        className="input-main"
        placeholder="Поиск ткани"
        name="length"
        value={searchValue}
        onChange={changeHandler}
        />
      </div>
    </div>
  )
}

function SelectFabrics(props){
  const [fabrics, updateFabrics] = useState([])
  const [searchStatus, setSearchStatus] = useState(false)
  const [fabricsSorted, updateFabricsSorted] = useState([])
  const [itemsLimit, setItemsLimit] = useState(16)

  useEffect(() => {
    async function fetchData() {
      const collectionsRes = await axios.get(`${DOMAIN_NAME_API}/collections?options=ORDER BY nameEN`)
      const fabricsRes = await axios.get(`${DOMAIN_NAME_API}/fabrics?options=ORDER BY collectionID`)

      let collections = collectionsRes.data.collections;
      let fabrics = fabricsRes.data;
      let formattedFabrics = [];

      collections.forEach((collection, i) => {
        fabrics.forEach((fabric, i) => {
          if (collection.id === fabric.collectionID) {
            fabric.collection = collection;
            fabric.requestedLength = 0;
            formattedFabrics.push(fabric);
          }
        });
      });

      updateFabrics(formattedFabrics)
    }
    fetchData()
  },[])


  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom-100 <= window.innerHeight
  }

  useEffect( () => {
    const wrappedElement = document.getElementById('root');

    function handleScroll() {
      // const scrollTop = window.scrollY;
      // console.log(scrollTop);
      if (isBottom(wrappedElement)) {
        setItemsLimit(itemsLimit+8)
      }
    }
    // subscribe event
    // console.log('subscribe event')
    window.addEventListener("scroll", handleScroll)
    return () => {
      // unsubscribe event
      // console.log('unsubscribe event')
      window.removeEventListener("scroll", handleScroll)
    }
  }, [itemsLimit])

  const updateData = (value,data) => {
    switch(value) {
      // case 'SELECTED_FABRIC':
      //   // console.log('SELECTED_FABRIC');
      //   // this.setState({sortedCollections: [], searchStatus: false})
      //   props.updateData('ADD_FABRIC',data);
      //   break;
      case 'SEARCH_FABRICS':
        if(data.searchValue !== ''){
          setSearchStatus(true)
          updateFabricsSorted(data.sortedData)
          setItemsLimit(16)
        } else {
          setSearchStatus(false)
          updateFabricsSorted([])
          setItemsLimit(16)
        }
        break;
      default:
        break;
    }
  }

  let fabricItems = '',
      prevCollection = 'start'

    if (searchStatus) {
      fabricItems = fabricsSorted.slice(0,itemsLimit).map((item,index)=>{
        if (item.collection.nameEN !== prevCollection) {
          prevCollection = item.collection.nameEN;
          return (
            <FabricItemInTable key={item.id} item={item} updateData={updateData} preItem={index !== 0 ? true : false} />
          )
        } else {
          return (
            <FabricItemInTable key={item.id} item={item} updateData={updateData} preItem={false} />
          )
        }
      });
    } else {
      fabricItems = fabrics.slice(0,itemsLimit).map((item,index)=>{
        if (item.collection.nameEN !== prevCollection) {
          prevCollection = item.collection.nameEN;
          return (
            <FabricItemInTable key={item.id} item={item} updateData={updateData} preItem={index !== 0 ? true : false} />
          )
        } else {
          return (
            <FabricItemInTable key={item.id} item={item} updateData={updateData} preItem={false} />
          )
        }
      })
    }
  return <div className={props.customClass ? `col-md-12 ${props.customClass}` : "col-md-12"}>
    <div className="row">
      <SearchFabrics updateData={updateData} items={fabrics} searchStatus={searchStatus}  />
      <div className="col-md-12">
        {
          fabricItems ?
          <table className="table table-borderless table-sm table-responsive-md table-fabrics-list">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Название</th>
                <th scope="col">Кол-во метров</th>
                <th scope="col">Цена за метр</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              { fabricItems }
            </tbody>
          </table>
          : ''
        }
      </div>
    </div>
  </div>
}

export default SelectFabrics

// Старая версия отслеживания скролла
// useScrollPosition( () => {
//   const wrappedElement = document.getElementById('root');
//   if (isBottom(wrappedElement)) {
//     setItemsLimit(itemsLimit+8)
//   }
// }, [itemsLimit])

// if (searchStatus) {
//   fabricItems = fabricsSorted.slice(0,itemsLimit).map((item,index)=>{
//     if (item.collection.nameEN !== prevCollection) {
//       prevCollection = item.collection.nameEN;
//       return (
//         <FabricItem key={item.id} updateData={updateData} item={item} preItem={<div className="col-md-12 mb-2"><h4>{item.collection.nameEN}</h4></div>} />
//       )
//     } else {
//       return (
//         <FabricItem key={item.id} updateData={updateData} item={item} />
//       )
//     }
//   });
// } else {
//   fabricItems = fabrics.slice(0,itemsLimit).map((item)=>{
//     if (item.collection.nameEN !== prevCollection) {
//       prevCollection = item.collection.nameEN;
//       return (
//         <FabricItem key={item.id} updateData={updateData} item={item} preItem={<div className="col-md-12 mb-2"><h4>{item.collection.nameEN}</h4></div>} />
//       )
//     } else {
//       return (
//         <FabricItem key={item.id} updateData={updateData} item={item} />
//       )
//     }
//   })
// }

// searchStatus ?
// fabricsSorted.slice(0,itemsLimit).map((item)=>{
//   if (item.collection.nameEN !== prevCollection) {
//     prevCollection = item.collection.nameEN;
//     return (
//       <FabricItem key={item.id} updateData={updateData} item={item} preItem={<div className="col-md-12 mb-2"><h4>{item.collection.nameEN}</h4></div>} />
//     )
//   } else {
//     return (
//       <FabricItem key={item.id} updateData={updateData} item={item} />
//     )
//   }
// }) :
// fabrics.slice(0,itemsLimit).map((item)=>{
//   if (item.collection.nameEN !== prevCollection) {
//     prevCollection = item.collection.nameEN;
//     return (
//       <FabricItem key={item.id} updateData={updateData} item={item} preItem={<div className="col-md-12 mb-2"><h4>{item.collection.nameEN}</h4></div>} />
//     )
//   } else {
//     return (
//       <FabricItem key={item.id} updateData={updateData} item={item} />
//     )
//   }
// })

// const FabricItem2 = (props) => {
//
//   const handleClicked = (item) => {
//     props.updateData('SELECTED_FABRIC',item)
//   }
//
//   let item = props.item
//   return (
//     <div className="col-md-12 mb-3">
//       <div className="row">
//         {props.preItem}
//         <div className="col-md-12" onClick={(e)=>{ handleClicked(item) }}>
//           <div className="row">
//             <div className="col-md-5">
//               <div className="imageStockItemInRequestPape">
//                 <Image url={item.image} height={75} customClass="ml-3" />
//               </div>
//             </div>
//             <div className="col-md-6 pl-0">
//               <p className="" style={{marginTop: 1+'rem'}}>{item.nameEN}</p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
