import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';

import Home from './components/home/Home'
import Categories from './components/categories/Categories'
import CategoryEdit from './components/categories/CategoryEdit'
import Collections from './components/collections/Collections'
import CollectionPage from './components/collections/CollectionPage'
import CollectionNew from './components/collections/CollectionNew'
import Stock from './components/stock/Stock'
import Gallery from './components/gallery/Gallery'
import Settings from './components/settings/Settings'
import Orders from './components/orders/Orders'
import Clients from './components/clients/Clients'
import ClientPage from './components/clients/ClientPage'
import NewClientPage from './components/clients/NewClientPage'
import Leads from './components/leads/Leads'
import Requests from './components/requests/Requests'
import NewRequest from './components/requests/NewRequestPage'
import Analytics from './components/analytics/Analytics'
// import Handling from './components/handling/Handling'
import HandlingsPage from './pages/HandlingsPage'
import NewHandlingPage from './pages/NewHandlingPage'
import Staff from './components/staff/Staff'
import NewStaff from './components/staff/NewStaffPage'
import DeliveriesPage from './pages/DeliveriesPage'
import NewDeliveryPage from './pages/NewDeliveryPage'
import PriceListPage from './pages/PriceListPage'

import QR from './components/qr.js'
import PDF from './components/pdf.js'

import Login from './components/auth/Login'
import Logout from './components/auth/Logout'
import Error404 from './components/Error404'

function App() {
  return (
    <Router>
      <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/categories/:id' component={CategoryEdit} />
          <Route path='/categories' component={Categories} />
          <Route path='/collections/new' component={CollectionNew} />
          <Route path='/collections/:id' component={CollectionPage} />
          <Route path='/collections' component={Collections} />
          <Route path='/qr/:categoryName/:collectionName' component={QR} />
          <Route path='/pdf/:categoryName/:collectionName' component={PDF} />
          <Route path='/stock' component={Stock} />
          <Route path='/gallery' component={Gallery} />
          <Route path='/orders' component={Orders} />
          <Route path='/clients/new' component={NewClientPage} />
          <Route path='/clients/:id' component={ClientPage} />
          <Route path='/clients' component={Clients} />
          <Route path='/leads' component={Leads} />
          <Route path='/requests/new' component={NewRequest} />
          <Route path='/requests' component={Requests} />
          <Route path='/analytics' component={Analytics} />
          <Route path='/handling/:id' component={NewHandlingPage} />
          <Route path='/handling' component={HandlingsPage} />
          <Route path='/deliveries/new' component={NewDeliveryPage} />
          <Route path='/deliveries' component={DeliveriesPage} />
          <Route path='/staff/new' component={NewStaff} />
          <Route path='/staff' component={Staff} />
          <Route path='/priceLists' component={PriceListPage} />
          <Route path='/settings' component={Settings} />
          <Route path='/logout' component={Logout} />
          <Route path='/auth' component={Login} />
          <Route path='/*' component={Error404} />
      </Switch>
    </Router>
  );
}

export default App;

// <img src={logo} className="App-logo" alt="logo" />
