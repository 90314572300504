import React, { useState, useEffect, useContext, useRef } from 'react';
import DeliveryContext from '../context/DeliveryContext'

function SelectClient(props){
  const [activeID, setActiveID] = useState(0)
  const [display, setDisplay] = useState('none')
  const [newItem, setNewItem] = useState('')
  const [sortedItems, updateSortedItems] = useState([])
  const [selected, updateSelected] = useState(false)

  const { dispatch } = useContext(DeliveryContext)

  useEffect(() => {
    updateSortedItems(props.items)
  },[props.items])

  // create element ref
  const innerRef = useRef(null)

  // function click outside
  const handleClickOutside = (event) => {
    if (!innerRef.current.contains(event.target)) {
      setDisplay('none')
    }
  }

  useEffect(() => {
    const rootElement = document.getElementById("root")

    // subscribe event
    rootElement.addEventListener("mousedown", handleClickOutside)

    return () => {
      // unsubscribe event
      rootElement.removeEventListener("mousedown", handleClickOutside)
    };
  }, [])

  const handleClicked = (event,index,item) => {
    event.preventDefault();
    setDisplay('none')
    setActiveID(-1)
    setNewItem(item.nameRU)
    updateSelected(true)
    dispatch({
      type: 'SELECT_CLIENT',
      payload: item || selected
    })
    // this.props.updateData('ADD_CLIENT',item);
  }

  const handleNewItem = (event) => {
    let display = 'none';

    if (event.target.value !== ''){
      display = 'block';
      setNewItem(event.target.value)
      setDisplay(display)
      updateSelected(false)
      sortData()
    } else {
      setNewItem('')
      setDisplay(display)
      setActiveID(-1)
      updateSelected(false)
      sortData()
    }
  }

  const handleFocusInput = (event) => {
    setNewItem('')
    setDisplay('block')
    setActiveID(-1)
  }

  const handleInputKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
        event.preventDefault();

        if (activeID !== -1) {
          handleClicked(event,activeID,sortedItems[activeID])
        }
        break;
      case 'ArrowDown':
        event.preventDefault();

        if (!sortedItems) {
          return;
        } else if (activeID !== sortedItems.length-1) {
          setActiveID(activeID+1)
        }
        break;
      case 'ArrowUp':
        event.preventDefault();

        if (!sortedItems) {
          return;
        } else if (activeID !== 0) {
          setActiveID(activeID-1)
        }
        break;
      case 'Escape':
        event.preventDefault();

        if (!sortedItems) { return; }
        setNewItem('')
        setDisplay('none')
        setActiveID(-1)
        break;
      default:
        break;
    }
  }

  const sortData = () => {
    // SEARCH BLOCK
    let searchData = newItem,
        arraySearch = searchData.split(' '),
        sortedItems = props.items,
        dataForSearch = props.items;

    if(arraySearch.length === 1) {
      sortedItems = dataForSearch.filter( item => (item.nameRU.toLowerCase().indexOf(searchData.toLowerCase()) !== -1 ) );
    } else if (arraySearch.length >= 2) {
        sortedItems = dataForSearch.filter(
         item =>
             item.nameRU.toLowerCase().indexOf(arraySearch[0].toLowerCase()) !== -1 &&
             item.nameRU.toLowerCase().indexOf(arraySearch[1].toLowerCase()) !== -1
        );
      } else if (this.state.newItem === '') {
        sortedItems = dataForSearch;
      }
      updateSortedItems(sortedItems)
  }
  // console.log(activeID,display,newItem,sortedItems,selected)
  return (
    <div className={props.customClass ? "col-md-6 " + props.customClass : "col-md-6"}>
      <input type="text" className="input-main-collection-page full-width" placeholder={props.placeholder} value={newItem} onChange={handleNewItem} onFocus={handleFocusInput} onKeyDown={handleInputKeyDown} />
      <div className="autocomplete-container" style={{ display: display, textAlign: 'left'}} ref={innerRef} >
        {
          sortedItems.length === 0 && newItem !== '' ?
          <div className="autocomplete-item"><div>Не найдено</div></div>
          : ''
        }
        {
          sortedItems.map((item, index) => {
              return (
                <div className={activeID === index ? "autocomplete-item active" : "autocomplete-item"} key={index} onClick={(e)=>handleClicked(e,index,item)} ><div><strong>{item.nameRU}</strong></div></div>
              )
            }
          )
        }
      </div>
    </div>
  )

}

export default SelectClient;
