import React, { Component } from 'react';
// import { DOMAIN_NAME_API } from '../../Constants'
// import { declOfNum, formatFloat } from '../../Functions'
// import axios from 'axios'
import Image from '../Image'

// const statusColors = ["#BDC3C7","#3FC380"];

class RequestedFabricItem extends Component {
  constructor(props) {
      super(props);
      this.state = {
        requestedLength: 0
      }
  }
  _handleForm1 = (event) => {
    // eslint-disable-next-line
    let requestedLength = event.target.value.replace(/[^.\d]+/g,"").replace( /^([^\.]*\.)|\./g, '$1' );
    this.setState({ requestedLength: requestedLength });
    this.props.updateData("EDIT_VALUE_FABRIC",{ requestedLength: requestedLength, rowID: this.props.rowID });
  }
  // _handleInputKeyDown = (event) => {
  //   switch (event.key) {
  //     case 'Enter':
  //       event.preventDefault();
  //       // this.createStock();
  //       break;
  //     default:
  //       break;
  //   }
  // }
  _handleRemove = (event) => {
    // console.log('remove',this.props.rowID);
    this.props.updateData("REMOVE_FABRIC", {rowID: this.props.rowID});
  }
render() {
  let item = this.props.item;
  return (
    <div className="col-md-12 mb-3">
      <div className="row">
        <div className="col-md-2">
          <div className="imageFabricItem">
            <Image url={item.image} height={75} customClass="ml-3" />
          </div>
        </div>
        <div className="col-md-5 pl-0">
          <div className="nameFabricItem">
            <p className="">{item.collection.nameEN} &nbsp;&#8212;&nbsp; {item.nameEN}</p>
          </div>
        </div>
        <div className="col-md-2 pl-0">
          <div className="valueFabricItem">
            <div className="input-group">
              <input type="text" className="input-main" placeholder={'Длина'} style={{fontSize: 1+'rem'}} value={this.state.requestedLength > 0 ? this.state.requestedLength : ''} onChange={this._handleForm1.bind(this)} />
            </div>
          </div>
        </div>
        <div className="col-md-2 pl-0">
          <div className="nameFabricItem">
            <p className="text-right"><button className="btn btn-secondary btn-sm" onClick={()=>{this._handleRemove()}}>X</button></p>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default RequestedFabricItem;

// <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.createStock()}}>Добавить</button>

// <div className="col-md-12 mb-3">
//   <div className="row">
//     <div className="col-md-4">
//       <h5>{item.client.nameRU}</h5>
//       <p>
//           <i className="fa fa-clock-o"></i>&nbsp;&nbsp;{item.date.format('HH:mm')}
//           &nbsp;&nbsp;
//           <i className="fa fa-calendar"></i>&nbsp;&nbsp;{item.date.format('DD MMMM YYYY')}
//       </p>
//     </div>
//     <div className="col-md-3 pl-0">
//       {
//         this.state.fabricsVisible ?
//         <>
//           <p style={{marginTop: 1+'rem'}} onClick={()=>{this.openFabrics()}}>{formatFloat(totalBalance) + ' ' + declOfNum(formatFloat(totalBalance)*10, ['метр', 'метра', 'метров'])}</p>
//           {template}
//         </>
//         : <p style={{marginTop: 1+'rem'}} onClick={()=>{this.openFabrics()}}>{formatFloat(totalBalance) + ' ' + declOfNum(formatFloat(totalBalance)*10, ['метр', 'метра', 'метров'])} {'('+item.fabrics.length+')'}</p>
//       }
//     </div>
//     <div className="col-md-3">
//
//     </div>
//   </div>
// </div>
