import React, { Component } from 'react';
import Layout from '../Layout';
import axios from 'axios'
import Dropzone from 'react-dropzone'
import {Helmet} from "react-helmet";
import { DOMAIN_NAME_API, IMAGE_SERVER, CDN_SERVER} from '../../Constants'

class Gallery extends Component {
  constructor(props) {
      super(props);
      this.state = {
        images: [],
        itemsLimit: 16,
        itemindex: 0,
        modalData: {},
        modalActive: false
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
    axios.get(DOMAIN_NAME_API + '/images?options=ORDER BY id DESC')
    .then(res => {
      var images = res.data.images;
      this.setState({
        images: images
      })
    });
    document.addEventListener('scroll', this.trackScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }
  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }
  trackScrolling = () => {
    const wrappedElement = document.getElementById('root');
    if (this.isBottom(wrappedElement)) {
      this.setState({
        itemsLimit: this.state.itemsLimit + 8
      })
      // console.log('header bottom reached');
      // document.removeEventListener('scroll', this.trackScrolling);
    }
  };
  onDrop(files) {
    if(files.length === 1){
      let file = files[0];
      this.loadImageInServer(file);
    } else {
      files.forEach(file => {
        this.loadImageInServer(file)
      });
    }
  }
  loadImageInServer(image){
    let data = new FormData();
    data.append('image',image);

    // load image on IMAGE_SERVER
    axios.post(IMAGE_SERVER + '/imageUpload2.php', data)
         .then((res) => {

           let uploadedImage = res.data;

            // add image to dataBase
           axios.post(DOMAIN_NAME_API + '/images', uploadedImage)
           .then((res2) => {
             let newImages = this.state.images;
                 newImages.unshift(res2.data);
             this.setState({ images: newImages });
           })

           // const initialData = this.state.collection;
           // let newData = update(initialData, {image: {$set: res.data.imageURL}});
           // this.setState({ collection: newData },()=> { this.saveCollection() });
    });
  }
  _handleClickImageItem(index){
    console.log('image',this.state.images[index]);
    this.setState({
      itemindex: index,
      modalData: this.state.images[index],
      modalActive: true
    })
  }
  render() {
    let helmetTitle = 'Галерея - CRM Фабрика Фурнитуры',
        modalActive = this.state.modalActive,
        modalData = this.state.modalData;
    return (
      <Layout match={this.props.match} history={this.props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-12">
              <h1>&nbsp;</h1>
              <h1>&nbsp;</h1>
            </div>
            <div className="col-md-12">
              <div className="row">

                <div className="col-md-3">
                  <div className="collection-item">
                    <img src={CDN_SERVER + '/images/no-image.jpg'} className="img-fluid rounded" alt="alt" />
                    <Dropzone onDrop={this.onDrop.bind(this)}>
                      {({getRootProps, getInputProps}) => (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="image-img-overlay">
                              <h5 className="image-title" style={{bottom: 20+'%'}}>{"Нажмите, чтобы загрузить, или перетащите сюда изображение"}</h5>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                </div>

                {
                  this.state.images.length !== 0 ?
                  this.state.images.slice(0,this.state.itemsLimit).map((image, index) => {
                      return (
                        <div className="col-md-3" key={index} data-toggle="modal" data-target="#exampleModalCenter" onClick={()=>{this._handleClickImageItem(index)}}>
                          <div className="collection-item">
                            <img src={CDN_SERVER + image.url + '?w=500&h=375'} className="img-fluid rounded" alt="alt" />
                            <div className="collection-img-overlay">
                              <h5 className="collection-title">{image.crop ? image.crop : ''}</h5>
                            </div>
                          </div>
                        </div>
                      )
                  })
                  : ''
                }
              </div>
            </div>
          </div>
        </div>

          {
            // Modal
          }
          <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-custom" role="document">
              <div className="modal-content">


                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <img src={modalActive ? CDN_SERVER + modalData.url : CDN_SERVER + '/images/no-image.jpg'} className="img-fluid rounded" alt="alt" />
                      </div>
                      <div className="col-md-6">
                        <img src={modalActive ? CDN_SERVER + modalData.url + '?w=1200&h=900' : CDN_SERVER + '/images/no-image.jpg'} className="img-fluid rounded" alt="alt" />
                      </div>
                      <div className="col-md-6">
                        <h5 className="text-center">Original</h5>
                      </div>
                      <div className="col-md-6">
                        <h5 className="text-center">Modified</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary" data-dismiss="modal">Save changes</button>
                </div>
              </div>
            </div>
          </div>

      </Layout>
    );
  }
}

export default Gallery;
