import React, { useState, useEffect } from 'react';
// import Loading from '../../common/Loading'
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'
import { formatPhoneNeo } from '../../Functions'

// import useHttp from '../../hooks/http.hook'

const ContactForms = (props) => {
  const [form, setForm] = useState({
    email: '', phone: '', nameRU: '', comments: '', prefix: '375'
  })

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value})
  }

  const handleChangePrefix = (e, newPrefix) => {
    setForm({ ...form, prefix: newPrefix})
  }

  const clickButton = async (e) => {
    e.preventDefault();

    if (form.email !== '' || form.phone !== '') {
      try {
        axios.defaults.headers.common['token'] = localStorage.getItem('token');

        let data = {
          clientID: props.clientID,
          address: form.email !== '' ? form.email : null,
          nameRU: form.nameRU,
          comments: form.comments
        }

        // if phone => add phone
        if (form.phone !== '') {
          data.number = `${form.prefix}${form.phone}`;
          // data.country = "BY";
        }

        const response = await axios.post(`${DOMAIN_NAME_API}/contacts`,data)
        await props.pushContact(response.data);
        await setForm({ email: '', phone: '', nameRU: '', comments: ''})
      } catch (e) {
        console.log(e);
      }
    }
    props.handler();
  }
  return <div className="col-md-12">
    <div className="row">
      <div className="col-1 pr-0">
        <div className="btn-group">
          <button value={form.prefix} data-toggle="dropdown" className="btn btn-secondary btn-sm dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>+{form.prefix}<span className="caret" /></button>
          <ul className="dropdown-menu" x-placement="bottom-start" >
            <li><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { handleChangePrefix(e,"375") }}>+375</button></li>
            <li><button className="dropdown-item" style={{cursor:'pointer'}} onClick={(e) => { handleChangePrefix(e,"7") }}>+7</button></li>
          </ul>
        </div>
      </div>
      <div className="col-2 pl-0 pr-0">
        <input type="text" name="phone" className="input-phone input-phone-sm" style={{maxWidth: 12+'rem'}} placeholder={"Телефон"} value={form.phone} onChange={changeHandler} />
      </div>
      <div className="col pr-0">
        <input type="text" name="email" className="input-phone input-phone-sm" placeholder={"Электронная почта"} value={form.email} onChange={changeHandler} />
      </div>
      <div className="col pr-0">
        <input type="text" name="nameRU" className="input-phone input-phone-sm" placeholder={"Название"} value={form.nameRU} onChange={changeHandler} />
      </div>
      <div className="col pr-0">
        <input type="text" name="comments" className="input-phone input-phone-sm" placeholder={"Комментарий"} value={form.comments} onChange={changeHandler} />
      </div>
      <div className="col-1 pr-0">
        <button className="btn btn-secondary btn-sm" onClick={clickButton}><i className="fas fa-check" /></button>
      </div>
    </div>
  </div>
}

const AddContact = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const visibleHandler = () => {
    setIsVisible(isVisible ? false : true);
  }
  if (isVisible) {
    return <ContactForms handler={visibleHandler} clientID={props.clientID} pushContact={props.pushContact} />
  }
  return <div className="col-md-12"><button className="btn btn-secondary btn-sm ml-1" onClick={visibleHandler}><i className="fas fa-plus"></i></button></div>;
}

function ClientContacts(props) {
  const [contacts, setContacts] = useState([]);

  const pushContact = (newContact) => {
    setContacts([...contacts, newContact]);
  }

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${DOMAIN_NAME_API}/contacts?options=WHERE clientID=${props.clientID}`)
      setContacts(response.data.contacts)
    }
    fetchData();
  },[props.clientID]);

  return <div className="col-md-12 mb-5">
      <h3>Контакты</h3>
      <div className="row">
        {
          contacts.map((item) => {
            let email = item.emailID ? <><i className="fa fa-envelope fa-fw"></i>&nbsp;{item.email.address}</> : '';
            let phone = item.phoneID ? <><i className="fa fa-phone fa-fw"></i>&nbsp;{formatPhoneNeo(item.phone.number)}</> : '';

            let description = '';

            if (item.nameRU !== '' && item.comments !== '') {
              description = `${item.nameRU} (${item.comments})`;
            } else if (item.nameRU !== '') {
              description = `${item.nameRU}`;
            } else if (item.comments !== '') {
              description = `${item.comments}`;
            }
            if (item.nameRU !== '' || item.comments !== '') {
              return <div className="col-md-12" key={item.id}><p>{email} {phone} &nbsp;&#8212;&nbsp; {description}</p></div>
            } else {
              return <div className="col-md-12" key={item.id}><p>{email} {phone}</p></div>
            }
          })
        }
        <AddContact clientID={props.clientID} pushContact={pushContact} />
      </div>
    </div>
}

export default ClientContacts

// <div className="col-md-12">
//   <p>{formatPhoneNeo('375333583388')}</p>
//   <p>{formatPhoneNeo('79142532875')}</p>
// </div>

// await props.pushContact({
//   "id": 13,
//   "clientID": 8,
//   "phoneID": null,
//   "emailID": 1111,
//   "nameRU": "",
//   "comments": "",
//   "email": {
//     "id": 8,
//     "address": "Тестовый"
//   }
// });
