import React, { Component } from 'react';
import axios from 'axios'
import CollectionButtonDelete from './CollectionButtonDelete';
import { DOMAIN_NAME_API } from '../../Constants'

class CategorySelect extends Component {
  constructor(props) {
      super(props);
      this.state = {
          categories: [],
          activeID: '',
          display: 'none',
          value: 'Выберите категорию'
      };
      this.setWrapper = this.setWrapper.bind(this);
      this.setWrapperInput = this.setWrapperInput.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount(){
    document.addEventListener('mousedown', this.handleClickOutside);

    axios.get(DOMAIN_NAME_API + '/categories')
    .then(res => {
      var categories = res.data.categories;
      // console.log(response);
      let categorySelected = res.data.categories.filter( category => ( category.id.toString().indexOf(this.props.defaultID.toString()) !== -1 ) );
      if (this.props.defaultID){
        this.setState({
          categories: categories,
          value: categorySelected[0]['nameRU']
        })
      } else {
        this.setState({
          categories: res.data.categories
        })
      }
    });
  }
  // static getDerivedStateFromProps(props, state) {
  //   if (props.defaultID && state.categories.length !== 0 && state.activeID === '') {
  //     console.log(props, state);
  //     let categorySelected = state.categories.filter( category => ( category.id.toString().indexOf(props.defaultID.toString()) !== -1 ) );
  //     return { value: categorySelected.nameRU };
  //   }
  // }
  // selectedFromProps() {
  //   let categorySelected = this.state.categories.filter( category => ( category.id.toString().indexOf(this.props.defaultID.toString()) !== -1 ) );
  //   this.setState({
  //     value: categorySelected.nameRU
  //   })
  // }
  setWrapper(node) {
  this.wrapper = node;
  }
  setWrapperInput(node){
    this.wrapperInput = node;
  }
  handleClickOutside(event) {
  if (this.wrapperInput && this.wrapperInput.contains(event.target)){

  } else if (this.wrapper && !this.wrapper.contains(event.target)) {
    if (this.state.display === 'block') {
      this.setState({
        display: 'none'
      })
    }
  }

}
  componentWillUnmount() {
  document.removeEventListener('mousedown', this.handleClickOutside);
}
  _handleClicked = (index,category) => {
    console.log('category',index,category);
    if (category) {
      this.setState({
        display: 'none',
        activeID: index,
        value: category.nameRU,
      });
      this.props.updateData('UPDATE_CATEGORY',category);
    } else {
      this.setState({
        display: 'none',
        activeID: index,
        value: this.state.categories[index]['nameRU'],
      });
    }

  }
  _handleSelectCategory = () => {
    this.setState({
      display: 'block'
    });
  }
  updateData = (value,data) => {
    // console.log(value,data);
    switch(value) {
        case 'DELETE_CATEGORY':
          this.props.updateData('DELETE_CATEGORY');
          // this.reloadColection();
          // console.log('DELETE_CATEGORY');
          break;
      default:
        break;
    }
  }
//   _handleSearchValue =(e) => {
//     if(e.target.value === ''){
//       this.setState({
//       value: '',
//       propsValue: '',
//       activeID: '',
//       search: false
//     });
//     } else {
//       let filteredData, text = e.target.value;
//       let arraySearch = text.split(' ');
//       let dataForSearch = this.state.categories;
//       if(arraySearch.length === 1) {
//         filteredData = dataForSearch.filter( category => category.nameRU.toLowerCase().indexOf(text.toLowerCase()) !== -1 );
//       }
//       this.setState({
//           value: e.target.value,
//           propsValue: '',
//           activeID: '',
//           search: true,
//           searchResult: filteredData
//         });
//     }
// }
// _handleDownKey(){
//   if (!this.state.searchResult) {
//     return;
//   } else if (this.state.activeID !== this.state.searchResult.length-1) {
//     this.setState({
//       activeID: this.state.activeID+1
//     });
//   }
// }
// _handleUpKey(){
//   if (!this.state.searchResult) {
//     return;
//   } else if (this.state.activeID !== 0) {
//     this.setState({
//       activeID: this.state.activeID-1
//     });
//   }
// }
// _handleEscapeKey(){
//   if (!this.state.searchResult) {
//     return;
//   }
//   this.setState({
//     value:'',
//     searchResult: [],
//     display: 'none',
//     activeID: '',
//     propsValue: '',
//     search: false
//   });
// }
// _handleInputKeyDown(event){
//   switch (event.key) {
//     // case 'Enter':
//     //   event.preventDefault();
//     //   console.log(this.state.categories[this.state.activeID]);
//     //   this._handleClicked(this.state.activeID,this.state.categories[this.state.activeID]);
//     //   break;
//     // case 'ArrowDown':
//     //   event.preventDefault(); // prevent the cursor from moving
//     //   this._handleDownKey();
//     //   break;
//     // case 'ArrowUp':
//     //   event.preventDefault(); // prevent the cursor from moving
//     //   this._handleUpKey();
//     //   break;
//     case 'Escape':
//       event.preventDefault();
//       this._handleEscapeKey();
//       break;
//     default:
//       break;
//   }
// }
  render() {
    let items = this.state.categories.map((category, index) => {
          return (
            <div className={this.state.activeID === index ? "autocomplete-item active" : "autocomplete-item"} key={index}  onClick={this._handleClicked.bind(this,index,category)} ><div><strong>{category.nameRU}</strong></div></div>
          )
        }
      );
      // let itemsSearch = this.state.searchResult.map((category, index) => {
      //       return (
      //         <div className={this.state.activeID === index ? "autocomplete-item active" : "autocomplete-item"} key={index}  onClick={this._handleClicked.bind(this,index,category)} ><div><strong>{category.nameRU}</strong></div></div>
      //       )
      //     }
      //   );
    const interfaceLocale = this.props.locale;
    // let inputTemplate = <input type="text" className="input-category" placeholder="Выберете категорию" value={this.state.value} onClick={this._handleSelectCategory.bind(this)} onChange={this._handleSearchValue.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} ref={this.setWrapperInput} />;
    // let inputTemplate = <input type="text" className="input-category" placeholder="Выберете категорию" defaultValue="Жаккард" readOnly/>;

      // if (this.props.defaultID && this.state.categories.length !== 0 && this.state.activeID === '') {
      //   let categorySelected = this.state.categories.filter( category => ( category.id.toString().indexOf(this.props.defaultID.toString()) !== -1 ) );
      //   // inputTemplate = <input type="text" className="input-category" placeholder="Выберете категорию" defaultValue={categorySelected.nameRU} />;
      //   this._handleClicked(0,categorySelected);
      // }
    return (
      <div className="row">
        <div className="col-12 mb-4">
          <button className="btn btn-secondary form-button " onClick={this._handleSelectCategory.bind(this)}>{this.state.value}</button>
          { this.props.match.path !== '/collections/new' ? <CollectionButtonDelete locale={interfaceLocale} updateData={this.updateData} /> : ''}
          <div className="autocomplete-container" style={{ display: this.state.display}} ref={this.setWrapper}>
            { items }
          </div>
        </div>
      </div>
    )
    }
}

export default CategorySelect;
// <input type="text" className="input-category" placeholder="Выберете категорию" defaultValue={this.state.propsValue} onClick={this._handleSelectCategory.bind(this)} onChange={this._handleSearchValue.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} ref={this.setWrapperInput} />


// <input type="text" className="input-category" placeholder="Выберете категорию" defaultValue={this.state.propsValue} value={this.state.value} onClick={this._handleSelectCategory.bind(this)} onChange={this._handleSearchValue.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} ref={this.setWrapperInput} />


// <input type="text" className="form-control input-place" placeholder="Название места или адрес" value={this.state.searchValue} onChange={this._handleSearchValue.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)}/>


// <div className={this.state.activeID === index ? "autocomplete-item active" : "autocomplete-item"} key={index} onClick={this._handleClicked.bind(this,index)} ><div><strong>{place.name}</strong><br /><small>{place.address.formattedAddress}</small></div></div>
