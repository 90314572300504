import React, { useState, useEffect } from 'react'
import Image from '../Image'
import RollItem from './RollItem'
// import HandlingContext from '../../context/HandlingContext'
import { declOfNum, formatFloat } from '../../Functions'
// import moment from 'moment'
// eslint-disable-next-line
// import ru from 'moment/locale/ru'

function HandlingBlock(props) {
  // const {state, dispatch} = useContext(HandlingContext)
  // console.log(state)
  const [totalBalance, setTotalBalance] = useState(0)
  const [isVisible, setVisible] = useState(false)
  const handleVisivle = () => {
    setVisible(isVisible ? false : true)
  }

  useEffect(() => {
    for (let fabric of props.item.stock) {
      console.log(fabric)
      setTotalBalance(prev => prev + fabric.balance)
    }
  },[props.item.stock])

  // let date = moment.unix(props.item.created)
  // moment.locale('ru')

  return (
    <>
      <div className="col-md-12 handling-block">
        <div className="handling-item-image mr-4">
          <Image url={props.item.description.image} height={75} customClass="ml-3" />
        </div>
        <div className="handling-item-title mr-4">
          <h5>{props.item.collection.nameEN} {props.item.description.nameEN} &nbsp;&#8212;&nbsp; {formatFloat(props.item.length/100) + ' ' + declOfNum(formatFloat(props.item.length/100)*10, ['метр', 'метра', 'метров'])}</h5>
        </div>
        <div className="handling-item-rolls-sum">
          {
            isVisible ?
            <>
              <p onClick={handleVisivle}>{"Доступно на складе: " + formatFloat(totalBalance/100) + ' ' + declOfNum(formatFloat(totalBalance/100)*10, ['метр', 'метра', 'метров'])}</p>
            </>
            : (
              totalBalance !== 0 ?
              <p onClick={handleVisivle}>{"Доступно на складе: " + formatFloat(totalBalance/100) + ' ' + declOfNum(formatFloat(totalBalance/100)*10, ['метр', 'метра', 'метров'])} {`(${+props.item.stock.length})`}</p>
              : <p>Нет на складе</p>
            )
          }
        </div>
        <div className="handling-item-message">
        </div>
      </div>
      {
        isVisible ?
        <div className="col-md-12">
          <div className="row">
            {
              props.item.stock.map((rollItem,index)=>{
                rollItem.requestedLength = props.item.length;
                return <RollItem key={rollItem.id} arrayIndex={index} roll={rollItem} />
              })
            }
          </div>
        </div>
        : ''
      }
    </>
  )
}

export default HandlingBlock
