import React, { Component } from 'react';
import axios from 'axios'
import { DOMAIN_NAME_API } from '../../Constants'

class AddClient extends Component {
  constructor(props) {
      super(props);
      this.state = {
          nameRU: '',
          alias: '',
          taxNumber: '',
          providerStatus: 0,
          'language': window.localStorage.getItem('lang') || 'ru'
      };
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
  _handleForm = (event,field) => {
    let newState = {}
    newState[field] = event.target.value;
    this.setState(newState);
  }
  _handleCheckboxProviderStatus = event => {
    let status = (this.state.providerStatus === 0 ? 1 : 0);
    this.setState( { providerStatus: status } );
  }
_handleClick = async() =>{
  // let nameRUcheck = await axios.get(encodeURI(`${DOMAIN_NAME_API}/clients?options=WHERE nameRU=${this.state.nameRU}`))
  let taxNumberCheck = await axios.get(`${DOMAIN_NAME_API}/clients?options=WHERE taxNumber=${this.state.taxNumber}`)

  // console.log('Проверка клиента', nameRUcheck, taxNumberCheck);

  // nameRUcheck.data.clients.length === 0 && taxNumberCheck.data.clients.length === 0
  if (taxNumberCheck.data.clients.length === 0) {
    console.log('Не найдено');

    axios.post(`${DOMAIN_NAME_API}/clients`,
      {
        nameRU: this.state.nameRU,
        alias: this.state.alias,
        taxNumber: this.state.taxNumber,
        providerStatus: this.state.providerStatus
      })
    .then((res)=> {
        this.props.history.push(`/clients/${res.data.id}`)
      })
    .catch((error)=>{
      console.log(error);
    })
  }
}
render() {
  const interfaceLocale = this.props.locale;
  return (
    <div className="row">
      <div className="col-md-6 mb-2 ml-2">
        <input type="text" className={"input-main-collection-page mb-2"} placeholder={interfaceLocale['addClient']['nameRU']} value={this.state.nameRU} onChange={ (e) => {this._handleForm(e,'nameRU')} } required />
        <input type="text" className={"input-main-collection-page mb-2"} placeholder={interfaceLocale['addClient']['alias']} value={this.state.alias} onChange={ (e) => {this._handleForm(e,'alias')} } required />
        <input type="text" className={"input-main-collection-page mb-2"} placeholder={interfaceLocale['addClient']['taxNumber']} value={this.state.taxNumber} onChange={ (e) => {this._handleForm(e,'taxNumber')} } maxLength={9} required />
        <p className="text-muted">Если юр. лицо является не резидентом Республики Беларусь в поле УНП укажите все нули</p>
      </div>

      <div className="col-md-3 mb-2">
        <div className="row">
          <label className="col-md-8 col-form-label">Поставщик</label>
          <div className="col-md-4 col-form-label">
            <label className="custom-control material-switch">
              <input type="checkbox" className="material-switch-control-input" defaultChecked={this.state.providerStatus} onChange={this._handleCheckboxProviderStatus} />
              <span className="material-switch-control-indicator" />
            </label>
          </div>
        </div>
      </div>

      <div className="col-md-12 mb-4 ml-2">
        {
          this.state.nameRU !== '' &&
          this.state.taxNumber !== '' &&
          this.state.taxNumber.length === 9 ?
          <button type="submit" className="btn btn-secondary " onClick={this._handleClick.bind(this)}>{interfaceLocale['addClient']['button']}</button>
          : ''
        }
      </div>
    </div>
  )
  }
}

export default AddClient;

// <button value={this.state.permission} data-toggle="dropdown" className="btn btn-secondary dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>{this.state.permission === 0 ? localeSelectPermissons.selectButton[lang] : localeSelectPermissons.permissionsList[this.state.permission][lang]} <span className="caret" /></button>
