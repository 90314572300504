import React, { Component } from 'react';
import Layout from '../Layout';
// import AddClient from './AddClient';
// import ListItem from './ListItem';
import {Helmet} from "react-helmet";
// import { Link } from 'react-router-dom';
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'
import ClientContacts from './ClientContacts'
import ClientStores from './ClientStores'
import Stores from '../stores/Stores'

class ClientPage extends Component {
  constructor(props) {
      super(props);
      this.state = {
        client: null,
        loaded: false
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
    axios.get(`${DOMAIN_NAME_API}/clients/${this.props.match.params.id}`)
    .then(res => {
      var client = res.data;
      this.setState({
        client: client,
        loaded: true
      })
    });
  }
  // updateData = (value) => {
  //   switch(value) {
  //     case 'ADD_CLIENT':
  //       this.reloadClients();
  //       console.log('ADD_CLIENT');
  //       break;
  //     case 'UPDATE_CLIENT':
  //       this.reloadClients();
  //       console.log('UPDATE_CLIENT');
  //       break;
  //       case 'DELETE_CLIENT':
  //         this.reloadClients();
  //         console.log('DELETE_CLIENT');
  //         break;
  //     default:
  //       break;
  //   }
  // }
  render() {
    // const selectedLanguage = localStorage.getItem('lang');
    // const interfaceLocale = locale[selectedLanguage];
    let name = '';
    if (this.state.loaded) {
      name = this.state.client.nameRU;
      if (this.state.client.alias !== '') {
        name = `${this.state.client.alias} (${this.state.client.nameRU})`;
      }
    }
    let helmetTitle = `Клиент ${name} - CRM Фабрика Фурнитуры`;
    return (
      <Layout match={this.props.match} history={this.props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12 mb-5">
          {
            this.state.loaded ?
            <>
              <h2>{name}</h2>
              {
                this.state.client.taxNumber ?
                <h5 style={{color: '#6c757d'}}>УНП: {this.state.client.taxNumber}</h5>
                : <h5 style={{color: '#6c757d'}}>Не резидент Республики Беларусь</h5>
              }
            </> :
            <><h3>&nbsp;</h3><h5>&nbsp;</h5></>
          }
        </div>
        <ClientContacts clientID={this.props.match.params.id} />
        <ClientStores clientID={this.props.match.params.id} />
        <Stores clientID={this.props.match.params.id} />
      </Layout>
    );
  }
}

export default ClientPage;
