import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { DOMAIN_NAME_API } from '../../Constants'
import ImageStandard from '../../common/ImageStandard'
import PriceListContext from '../../context/PriceListContext'
import moment from 'moment'
// eslint-disable-next-line
import ru from 'moment/locale/ru'

const FabricItemNeo = ({ item, lastItem}) => {
  const { state, dispatch } = useContext(PriceListContext)
  const [form, setForm] = useState({ price: '' })
  const [historyVisible, setHistoryVisible] = useState(false)
  const [historyPrices, setHistoryPrices] = useState([])

  useEffect(() => {
    let newPrice = ''
    if (item.prices.length !== 0) {
      let pricesArray = item.prices.filter( priceItem => (priceItem.fabricID === item.id && priceItem.priceListID === state.selectedPriceListID))

      if (pricesArray.length !== 0) {
        pricesArray.sort((a, b)=>{
          return b.created-a.created
        })
        setHistoryPrices(pricesArray)
        console.log('pricesArray',pricesArray)

        newPrice = (pricesArray[0]['price']['worth']/100).toString()
      }
    }

    let fakeEvent = {
      target: {
        value: newPrice.toString(),
        name: 'price'
      }
    }

    changeHandler(fakeEvent)
    return function cleanUp(){
      setHistoryPrices([])
    }
  },[item,state.selectedPriceListID,state.priceLists])

  const changeHandler = (event) => {
    // eslint-disable-next-line
    let prepareStr = event.target.value.replace(/[^.\d]+/g,"").replace(/^([^\.]*\.)|\./g, '$1' )
    let arrFields = prepareStr.split('.');

    // I tried to victory the regexp to format number 10.00 or 100.00
    // Unfortunately, I am using a crutch
    if (arrFields[1]) {
      if (arrFields[1] !== '' && arrFields[1].length <= 2) {
        setForm({ ...form, [event.target.name]: prepareStr })
      }
    } else {
      setForm({ ...form, [event.target.name]: prepareStr })
    }
  }

  const handleClicked = (item) => {
    async function postData(object){
      let config = { headers: { 'content-type': 'application/json', 'token': localStorage.getItem('token') }}

      const response = await axios.post(DOMAIN_NAME_API + '/priceLists/addItem', {
        priceListID: state.priceLists[state.selectedPriceList]['id'],
        fabricID: item.id,
        worth: +form.price*100,
        // worth: +price*100,
        clientID: null,
      }, config);
      // console.log(response.data)

      // add new price in prices
      object.prices.push(response.data)

      // add new price to global state
      // dispatch({
      //   type: "ADD_FABRIC_PRICE",
      //   payload: object
      // })
    }

    // не обновлять цену и историю, если поле не изменилось
    let initialWorth = ( historyPrices.length > 0 && (historyPrices[0].price.worth/100).toString() ) || ""
    // console.log('initialWorth: ',initialWorth)
    if (initialWorth !== form.price) {
      postData(item)
      // console.log(item,form.price)
    }
    // console.log(item,+inputEl.current.value*100)
  }

  return (
    <>
      <div className="priceList-line">
        <div className="priceList-line__left">
          <ImageStandard url={item.image} height={50} customClass="rounded-12 mr-3"/>
          <span>{item.nameEN}</span>
        </div>
        <div className="priceList-line__right">
          <div className="input-wrapper">
            <input
              type="text"
              className="rounded-12 mr-2"
              name="price"
              value={form.price}
              onChange={changeHandler}
            />
          </div>
          <div className="controls">
            <button className="btn btn-secondary btn-control rounded-12 mr-2" onClick={(e)=>{ handleClicked(item) }}><i className="fas fa-check" /></button>
            <button className="btn btn-secondary btn-control rounded-12" onClick={(e)=>{ setHistoryVisible(historyVisible ? false : true) }}><i className="fas fa-history"></i></button>
          </div>
        </div>
      </div>
      {
        historyVisible ?
        <div className="priceList-line">
          <div className="priceList-line__left"></div>
          <div className="priceList-line__right">
            <ul className="history-prices-list">
            {
              historyPrices.map((historyItem) => {
                historyItem.date = moment.unix(historyItem.created)
                moment.locale('ru')
                return (
                  <li key={historyItem.id} className="mb-2">
                    <span className="price">
                      <i className="fas fa-dollar-sign"></i>
                      &nbsp;
                      {(historyItem.price.worth/100).toFixed(2)}
                    </span>
                    <span className="time">
                      <i className="fa fa-clock-o"></i>&nbsp;&nbsp;{historyItem.date.format('HH:mm')}
                    </span>
                    <span className="date">
                      <i className="fa fa-calendar"></i>&nbsp;&nbsp;{historyItem.date.format('DD MMMM YYYY')}
                    </span>
                  </li>
                )
              })
            }
            </ul>
          </div>
        </div>
        : null
      }
      {
        lastItem ?
        <div style={{ width: 100+'%', padding: .5+'rem'}}></div>
        : null
      }
    </>
  )
}

const CollectionItemNeo = ({ collection, rowID }) => {
  const { state, dispatch } = useContext(PriceListContext)
  const [form, setForm] = useState({ price: '' })
  const [fabricsVisible, setFabricsVisible] = useState(false)
  const [historyPrices, setHistoryPrices] = useState([])

  useEffect(() => {
    let newPrice = ''
    if (collection.prices.length !== 0) {
      let pricesArray = collection.prices.filter( priceItem => (priceItem.collectionID === collection.id && priceItem.priceListID === state.selectedPriceListID))

      if (pricesArray.length !== 0) {
        pricesArray.sort((a, b)=>{
          return b.created-a.created
        })
        setHistoryPrices(pricesArray)

        newPrice = (pricesArray[0]['price']['worth']/100).toString()
      }
    }

    let fakeEvent = {
      target: {
        value: newPrice.toString(),
        name: 'price'
      }
    }

    changeHandler(fakeEvent)
    return function cleanUp(){
      setHistoryPrices([])
    }
  },[collection,state.selectedPriceListID,state.priceLists])

  const changeHandler = (event) => {
    // eslint-disable-next-line
    let prepareStr = event.target.value.replace(/[^.\d]+/g,"").replace(/^([^\.]*\.)|\./g, '$1' )
    let arrFields = prepareStr.split('.');

    // I tried to victory the regexp to format number 10.00 or 100.00
    // Unfortunately, I am using a crutch
    if (arrFields[1]) {
      if (arrFields[1] !== '' && arrFields[1].length <= 2) {
        setForm({ ...form, [event.target.name]: prepareStr })
      }
    } else {
      setForm({ ...form, [event.target.name]: prepareStr })
    }
  }
  
  const handleClicked = (item) => {
    async function postData(){
      let config = { headers: { 'content-type': 'application/json', 'token': localStorage.getItem('token') }}

      const response = await axios.post(DOMAIN_NAME_API + '/priceLists/addCollectionItem', {
        priceListID: state.priceLists[state.selectedPriceList]['id'],
        collectionID: item.id,
        worth: +form.price*100,
        // worth: +price*100,
        clientID: null,
      }, config);
      console.log(response)

      // update historyPrices with new price
      let resPrice = response.data 
      setHistoryPrices([resPrice, ...historyPrices])

      // add new price to global state
      dispatch({
        type: "ADD_COLLECTION_PRICE",
        payload: {
          rowID,
          resPrice
        }
      })
    }

    // не обновлять цену и историю, если поле не изменилось
    let initialWorth = ( historyPrices.length > 0 && (historyPrices[0].price.worth/100).toString() ) || ""
    if (initialWorth !== form.price) {
      postData()
      console.log(item,form.price)
    }
  }

  return (
    <>
      <div className="priceList-line">
        <div className="priceList-line__left">
          <ImageStandard url={collection.image} height={50} customClass="rounded-12 mr-3"/>
          <span>{collection.nameEN}</span>
        </div>
        <div className="priceList-line__right">
          <div className="input-wrapper">
            <input
              type="text"
              className="rounded-12 mr-2"
              name="price"
              value={form.price}
              onChange={changeHandler}
            />
          </div>
          <div className="controls">
            <button className="btn btn-secondary btn-control rounded-12 mr-2" onClick={(e)=>{ handleClicked(collection) }}><i className="fas fa-check-double" /></button>
            <button className="btn btn-secondary btn-control rounded-12" onClick={(e)=>{ setFabricsVisible(fabricsVisible ? false : true) }}><i className={`fas fa-chevron-${fabricsVisible ? 'up' : 'down'}`}></i></button>
          </div>
        </div>
      </div>
      {
        fabricsVisible ?
        collection.fabricsWithPrices.map( (fabric,index) => {
          let lastItem = false
          if (collection.fabricsWithPrices.length-1 === index) {
            lastItem = true
          }
          return <FabricItemNeo key={fabric.id} collection={collection} item={fabric} lastItem={lastItem} />
        })
        : null
      }
    </>
  )
}

const SearchFabrics = ({ items, updateData }) => {
  const { state, dispatch } = useContext(PriceListContext)
  const [searchValue, updateSearchValue] = useState('')
  
  const changeHandler = (event) => {
    updateSearchValue(event.target.value)
  }

  const resetSearch = (e) => {
    updateSearchValue('')
  }

  const handleSelectPriceList = (object) => {
    dispatch({ type: 'SELECT_PRICELIST', payload:object })
  }

  useEffect(() => {
    let value = searchValue
    let filteredData = [],
        arraySearch = value.split(' '),
        dataForSearch = items;

    if (value !== '') {
        switch (arraySearch.length) {
          case 1:
            filteredData = dataForSearch.filter(
              item => (
                item.nameEN.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                item.nameRU.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                item.id.toString().indexOf(value.toString()) !== -1
              )
            )
            break;
          case 2:
          filteredData = dataForSearch.filter(
             item =>
                 (item.nameEN.toLowerCase().indexOf(arraySearch[0].toLowerCase()) !== -1 &&
                 item.nameEN.toLowerCase().indexOf(arraySearch[1].toLowerCase()) !== -1) 
                 ||
                 (item.nameRU.toLowerCase().indexOf(arraySearch[0].toLowerCase()) !== -1 &&
                 item.nameRU.toLowerCase().indexOf(arraySearch[1].toLowerCase()) !== -1)
               );
            break;
          default:
            break;
        }
        updateData("SEARCH_FABRICS",{ searchValue: value, sortedData: filteredData })
    } else {
      updateData("SEARCH_FABRICS",{ searchValue: '', sortedData: [] })
    }

  },[state.selectedPriceList,searchValue,items,updateData()])

  return (
    <div className="col-md-12 mb-4">
      <div className="input-group input-group-priceList">
        {
          searchValue === '' ?
          ( <span className="input-group-addon input-main-icon darkgray"><i className="fa fa-search" aria-hidden="true"></i></span> ) :
          (<span className="input-group-addon input-main-icon" onClick={resetSearch} style={{cursor: 'pointer'}}><i className="fa fa-times" aria-hidden="true"></i></span>)
        }
        <input autoFocus
          type="text"
          className="input-main"
          placeholder="Поиск ткани"
          name="length"
          value={searchValue}
          onChange={changeHandler}
        />
        <ul className="priceList-list">
          {
            state.priceLists.map((priceListItem, index) => {
              return (
                <li key={priceListItem.id} className={index === state.selectedPriceList ? "priceList-list-item active" : 'priceList-list-item'} onClick={(e)=> {handleSelectPriceList({ index, id: priceListItem.id })}}>
                  <div className="category-item-name">
                    {index === 0 || index === 1 ? priceListItem.nameRU.replace('Основной','Осн.') : priceListItem.nameRU}
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </div>
  )
}

function SelectPriceListItems(props){
  const { state, dispatch } = useContext(PriceListContext)
  // const [collections, updateCollections] = useState([])
  const [searchStatus, setSearchStatus] = useState(false)
  const [collectionsSorted, updateCollectionsSorted] = useState([])
  const [itemsLimit, setItemsLimit] = useState(16)

  console.log('collections:',state.collections)
  useEffect(() => {
    async function fetchData() {
      // Вариант с тканями отдельно.
      const collectionsWithFabricsWithPrices = await axios.get(`${DOMAIN_NAME_API}/collectionsWithFabricsWithPrices`)
      // console.log(collectionsWithFabricsWithPrices.data.collections)
      // updateCollections(collectionsWithFabricsWithPrices.data.collections)
      dispatch({
        type: 'INITIAL_COLLECTIONS',
        payload: collectionsWithFabricsWithPrices.data.collections
      })
    }
    fetchData()
  },[])


  const isBottom = (el) => {
    return el.getBoundingClientRect().bottom-100 <= window.innerHeight
  }

  useEffect( () => {
    const wrappedElement = document.getElementById('root');

    function handleScroll() {
      if (isBottom(wrappedElement)) {
        setItemsLimit(itemsLimit+8)
      }
    }
    // subscribe event
    window.addEventListener("scroll", handleScroll)
    return () => {
      // unsubscribe event
      window.removeEventListener("scroll", handleScroll)
    }
  }, [itemsLimit])

  const updateData = (value,data) => {
    switch(value) {
      case 'SEARCH_FABRICS':
        if(data.searchValue !== ''){
          setSearchStatus(true)
          updateCollectionsSorted(data.sortedData)
          setItemsLimit(16)
        } else {
          setSearchStatus(false)
          updateCollectionsSorted([])
          setItemsLimit(16)
        }
        break;
      default:
        break;
    }
  }

  let collectionsItems = null,
      collectionsArray = state.collections

    searchStatus ? collectionsArray = collectionsSorted : collectionsArray = state.collections

    collectionsItems = collectionsArray.slice(0,itemsLimit).map((item,index)=>{
      return <CollectionItemNeo key={item.id} rowID={index} collection={item} />
    })

  return <div className={props.customClass ? `col-md-12 ${props.customClass}` : "col-md-12"}>
    <div className="row">
      <SearchFabrics updateData={updateData} items={state.collections} searchStatus={searchStatus}  />
      <div className="col-md-12 priceList-lines-wrapper">
        { collectionsItems ? collectionsItems : null }
      </div>
    </div>
  </div>
}

export default SelectPriceListItems
