import React from 'react';

export default function QR(props) {
  // console.log(props.match.params.categoryName);
  return (
    <div className="text-center"><img width='500' height="500" src={`https://storage.ffm.by/qr?url=http://catalog2.ffm.by/collections/${props.match.params.categoryName}/${props.match.params.collectionName}`} alt=""  /></div>
  );
};

 // QR;
