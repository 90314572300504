import React, { Component } from 'react';
import axios from 'axios'
import { DOMAIN_NAME_API } from '../../Constants'
import SelectClient from './SelectClient'
import RequestedFabrics from './RequestedFabrics'
import SelectFabrics from './SelectFabrics'
import update from 'immutability-helper';

class AddRequest extends Component {
  constructor(props) {
      super(props);
      this.state = {
        client: null,
        comments: "",
        // requestID: null,
        requestedFabrics: [],
        // nameRU: '',
        // nameEN: '',
        // form1Class: '',
        // form2Class: '',
        'language': window.localStorage.getItem('lang') || 'ru'
      };
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
  _handleFormNeo = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value})
  }
  addClient(client){
    this.setState({
      client: client
    })
  }
  async _handleClick(){
    // console.log('Оформить заявку',this.state.client,this.state.requestedFabrics);
    const response = await axios.post(DOMAIN_NAME_API + '/requests',
      {
        clientID: this.state.client.id,
        comments: this.state.comments,
        status: 1
      });

    for (let fabric of this.state.requestedFabrics) {
      await axios.post(DOMAIN_NAME_API + '/requestedFabrics',
      {
        requestID: response.data.id,
        fabricID: fabric.id,
        comments: "",
        length: +fabric.requestedLength*100
      })
        .catch((error)=>console.log(error));
    }

    window.location = '/requests';
  }
  updateData = (value,data) => {
    if(data){
      let requestedFabrics;
      switch(value) {
        case 'ADD_CLIENT':
          this.addClient(data);
          // console.log('ADD_CLIENT');
          break;
        case 'CHANGE_FABRIC_VALUE':
          // this.addClient(data);
          // console.log('CHANGE_FABRIC_VALUE',data);
          requestedFabrics = this.state.requestedFabrics;
          requestedFabrics[data.rowID]['requestedLength'] = data.requestedLength;
          this.setState({
            requestedFabrics: requestedFabrics
          })
          break;
        case 'ADD_FABRIC':
          requestedFabrics = this.state.requestedFabrics;
          requestedFabrics.push(data);
          this.setState({
            requestedFabrics: requestedFabrics
          })
          break;
        case 'DELETE_FABRIC':
          // console.log('DELETE_FABRIC', data, this.state.requestedFabrics);
          this.setState((prevState) => ({
            requestedFabrics: update(prevState.requestedFabrics, { $splice: [[data.rowID, 1]] })
          }));
          break;
        default:
          break;
      }
    }
  }
render() {
  // let lang = this.state.language || 'ru';
  return (
    <div className="row">
      <div className="col-md-12 mb-3 ml-2">
        <div className="row">
          <SelectClient updateData={this.updateData} items={this.props.clients}  placeholder="Выбрать клиента" />
          {
            this.state.client && this.state.requestedFabrics.length > 0 ?
            <div className="col-md-6" style={{textAlign: 'right'}}>
              <button className="btn btn-secondary mr-2" onClick={this._handleClick.bind(this)}>Оформить</button>
            </div>
            : ''
          }
        </div>
      </div>
      {
        this.state.client ?
        <>
          <SelectFabrics updateData={this.updateData} />
          <RequestedFabrics updateData={this.updateData} items={this.state.requestedFabrics} />
        </>
        : ''
      }
    </div>
  )
  }
}

export default AddRequest;

// <div className="col-md-12 mb-4 ml-2">
//   <input
//     type="text"
//     name="nameRU"
//     className={"input-main-collection-page "+ this.state.form1Class}
//     placeholder="nameRU"
//     value={this.state.nameRU}
//     onChange={this._handleFormNeo.bind(this)}
//     required
//   />
//   <input
//     type="text"
//     name="nameEN"
//     className={"input-main-collection-page "+ this.state.form2Class}
//     placeholder="nameEN"
//     value={this.state.nameEN}
//     onChange={this._handleFormNeo.bind(this)}
//     required
//   />
// </div>

// <button type="submit" className="btn btn-secondary " onClick={this._handleClick.bind(this)}>Кнопка</button>
