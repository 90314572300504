import React, { Component } from 'react';
// import Image from '../Image'
import RequestedFabricItem from './RequestedFabricItem'

class RequestedFabrics extends Component {
  constructor(props) {
      super(props);
      this.state = {
        requestedLength: 0,
        marginTop: 0,
        lengthFromTop: 0
      }
  }
  componentDidMount(){
    const requestedFabricsBlock = document.getElementById('RequestedFabricsBlock');
    // Это мы получили кол-во пикселей от верха экрана до нашего элемента
    // console.log(requestedFabricsBlock.getBoundingClientRect().y);
    this.setState({
      lengthFromTop: requestedFabricsBlock.getBoundingClientRect().y
    })
    document.addEventListener('scroll', this.trackScrolling)
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }
  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }
  trackScrolling = () => {
    const root = document.getElementById('root');
    let scrollTop = -1*(root.getBoundingClientRect().top);
    if (scrollTop > this.state.lengthFromTop) {
      this.setState({
        marginTop: Math.round(scrollTop-this.state.lengthFromTop)+32
      })
    } else {
      this.setState({
        marginTop: 0
      })
    }

    // if (this.isBottom(wrappedElement)) {
    //   this.setState({
    //     itemsLimit: this.state.itemsLimit + 8
    //   })
    // }
  };
  _handleForm1 = (event) => {
    // eslint-disable-next-line
    let requestedLength = event.target.value.replace(/[^.\d]+/g,"").replace( /^([^\.]*\.)|\./g, '$1' );
    this.setState({ requestedLength: requestedLength });
  }
  updateData = (value,data) => {
    if(data){
      switch(value) {
        case 'EDIT_VALUE_FABRIC':
          // this.addClient(data);
          // console.log('EDIT_VALUE_FABRIC',data);
          this.props.updateData("CHANGE_FABRIC_VALUE",data);
          break;
        case 'REMOVE_FABRIC':
          // this.addClient(data);
          // console.log('EDIT_VALUE_FABRIC',data);
          this.props.updateData("DELETE_FABRIC",data);
          break;
        default:
          break;
      }
    }
  }
  render() {
    let items = this.props.items;
    return (
      <div className={this.props.customClass ? "col-md-8 " + this.props.customClass : "col-md-8"} id="RequestedFabricsBlock">
        <div className="row" style={{ marginTop: this.state.marginTop+'px' }}>
          {
            items.map( (item,index)=>{
              return <RequestedFabricItem updateData={this.updateData} item={item} key={index} rowID={index} />
            })
          }
        </div>
      </div>

    );
  }
}

export default RequestedFabrics;
