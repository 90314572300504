import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Image from '../Image'
// import { CDN_SERVER } from '../../Constants'
// import svgPlaceholder from '../../svg-placeholder.svg';

class CollectionItem extends Component {
  // eslint-disable-next-line
  constructor(props) {
      super(props);
      // this.state = {
      //   form1: this.props.category.nameRU || ''
      // };
  }
render() {
  // const interfaceLocale = this.props.locale;
  let collection = this.props.collection;
  return (
    <div className="col-md-3">
      <div className="collection-item">
        <Link to={"/collections/"+collection.id}>
        <Image url={collection.image} height={375} />
          <div className="collection-img-overlay">
            <h5 className="collection-title">{collection.nameEN}</h5>
          </div>
        </Link>
      </div>
    </div>
  );
  }
}

export default CollectionItem;

// <picture className="intrinsic intrinsic--4x3 rounded">
//   {
//     collection.image ?
//     <>
//       <source srcset={CDN_SERVER + webpSource + '?h=375'} type="image/webp" />
//       <source srcset={CDN_SERVER + jpgSource + '?h=375'} type="image/jpeg" />
//       <img className="intrinsic-item rounded" src={CDN_SERVER + jpgSource + '?h=375'} alt="Collection" />
//     </>
//     : ''
//   }
// </picture>
// <img src={collection.image ? CDN_SERVER + collection.image + '?w=500&h=375' : svgPlaceholder} className="img-fluid rounded" alt="" />
// <div className="col-md-3 collection-item">
//   <img src={Generals.IMAGE_SERVER + collection.image} className="img-fluid" />
// </div>
