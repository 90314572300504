import React, { useContext } from 'react'
import HandlingContext from '../../context/HandlingContext'

function LogoutButton( props ){
  const { dispatch } = useContext(HandlingContext)
  const clickHandler = async () => {
    dispatch({
      type: 'LOG_OUT',
      payload: {}
    })
  }
  return <button className="dropdown-item" onClick={clickHandler}><em className="fa fa-power-off mr-1" /> {'Выйти из отгрузки'}</button>
}

export default LogoutButton
