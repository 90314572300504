import React, { Component } from 'react';
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'


class FabricButtonDelete extends Component {
  constructor(props) {
      super(props);
      this.state = {
        clickToConfirm: false
      }
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
  _handleButton = (e,type) => {
    if (type === 'buttonDelete'){
      this.setState({clickToConfirm: true})
    } else if (type === 'confirmDelete') {
      this.deleteFabric();
    }
  }
  deleteFabric(){
    const url = DOMAIN_NAME_API + '/fabrics/'+this.props.fabricID;
      axios.delete(url)
      .then(
        res=>{
          this.props.updateData();
        }
      )
  }
  render() {
    const interfaceLocale = this.props.locale;
    return this.state.clickToConfirm ?
    <button type="submit" className="btn btn-outline-secondary form-button mb-4" onClick={(e)=>{ this._handleButton(e,"confirmDelete") }}>{interfaceLocale['fabric']['buttonConfirm']}</button> :
    <button type="submit" className="btn btn-outline-secondary form-button mb-4" onClick={(e)=>{ this._handleButton(e,"buttonDelete") }}>{interfaceLocale['fabric']['buttonDelete']}</button>
  }
}

export default FabricButtonDelete;
