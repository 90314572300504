import React, { Component } from 'react';
// import { DOMAIN_NAME_API } from '../../Constants'
// import { declOfNum, formatFloat } from '../../Functions'
import axios from 'axios'
import Image from '../Image'
// import svgPlaceholder from '../../svg-placeholder.svg';

class FabricItem extends Component {
  constructor(props) {
      super(props);
      this.state = {
        rollsVisible: false
      }
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
  _handleClicked = (item) => {
    this.props.updateData('SELECTED_FABRIC',item);
  }
  render() {
    let item = this.props.item;
    return (
      <div className="col-md-12 mb-3">
        <div className="row">
          {this.props.preItem}
          <div className="col-md-12" onClick={this._handleClicked.bind(this,item)}>
            <div className="row">
              <div className="col-md-5">
                <div className="imageStockItemInRequestPape">
                  <Image url={item.image} height={75} customClass="ml-3" />
                </div>
              </div>
              <div className="col-md-6 pl-0">
                <p className="" style={{marginTop: 1+'rem'}}>{item.nameEN}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default FabricItem;

// { item.stock || this.state.stock ?
//   <>
//   <p className="mb-1">
//     <strong> {balance} </strong>
//     {' ' + declOfNum(balance*10, ['метр', 'метра', 'метров'])}
//   </p>
//   <div className="input-group">
//     <input type="text" className="input-main" placeholder={'Обновить остаток'} style={{fontSize: 1+'rem'}} value={this.state.newStock} onChange={this._handleForm1.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} />
//     <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.createStock()}}>Обновить</button>
//   </div>
//   </>
// : <div className="input-group">
//     <input type="text" className="input-main" placeholder={'Добавить остаток'} style={{fontSize: 1+'rem'}} value={this.state.newStock} onChange={this._handleForm1.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} />
//     <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.createStock()}}>Добавить</button>
//   </div>
// }

// <img className="rounded ml-3" src={item.image ? CDN_SERVER + item.image + '?w=120&h=90' : svgPlaceholder} width='100' height='auto' alt=""/>


// <div className="input-group">
//   <input type="text" className="input-main" placeholder={'Обновить остаток'} style={{fontSize: 1+'rem'}} value={this.state.newStock} onChange={this._handleForm1.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} />
//   <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.createStock()}}>Обновить</button>
// </div>
//  <div className="input-group">
//   <input type="text" className="input-main" placeholder={'Добавить остаток'} style={{fontSize: 1+'rem'}} value={this.state.newStock} onChange={this._handleForm1.bind(this)} onKeyDown={this._handleInputKeyDown.bind(this)} />
//   <button className="btn btn-secondary btn-sm ml-1" onClick={()=>{this.createStock()}}>Добавить</button>
// </div>

// <tr>
//   <td style={{borderTop: 'none'}}><img className="rounded" src={CDN_SERVER + item.image + '?w=120&h=90'} width='100' height='auto' /></td>
//   <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}>{item.collectionID}</td>
//   <td style={{borderTop: 'none',verticalAlign:'middle',textAlign:'center'}}>{item.nameEN}</td>
//   <td style={{borderTop: 'none',verticalAlign:'middle'}}>
//     <button className="btn btn-secondary btn-sm">Кнопка</button>
//   </td>
// </tr>
