import React, { Component } from 'react';
import Layout from '../Layout';
import ListItem from './ListItem';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'
import * as localeRU from './locale_ru.json'
import * as localeEN from './locale_en.json'
const locale = {
    "ru":localeRU.default,
    "en":localeEN.default
  }

class Staff extends Component {
  constructor(props) {
      super(props);
      this.state = {
        users: [],
        loaded: false
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
    this.reloadClients();
  }
  reloadClients(){
    axios.get(`${DOMAIN_NAME_API}/users?options=WHERE id !=2 AND id !=3`)
    .then(res => {
      var users = res.data.users;
      this.setState({
        users: users,
        loaded: true
      })
    });
  }
  updateData = (value) => {
    switch(value) {
      case 'ADD_USER':
        this.reloadClients();
        console.log('ADD_USER');
        break;
      case 'UPDATE_USER':
        this.reloadClients();
        console.log('UPDATE_USER');
        break;
      case 'DELETE_CLIENT':
        this.reloadClients();
        console.log('DELETE_CLIENT');
        break;
      default:
        break;
    }
  }
  render() {
    let helmetTitle = 'Сотрудники - CRM Фабрика Фурнитуры';
    const selectedLanguage = localStorage.getItem('lang');
    const interfaceLocale = locale[selectedLanguage];
    return (
      <Layout match={this.props.match} history={this.props.history} title={<Link to={'/staff/new'} className="btn btn-secondary ml-2">{'Новый сотрудник'}</Link>}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
              {
                this.state.loaded ?
                (
                  this.state.users.map((user, index) => {
                      return (
                        <ListItem key={index} locale={interfaceLocale} updateData={this.updateData} user={user} />
                      )
                  })
                ) : ''
              }
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Staff;
