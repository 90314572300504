import React, { Component } from 'react';

class SearchStock extends Component {
  constructor(props) {
      super(props);
      this.state = {
          searchValue: ''
      };
  }
_handleSearchForm = (event) => {
  this.setState({ searchValue: event.target.value });
  // this.sortedCollections({searchValue: event.target.value});
  // this.props.updateData("SEARCH_STOCK",{ searchValue: event.target.value, sortedData: [] });
  this.sortData(event.target.value);
}
_handleClearSearchValue = (event) => {
  this.setState({ searchValue: '' });
  this.props.updateData("SEARCH_STOCK",{ searchValue: '', sortedData: [] });
}
sortData(searchValue){
  let filteredData,
      searchData = searchValue,
      arraySearch = searchData.split(' '),
      dataForSearch = this.props.items;
      if (searchData !== ''){
        if(arraySearch.length === 1) {
          filteredData = dataForSearch.filter( item => (item.collection.nameEN.toLowerCase().indexOf(searchData.toLowerCase()) !== -1 || item.collection.nameRU.toLowerCase().indexOf(searchData.toLowerCase()) !== -1 || item.id.toString().indexOf(searchData.toString()) !== -1 ) );
        } else if (arraySearch.length === 2) {
              filteredData = dataForSearch.filter(
                 item =>
                     item.nameEN.toLowerCase().indexOf(arraySearch[0].toLowerCase()) !== -1 &&
                     item.nameEN.toLowerCase().indexOf(arraySearch[1].toLowerCase()) !== -1
                   );
            }
        this.props.updateData("SEARCH_STOCK",{ searchValue: searchData, sortedData: filteredData });
      } else {
        this.props.updateData("SEARCH_STOCK",{ searchValue: '', sortedData: [] });
      }
}
render() {
  return (
    <div className="row">
      <div className="col-md-6">
      <div className="input-group">
          {
            this.state.searchValue === '' ?
            ( <span className="input-group-addon input-main-icon darkgray"><i className="fa fa-search" aria-hidden="true"></i></span> ) :
            (<span className="input-group-addon input-main-icon" onClick={this._handleClearSearchValue.bind(this)} style={{cursor: 'pointer'}}><i className="fa fa-times" aria-hidden="true"></i></span>)
          }
          <input autoFocus type="text" className="input-main" placeholder={'Поиск по имени или ID'} value={this.state.searchValue} onChange={this._handleSearchForm.bind(this)}/>
        </div>
      </div>
    </div>
    )
  }
}

export default SearchStock;

// <input type="text" className="input-main mb-4" placeholder={'Поиск по имени или ID'} value={this.state.searchValue} onChange={this._handleSearchForm.bind(this)} />
