import React, { Component } from 'react';
import axios from 'axios'
import { DOMAIN_NAME_API } from '../../Constants'
// import UniversalSelect from '../../common/UniversalSelect'
import * as localeSelect from '../../common/permissions.json'
const localeSelectPermissons = localeSelect.default;

class AddUser extends Component {
  constructor(props) {
      super(props);
      this.state = {
          login: '',
          nameRU: '',
          nameEN: '',
          pass: '',
          passRepeat: '',
          permission: 0,
          form1Class: '',
          form2Class: '',
          form3Class: '',
          form4Class: '',
          form5Class: '',
          'language': window.localStorage.getItem('lang') || 'ru'
      };
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
  _handleForm = (event,field) => {
    let newState = {}
    newState[field] = event.target.value;
    this.setState(newState);
  }
  _handleChangePermission = (event,value) => {
    this.setState({
      permission: value
    });
  }
_handleClick(){
  if (this.state.login !== '' &&
      this.state.nameRU !== '' &&
      this.state.nameEN !== '' &&
      this.state.pass !== '' &&
      this.state.passRepeat !== '' &&
      this.state.permission !== ''){
    axios.post(DOMAIN_NAME_API + '/users',
      {
        login: this.state.login,
        nameRU: this.state.nameRU,
        nameEN: this.state.nameEN,
        pass: this.state.pass,
        pinCode: 0,
        permission: this.state.permission
      })
    .then(
      // this.props.history.push('/staff')
      ()=> {window.location = '/staff'}
    )
    .catch((error)=>{
      console.log(error);
    })

  } else if (this.state.form2 === '') {
    this.setState({
      form2Class: 'animated shake fast'
    },()=>{
      setTimeout(
        () => {
          this.setState({
            form2Class: ''
          })
        },400
      )
    })
  } else if (this.state.form1 === '') {
    this.setState({
      form1Class: 'animated shake fast'
    },()=>{
      setTimeout(
        () => {
          this.setState({
            form1Class: ''
          })
        },400
      )
    })
  }
}
render() {
  const interfaceLocale = this.props.locale;
  let lang = this.state.language || 'ru';
  console.log('addUser', this.state);
  return (
    <div className="row">
      <div className="col-md-12 mb-3 ml-2">
        <input type="text" className={"input-main-collection-page "+ this.state.form1Class} placeholder={interfaceLocale['addUser']['nameRU']} value={this.state.nameRU} onChange={ (e) => {this._handleForm(e,'nameRU')} } required />
        <input type="text" className={"input-main-collection-page "+ this.state.form2Class} placeholder={interfaceLocale['addUser']['nameEN']} value={this.state.nameEN} onChange={ (e) => {this._handleForm(e,'nameEN')} } required />
        <div className="btn-group">
          <button value={this.state.permission} data-toggle="dropdown" className="btn btn-secondary dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>{this.state.permission === 0 ? localeSelectPermissons.selectButton[lang] : localeSelectPermissons.permissionsList[this.state.permission][lang]} <span className="caret" /></button>
          <ul className="dropdown-menu" x-placement="bottom-start" >
            {
              localeSelectPermissons.permissionsList.map( (permissionItem, index) => {
                return <li key={index}><button className="dropdown-item" style={{cursor:'pointer'}} onClick={ (e) => {this._handleChangePermission(e,permissionItem.type)} }>{permissionItem[lang]}</button></li>
              })
            }
          </ul>
        </div>
      </div>
      <div className="col-md-12 mb-3 ml-2">
        <input type="text" className={"input-main-collection-page  "+ this.state.form3Class} placeholder={interfaceLocale['addUser']['login']} value={this.state.login} onChange={ (e) => {this._handleForm(e,'login')} } required />
        <input type="password" className={"input-main-collection-page  "+ this.state.form4Class} placeholder={interfaceLocale['addUser']['pass']} value={this.state.pass} onChange={ (e) => {this._handleForm(e,'pass')} } required />
        <input type="password" className={"input-main-collection-page "+ this.state.form5Class} placeholder={interfaceLocale['addUser']['passRepeat']} value={this.state.passRepeat} onChange={ (e) => {this._handleForm(e,'passRepeat')} } required />
      </div>
      <div className="col-md-12 mb-4 ml-2">
        {
          this.state.login !== '' &&
          this.state.nameRU !== '' &&
          this.state.nameEN !== '' &&
          this.state.pass !== '' &&
          this.state.permission !== 0 &&
          this.state.pass === this.state.passRepeat ?
          <button type="submit" className="btn btn-secondary " onClick={this._handleClick.bind(this)}>{interfaceLocale['addUser']['button']}</button>
          : ''
        }
      </div>
    </div>
  )
  }
}

export default AddUser;
