import React, { Component } from 'react';
import { Redirect } from 'react-router-dom'

import SideBar from './SideBar';
import Navbar from './Navbar';

class Layout extends Component {
    render() {
      const token = window.localStorage.getItem('token');
      const user = JSON.parse(window.localStorage.getItem('user'));
      if (!token) {
          return <Redirect to="/auth"/>
      } else {
          return (
            <div className="layout">
              <div className="sidebar-container">
                <SideBar match={this.props.match} history={this.props.history} />
              </div>
              <div className="main-container">
                <div className="container-fluid">
                  <Navbar match={this.props.match} history={this.props.history} settings={this.props.navbarSettings} user={user} title={this.props.title || ''} />
                  <div className="row">
                    {this.props.children}
                  </div>
                </div>
              </div>
            </div>
          );
      }
    }
}

export default Layout;
