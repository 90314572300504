import React, { useState, useEffect, useContext } from 'react';
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'
import HandlingContext from '../../context/HandlingContext'
let digitsList = [1,2,3,4,5,6,7,8,9,'',0]

function LockScreen() {
  const [digits, setDigits] = useState(0)
  const [pinCode,updatePinCode] = useState('')
  const { dispatch } = useContext(HandlingContext)

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${DOMAIN_NAME_API}/users/auth/byPinCode/${pinCode}`)
      if (response.data.status === 'ok' && response.data.user) {
        dispatch({
          type: 'LOG_IN',
          payload: response.data.user
        })
      }
    }
    if (digits === 4) {
      fetchData()
    }
  },[digits,pinCode,dispatch])
  
  const handlePad = (digit) => {
    if (digits < 4) {
      setDigits(prev => prev+1)
      updatePinCode(prev => prev + digit)
    }
  }

  const handlePadBackspace = () => {
    if (digits !== 0) {
      setDigits(prev => prev-1)
      updatePinCode(prev => prev.slice(0,-1))
    }
  }

  return (
    <div className="handling-locked text-center">
      <div className="row pad">
        <div className="col-md-12 mb-4">
          <div className="row">
          <div className="col-md-3">
            {
              digits > 0 ?
              <button className="btn btn-block pad-digit"><i className="fas fa-circle"></i></button> : <button className="btn btn-block pad-digit pad-placeholder"><i className="fas fa-circle"></i></button>
            }
          </div>
            <div className="col-md-3">
              {
                digits > 1 ?
                <button className="btn btn-block pad-digit"><i className="fas fa-circle"></i></button> : <button className="btn btn-block pad-digit pad-placeholder"><i className="fas fa-circle"></i></button>
              }
            </div>
            <div className="col-md-3">
              {
                digits > 2 ?
                <button className="btn btn-block pad-digit"><i className="fas fa-circle"></i></button> : <button className="btn btn-block pad-digit pad-placeholder"><i className="fas fa-circle"></i></button>
              }
            </div>
            <div className="col-md-3">
              {
                digits > 3 ?
                <button className="btn btn-block pad-digit"><i className="fas fa-circle"></i></button> : <button className="btn btn-block pad-digit pad-placeholder"><i className="fas fa-circle"></i></button>
              }
            </div>
          </div>
        </div>
        {
          digitsList.map( (digit, index) => {
            if (index === 9) {
              return  <div className="col-md-4 mb-4" key={index}></div>
            }
            return (
              <div className="col-md-4 mb-4" key={index}>
                <button className="btn btn-lg btn-secondary btn-block pad-button" onClick={handlePad.bind(this,digit)}>{digit}</button>
              </div>
            )
          })
        }
        <div className="col-md-4 mb-4">
          <button className="btn btn-lg btn-secondary btn-block pad-button" onClick={()=>{ handlePadBackspace() }}>{'<'}</button>
        </div>
      </div>
    </div>
  )
}

export default LockScreen
