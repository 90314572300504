import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios'
import { DOMAIN_NAME_API } from '../../Constants'
import moment from 'moment'
// eslint-disable-next-line
import ru from 'moment/locale/ru'
// import DeliveryContext from '../../context/DeliveryContext'
import { toFormatRollLength, declOfPrice, checkValue } from '../../Functions'

function DeliveryItem(props){
  // const { dispatch } = useContext(DeliveryContext)
  let delivery = props.delivery
  const [visible, setVisible] = useState(false)
  const [template, setTemplate] = useState('')
  const [totalBalance, setTotalBalance] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [currency, setCurrency] = useState(0)
  const [deliveryCost, updateDeliveryCost] = useState({
    value: delivery.cost ? (delivery.cost.worth/100).toString() : '',
    visible: false
  })

  useEffect(() => {
    let data = {
      totalBalance: 0,
      totalPrice: 0,
      currency: 0
    }

    data.template = delivery.fabrics.map((fabricItem,index)=>{
      data.totalBalance = data.totalBalance + fabricItem.length
      data.totalPrice = data.totalPrice + fabricItem.price.worth
      data.currency = fabricItem.price.type
      return (
        <tr key={index}>
          <td>{fabricItem.collection.nameEN} {fabricItem.description.nameEN}</td>
          <td>{fabricItem.length/100} {toFormatRollLength(fabricItem.length/100)}</td>
        </tr>
      )
    })
    setTotalBalance(data.totalBalance)
    setTotalPrice(data.totalPrice)
    setCurrency(data.currency)
    setTemplate(data.template)
  },[delivery.fabrics])

  delivery.date = moment.unix(delivery.created)

  function saveDeliveryCost(){
    async function postData(){
      let config = { headers: { 'content-type': 'application/json', 'token': localStorage.getItem('token') }}

      const response = await axios.post(DOMAIN_NAME_API + '/deliveries/cost', {
        deliveryID: delivery.id,
        worth: +deliveryCost.value*100
      }, config);

      console.log(response)
    }
    postData()

    updateDeliveryCost(prevState => {
      return { ...prevState, visible: false }
    })
  }

  function showInput(){
    updateDeliveryCost(prevState => {
      return { ...prevState, visible: true }
    })
  }

  function handleInputKeyDown(event){
    switch (event.key) {
      case 'Enter':
        event.preventDefault()
        saveDeliveryCost()
        break;
      default:
        break;
    }
  }

  const handleForm = (event) => {
    event.persist()
    // let newValue = event.target.value.replace(/[^.\d]+/g,"").replace(/^([^\.]*\.)|\./g, '$1' )
    // console.log(event.target.value,checkValue(event.target.value))
    updateDeliveryCost(prevState => {
      return { ...prevState, value: checkValue(event.target.value) }
    })
  }

  return (
    <div className="delivery-block">
      <div className="delivery-title">
        <h5>
          <Link to={`/deliveries/${delivery.id}`}>
            <i className="fa fa-clock-o"></i>&nbsp;&nbsp;{delivery.date.format('HH:mm')}
            &nbsp;&nbsp;
            <i className="fa fa-calendar"></i>&nbsp;&nbsp;{delivery.date.format('DD MMMM YYYY')}
          </Link>
        </h5>
      </div>
      <div className="delivery-item w-40">
        {
          visible ?
          <>
            <p onClick={()=>{setVisible(prevState => !prevState)}}>{`${totalBalance/100} ${toFormatRollLength(totalBalance/100)} ${totalPrice/100} ${declOfPrice(totalPrice/100,currency)}`}</p>
            <table className="table table-borderless table-sm mb-0" style={{marginLeft: 1+'rem'}}><tbody>{template}</tbody></table>
          </>
          : <p onClick={()=>{setVisible(prevState => !prevState)}}>{`${totalBalance/100} ${toFormatRollLength(totalBalance/100)} ${totalPrice/100} ${declOfPrice(totalPrice/100,currency)} (${delivery.fabrics.length})`}</p>
        }
      </div>
      <div className="delivery-item">
        {
          deliveryCost.visible
          ?
          <>
            <p className="mb-2">Стоимость доставки</p>
            <div className="input-group">
              <input type="text" className="input-main" style={{fontSize: 1+'rem', maxWidth: 4+'rem'}} value={deliveryCost.value} onChange={handleForm} onKeyDown={handleInputKeyDown} />
              <button className="btn btn-secondary btn-sm ml-1" onClick={saveDeliveryCost}><i className="fas fa-check" /></button>
            </div>
          </>
          :
          <>
            <p className="mb-1">Стоимость доставки:</p>
            {
              deliveryCost.value === ''
              ? <p className="pinCodeButton" onClick={showInput}>Не указано</p> :
              <p>{`${deliveryCost.value} ${declOfPrice((+deliveryCost.value).toFixed(0),3)}`}</p>
            }
          </>
        }
      </div>
    </div>
  )
}

export default DeliveryItem
