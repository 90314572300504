import React, { useEffect, useReducer, useState } from 'react'
import Layout from '../components/Layout'
import { Helmet } from "react-helmet"
// import { Link } from 'react-router-dom';
import priceListReducer from '../reducers/PriceListReducer'
import PriceListContext from '../context/PriceListContext'
import axios from 'axios'
import { DOMAIN_NAME_API } from '../Constants'
import SelectPriceListItems from '../components/priceLists/SelectPriceListItems'

function PriceListPage(props){
  const [state, dispatch] = useReducer(priceListReducer, {
    priceLists: [],
    selectedPriceList: 0,
    selectedPriceListID: 1,
    collections: []
  })
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    async function fetchData() {
      let priceListsRes = await axios.get(`${DOMAIN_NAME_API}/priceLists`)
      let priceLists = priceListsRes.data.priceLists

      dispatch({
        type: 'INITIAL_PRICELISTS',
        payload: priceLists
      })
      setLoaded(true)
      // console.log('priceLists',priceLists)
    }
    fetchData()
  },[])

  let helmetTitle = 'Прайс-листы - CRM Фабрика Фурнитуры'
  return (
    <PriceListContext.Provider value={{ state, dispatch }}>
      <Layout match={props.match} history={props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row">
            {
              isLoaded ? <SelectPriceListItems /> : null
            }
          </div>
        </div>
      </Layout>
    </PriceListContext.Provider>
  )
}

export default PriceListPage
