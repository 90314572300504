import React, { Component } from 'react';
import Layout from '../Layout';
import CategorySelect from './CategorySelect';
import Dropzone from 'react-dropzone'
import RichTextEditor from 'react-rte';
import Image from '../Image'

import { DOMAIN_NAME_API, IMAGE_SERVER } from '../../Constants'
import update from 'immutability-helper';
import axios from 'axios'
import { httpRequest } from '../../Functions.js'
import * as localeRU from './locale_ru.json'
import * as localeEN from './locale_en.json'
const locale = {
    "ru":localeRU.default,
    "en":localeEN.default
  }

class CollectionNew extends Component {
  constructor(props) {
      super(props);
      this.state = {
          collection: {
            nameRU: '',
            nameEN: '',
            categoryID:'',
            image:'',
            active: 0,
            sale: 0,
            new: 0,
            custom: 0,
            created:'',
            material:'',
            resistance:'',
            width:'',
            density:'',
            description: ''
          },
          editor: RichTextEditor.createValueFromString('Описание коллекции', 'html'),
          downloaded: false
      };
  }
  componentDidMount() {
    var now = new Date();
    const initialData = this.state.collection;
    let newData = update(initialData, {created: {$set: now.getTime()/1000}});
    this.setState({ collection: newData });
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
  // nameRU
  _handleForm1 = (event) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {nameRU: {$set: event.target.value}});
    this.setState({ collection: newData });
  }
  // nameEN
  _handleForm2 = (event) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {nameEN: {$set: event.target.value}});
    this.setState({ collection: newData });
  }
  // material
  _handleForm3 = (event) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {material: {$set: event.target.value}});
    this.setState({ collection: newData });
  }
  // resistance
  _handleForm4 = (event) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {resistance: {$set: event.target.value}});
    this.setState({ collection: newData });
  }
  // width
  _handleForm5 = (event) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {width: {$set: event.target.value}});
    this.setState({ collection: newData });
  }
  // density
  _handleForm6 = (event) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {density: {$set: event.target.value}});
    this.setState({ collection: newData });
  }
  _handleEditor = (value) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {description: {$set: value.toString('html')}});
    this.setState({
      collection: newData,
      editor: value
    });
  }
  updateData = (value,data) => {
    // console.log(value,data);
    switch(value) {
      // case 'ADD_COLLECTION':
      //   this.reloadColections();
      //   console.log('ADD_COLLECTION');
      //   break;
      case 'UPDATE_CATEGORY':
        const initialData = this.state.collection;
        let newData = update(initialData, {categoryID: {$set: data.id}});
        this.setState({ collection: newData });
        // console.log('UPDATE_COLLECTION');
        break;
        case 'DELETE_CATEGORY':
          console.log('DELETE_CATEGORY');
          break;
      default:
        break;
    }
  }
  // _handleChangeImage(){
  //
  // }
onDrop(files) {
  if(files.length === 1){
    let file = files[0];
    this.loadImageInServer(file);
  } else {
    files.forEach(file => {
      this.loadImageInServer(file)
    });
  }
}
loadImageInServer(image){

  let formData = new FormData();
  formData.append('image',image);

  httpRequest.post(IMAGE_SERVER + '/upload', formData)
  .then((data) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {image: {$set: data.imageURL}});
    setTimeout(()=>{
      this.setState({ collection: newData });
    }, 1200)
  })
  .catch((error) => {
    console.log(error)
  });

  // let data = new FormData();
  // data.append('image',image);
  //
  // const config = {
  //     headers: { 'content-type': 'multipart/form-data' }
  // };
  //
  // axios.post(IMAGE_SERVER + '/imageUpload2.php', data, config)
  //      .then((res) => {
  //        console.log(res);
  //        const initialData = this.state.collection;
  //        let newData = update(initialData, {image: {$set: res.data.imageURL}});
  //        this.setState({ collection: newData });
  // });
}
  createCollection(){
    const url = DOMAIN_NAME_API + '/collections';
    let config = { headers: { 'content-type': 'application/json', 'token': localStorage.getItem('token') }};
    let collection = this.state.collection;
      axios.post(url, collection, config)
      .then((res) => {
        this.redirectCollectionEdit(res.data.id);
        // console.log(res.data);
        }
      );
  }
  redirectCollectionEdit(id) {
    this.props.history.push('/collections/'+id)
  }
  render() {
    const selectedLanguage = localStorage.getItem('lang');
    const interfaceLocale = locale[selectedLanguage];
    let collection = this.state.collection,
        resistance = '';

    if (collection){
      if (collection.resistance){
        resistance = this.state.collection.resistance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        // resistance = this.state.collection.resistance.toLocaleString('ru')
      }
    }
    return (
      <Layout match={this.props.match} history={this.props.history}>
        <div className="col-md-10">
          <div className="row">
            <div className="col-md-12">
              <h1>&nbsp;</h1>
              <h1>&nbsp;</h1>
            </div>
            <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <CategorySelect match={this.props.match} locale={interfaceLocale} defaultID={this.state.collection.categoryID} updateData={this.updateData} />
                    <input type="text" className="input-main-collection-page mb-2" placeholder={interfaceLocale['collection']['nameRU']} value={this.state.collection.nameRU} onChange={this._handleForm1.bind(this)} />
                    <input type="text" className="input-main-collection-page mb-5" placeholder={interfaceLocale['collection']['nameEN']} value={this.state.collection.nameEN} onChange={this._handleForm2.bind(this)} />


                    <form className="form-inline mb-2">
                      <div className="form-group">
                        <input type="text" className="input-main" placeholder={interfaceLocale['collection']['material']} value={this.state.collection.material} onChange={this._handleForm3.bind(this)} />
                      </div>
                      <div className="form-group">
                        {
                          this.state.collection.material !== '' ?
                          <p className="text-muted input-text-example">{interfaceLocale['desctiprion']['material']}</p>
                          : ''
                        }
                      </div>
                    </form>
                    <form className="form-inline mb-2">
                      <div className="form-group">
                        <input type="text" className="input-main" placeholder={interfaceLocale['collection']['resistance']} value={this.state.collection.resistance} onChange={this._handleForm4.bind(this)} />
                      </div>
                      <div className="form-group">
                        <p className="text-muted input-text-example">{resistance !== '' ? resistance +' '+ interfaceLocale['desctiprion']['resistance'] : ''}</p>
                      </div>
                    </form>
                    <form className="form-inline mb-2">
                      <div className="form-group">
                        <input type="text" className="input-main" placeholder={interfaceLocale['collection']['width']} value={this.state.collection.width} onChange={this._handleForm5.bind(this)} />
                      </div>
                      <div className="form-group">
                        {
                          this.state.collection.width !== '' ?
                          <p className="text-muted input-text-example">{this.state.collection.width +' '+ interfaceLocale['desctiprion']['fabricWidth']}</p>
                          : ''
                        }
                      </div>
                    </form>
                    <form className="form-inline mb-5">
                      <div className="form-group">
                        <input type="text" className="input-main" placeholder={interfaceLocale['collection']['density']} value={this.state.collection.density} onChange={this._handleForm6.bind(this)} />
                      </div>
                      <div className="form-group">
                        {
                          this.state.collection.density !== '' ?
                          <p className="text-muted input-text-example">{this.state.collection.density +' '+ interfaceLocale['desctiprion']['density']}</p>
                          : ''
                        }
                      </div>
                    </form>
                    <RichTextEditor
                      value={this.state.editor ? this.state.editor : RichTextEditor.createValueFromString('Описание коллекции','html')}
                      onChange={this._handleEditor}
                      className="editor-main mb-5"
                      toolbarClassName={'toolbar-category-description'}
                    />
                    {
                      this.state.collection.nameRU !== '' &&
                      this.state.collection.nameEN !== '' &&
                      this.state.collection.categoryID !== '' &&
                      this.state.collection.image !== '' &&
                      this.state.collection.material !== '' &&
                      this.state.collection.resistance !== '' &&
                      this.state.collection.width !== '' &&
                      this.state.collection.density !== '' &&
                      this.state.collection.description !== '' ?
                      <button type="submit" className="btn btn-secondary mb-4" onClick={this.createCollection.bind(this)}>{interfaceLocale['createCollectionButton']}</button>
                      : ''
                    }
                  </div>
                  <div className="col-md-6">
                    <div className="image-item">
                      <Image url={collection.image} height={600} />
                      <Dropzone onDrop={this.onDrop.bind(this)}>
                        {({getRootProps, getInputProps}) => (
                          <section>
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="image-img-overlay">
                                <h5 className="image-title">{interfaceLocale['imageUpload']}</h5>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>

            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CollectionNew;

// <picture className="intrinsic intrinsic--4x3 rounded">
//   {
//     collection.image ?
//     <>
//       <source srcset={CDN_SERVER + webpSource + '?h=600'} type="image/webp" />
//       <source srcset={CDN_SERVER + jpgSource + '?h=600'} type="image/jpeg" />
//       <img className="intrinsic-item rounded" src={CDN_SERVER + jpgSource + '?h=600'} alt="Collection" />
//     </>
//     : <img src={CDN_SERVER + '/images/no-image.jpg'} className="intrinsic-item rounded" alt="" />
//   }
// </picture>
// <img src={collection.image ? CDN_SERVER + collection.image + '?w=800&h=600' : CDN_SERVER + '/images/no-image.jpg'} className="img-fluid rounded" alt="alt" />
// <button className="btn btn-secondary " onClick={this._handleChangeImage.bind(this)}>Изменить изображение</button>
