import React, { Component } from 'react';
import Layout from '../Layout';
import Dropzone from 'react-dropzone'
import Image from '../Image'
import update from 'immutability-helper';
import axios from 'axios'
// import { useHttp } from '../../hooks/http.hook.js'
import { httpRequest } from '../../Functions.js'
import {Helmet} from "react-helmet";
import RichTextEditor from 'react-rte';
import { DOMAIN_NAME_API, IMAGE_SERVER } from '../../Constants'

import * as localeRU from './locale_ru.json'
import * as localeEN from './locale_en.json'
const locale = {
    "ru":localeRU.default,
    "en":localeEN.default
  }

class CategoryEdit extends Component {
  constructor(props) {
      super(props);
      this.state = {
          category: {
            nameRU: '',
            nameEN: '',
            description: '',
            image: null
          },
          editor: RichTextEditor.createValueFromString('Текстовый блок', 'html'),
          downloaded: false
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
    this.reloadCategory();
  }
  reloadCategory(){
    axios.get(DOMAIN_NAME_API + '/categories/'+this.props.match.params.id)
    .then(res => {
      this.setState({
        category: res.data,
        downloaded: true,
        editor: RichTextEditor.createValueFromString(res.data.description, 'html'),
      })
    });
  }
  // nameRU
  _handleForm1 = (event) => {
    const initialData = this.state.category;
    let newData = update(initialData, {nameRU: {$set: event.target.value}});
    this.setState({ category: newData },()=> { this.saveCategory() });
  }
  // nameEN
  _handleForm2 = (event) => {
    const initialData = this.state.category;
    let newData = update(initialData, {nameEN: {$set: event.target.value}});
    this.setState({ category: newData },()=> { this.saveCategory() });
  }
  _handleEditor = (value) => {
    const initialData = this.state.category;
    let newData = update(initialData, {description: {$set: value.toString('html')}});
    this.setState({
      category: newData,
      editor: value
    },()=> { this.saveCategory() });
  }
  // // material
  // _handleForm3 = (event) => {
  //   const initialData = this.state.collection;
  //   let newData = update(initialData, {material: {$set: event.target.value}});
  //   this.setState({ collection: newData },()=> { this.saveCollection() });
  // }
  // // resistance
  // _handleForm4 = (event) => {
  //   const initialData = this.state.collection;
  //   if (event.target.validity.valid){
  //     let newData = update(initialData, {resistance: {$set: event.target.value}});
  //     this.setState({ collection: newData },()=> { this.saveCollection() });
  //   }
  // }
  // // width
  // _handleForm5 = (event) => {
  //   const initialData = this.state.collection;
  //   if (event.target.validity.valid){
  //     let newData = update(initialData, {width: {$set: event.target.value}});
  //     this.setState({ collection: newData },()=> { this.saveCollection() });
  //   }
  // }
  // // density
  // _handleForm6 = (event) => {
  //   const initialData = this.state.collection;
  //   if (event.target.validity.valid){
  //     let newData = update(initialData, {density: {$set: event.target.value}});
  //     this.setState({ collection: newData },()=> { this.saveCollection() });
  //   }
  // }
  updateData = (value,data) => {
    // console.log(value,data);
    switch(value) {
      // case 'ADD_COLLECTION':
      //   this.reloadColections();
      //   console.log('ADD_COLLECTION');
      //   break;
      case 'UPDATE_CATEGORY':
        const initialData = this.state.collection;
        let newData = update(initialData, {categoryID: {$set: data.id}});
        this.setState({ collection: newData },()=> { this.saveCategory() });
        console.log('UPDATE_COLLECTION');
        break;
        case 'DELETE_CATEGORY':
          this.reloadColection();
          console.log('DELETE_CATEGORY');
          break;
      default:
        break;
    }
  }
onDrop(files) {
  if(files.length === 1){
    let file = files[0];
    this.loadImageInServer(file);
  } else {
    files.forEach(file => {
      this.loadImageInServer(file)
    });
  }
}
loadImageInServer(image){
  let formData = new FormData();
  formData.append('image',image);

  httpRequest.post(IMAGE_SERVER + '/upload', formData)
  .then((data) => {
    const initialData = this.state.category;
    let newData = update(initialData, {image: {$set: data.imageURL}});
    setTimeout(()=>{
      this.setState({ category: newData },()=> { this.saveCategory() });
    }, 1200)
  })
  .catch((error) => {
    console.log(error)
  });


  // const config = {
  //     headers: { 'content-type': 'multipart/form-data' }
  // };

  // axios.post( IMAGE_SERVER + '/imageUpload2.php', data, config )
  //  .then((res) => {
  //    console.log(res);
  //    const initialData = this.state.category;
  //    let newData = update(initialData, {image: {$set: res.data.imageURL}});
  //    this.setState({ category: newData },()=> { this.saveCategory() });
  // })
  // .catch((error) => {
  //   console.log(error)
  // });
}
  saveCategory(){
    const url = DOMAIN_NAME_API + '/categories/'+this.props.match.params.id;
    const config = {
        headers: { 'token': localStorage.getItem('token') }
    };
    let category = this.state.category;

    axios.put(url, category, config)
  }
    render() {
      const selectedLanguage = localStorage.getItem('lang');
      const interfaceLocale = locale[selectedLanguage];
      let category = this.state.category;
      let helmetTitle = 'Категория ' + this.state.category.nameRU + ' - CRM Фабрика Фурнитуры';
      return (
        <Layout match={this.props.match} history={this.props.history}>
          <Helmet>
            <title>{helmetTitle}</title>
          </Helmet>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <h1>&nbsp;</h1>
                <h1>&nbsp;</h1>
              </div>
              <div className="col-md-12">
              {
                this.state.downloaded ? (
                  <div className="row">
                    <div className="col-md-6">
                      <input type="text" className="input-main-collection-page mb-4" placeholder={interfaceLocale['searchForm']} value={this.state.category.nameRU} onChange={this._handleForm1.bind(this)} />
                      <input type="text" className="input-main-collection-page mb-5" placeholder={interfaceLocale['searchForm']} value={this.state.category.nameEN} onChange={this._handleForm2.bind(this)} />
                      <RichTextEditor
                        value={this.state.editor ? this.state.editor : RichTextEditor.createValueFromString('Описание категории','html')}
                        onChange={this._handleEditor}
                        className="editor-main"
                        toolbarClassName={'toolbar-category-description'}
                      />
                    </div>
                    <div className="col-md-6">
                      <div className="image-item">
                        <Image url={category.image} height={600} />
                        <Dropzone onDrop={this.onDrop.bind(this)}>
                          {({getRootProps, getInputProps}) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="image-img-overlay">
                                  <h5 className="image-title">{interfaceLocale['imageUpload']}</h5>
                                </div>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </div>
                ) : ''
              }

              <div className="row">

              </div>


              </div>
            </div>
          </div>
        </Layout>
      );
    }
}

export default CategoryEdit;

// <picture className="intrinsic intrinsic--4x3 rounded">
//   {
//     category.image ?
//     <>
//       <source srcset={CDN_SERVER + webpSource + '?h=600'} type="image/webp" />
//       <source srcset={CDN_SERVER + jpgSource + '?h=600'} type="image/jpeg" />
//       <img className="intrinsic-item rounded" src={CDN_SERVER + jpgSource + '?h=600'} alt="Collection" />
//     </>
//     : <img src={CDN_SERVER + '/images/no-image.jpg'} className="intrinsic-item rounded" alt="" />
//   }
// </picture>
// <button className="btn btn-secondary " onClick={this._handleChangeImage.bind(this)}>Изменить изображение</button>
