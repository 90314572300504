import React, { Component } from 'react';
import Layout from '../Layout';
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import SearchStock from './SearchStock';
import StockItem from './StockItem';

class Stock extends Component {
  constructor(props) {
      super(props);
      this.state = {
          collections: [],
          fabrics: [],
          stock: [],
          itemsLimit: 16,
          sortedCollections: [],
          searchStatus: false,
          loaded: false
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');

    axios.all([
      axios.get(DOMAIN_NAME_API + '/collections?options=ORDER BY nameEN'),
      axios.get(DOMAIN_NAME_API + '/fabrics?options=ORDER BY collectionID'),
      axios.get(DOMAIN_NAME_API + '/rolls?options=WHERE balance > 0')
    ])
    .then(axios.spread((collectionsRes, fabricsRes, rollsRes) => {
      let collections = collectionsRes.data.collections;
      let fabrics = fabricsRes.data;
      let rolls = rollsRes.data.rolls;
      let formattedFabrics = [];

      collections.forEach((collection, i) => {
        fabrics.forEach((fabric, i) => {
          if (collection.id === fabric.collectionID) {
            fabric.collection = collection;
            fabric.stock = [];
            formattedFabrics.push(fabric);
          }
        });
      });

      // stock.forEach((stockItem, i) => {
      //   formattedFabrics.forEach((fabric, i) => {
      //     if (stockItem.fabricID === fabric.id) {
      //       fabric.stock = stockItem;
      //     }
      //   });
      // });

      rolls.forEach((rollItem, i) => {
        formattedFabrics.forEach((fabric, i) => {
           // && stockItem.status === 1
          if (rollItem.fabricID === fabric.id) {
            fabric.stock.push(rollItem);
          }
        });
      });

      // Update State with fabrics and collections
      this.setState({
        fabrics: formattedFabrics,
        collections: collections
      })

    }))
    .catch( error => {
      // handle error
      console.log(error);
    });

    // axios.get( DOMAIN_NAME_API + '/collections?options=ORDER BY nameEN' )
    // .then(res1 => {
    //   let collections = res1.data.collections
    //   // this.setState({
    //   //   collections: collections
    //   // });
    //
    //   axios.get(DOMAIN_NAME_API + '/fabrics?options=ORDER BY collectionID')
    //   .then(res2 => {
    //     let fabrics = res2.data,
    //         formattedFabrics = [];
    //
    //     // fabrics.forEach((fabric, i) => {
    //     //   let shit = collections.filter( collection => (collection.id === fabric.collectionID) );
    //     //   fabric.collection = shit[0];
    //     //   formattedFabrics.push(fabric);
    //     // });
    //
    //     collections.forEach((collection, i) => {
    //       fabrics.forEach((fabric, i) => {
    //         if (collection.id === fabric.collectionID) {
    //           fabric.collection = collection;
    //           formattedFabrics.push(fabric);
    //         }
    //       });
    //
    //     });
    //
    //
    //     axios.get(DOMAIN_NAME_API + '/stock?options=WHERE status = 1')
    //     .then(res3 => {
    //       let stock = res3.data.stock;
    //
    //       stock.forEach((stockItem, i) => {
    //         formattedFabrics.forEach((fabric, i) => {
    //            // && stockItem.status === 1
    //           if (stockItem.fabricID === fabric.id) {
    //             fabric.stock = stockItem;
    //           }
    //         });
    //
    //       });
    //
    //       // Update State with fabrics and collections
    //       this.setState({
    //         fabrics: formattedFabrics,
    //         collections: collections,
    //         stock: stock
    //       })
    //
    //     });
    //
    //   });
    //
    // });



    // axios.get(DOMAIN_NAME_API + '/stock?options=WHERE status = 1')
    document.addEventListener('scroll', this.trackScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }
  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }
  trackScrolling = () => {
    const wrappedElement = document.getElementById('root');
    if (this.isBottom(wrappedElement)) {
      this.setState({
        itemsLimit: this.state.itemsLimit + 8
      })
      // console.log('header bottom reached');
      // document.removeEventListener('scroll', this.trackScrolling);
    }
  }
  updateData = (value,data) => {
    console.log(value,data);
    switch(value) {
      case 'ADD_STOCK':
        console.log('ADD_STOCK');
        break;
      case 'SEARCH_STOCK':
      if(data.searchValue !== ''){
        this.setState({sortedCollections: data.sortedData, searchStatus: true})
      } else if( data.category && data.searchValue !== ''){
        this.setState({sortedCollections: data.sortedData, searchStatus: true})
      } else {
        this.setState({sortedCollections: [], searchStatus: false})
      }
        console.log('SEARCH_STOCK',data);
        break;
      default:
        break;
    }
  }
  render() {
    let helmetTitle = 'Склад - CRM Фабрика Фурнитуры',
        template = '',
        prevCollection = 'start';
    if (this.state.searchStatus) {
      template = this.state.sortedCollections.slice(0,this.state.itemsLimit).map((item,index)=>{
        if (item.collection.nameEN !== prevCollection) {
          prevCollection = item.collection.nameEN;
          return (
            <StockItem key={index} updateData={this.updateData} item={item} preItem={<div className="col-md-12 mb-2"><h4>{'#' + item.collection.id} {item.collection.nameEN}</h4></div>} />
          )
        } else {
          return (
            <StockItem key={index} updateData={this.updateData} item={item} />
          )
        }
      });
    } else {
      template = this.state.fabrics.slice(0,this.state.itemsLimit).map((item,index)=>{
        if (item.collection.nameEN !== prevCollection) {
          prevCollection = item.collection.nameEN;
          return (
            <StockItem key={index} updateData={this.updateData} item={item} preItem={<div className="col-md-12 mb-2"><h4>{item.collection.nameEN}</h4></div>} />
          )
        } else {
          return (
            <StockItem key={index} updateData={this.updateData} item={item} />
          )
        }
      });
    }
    // console.log(this.state.fabrics);
    return (
      <Layout match={this.props.match} history={this.props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <div className="row">

                <div className="col-md-8  mb-2">
                  <SearchStock updateData={this.updateData} items={this.state.fabrics} />
                </div>

                <div className="col-md-4 text-right mb-2">
                  <Link to={'/deliveries'} className="btn btn-secondary">{'Поставка'}</Link>
                  <Link to={'/handling'} className="btn btn-secondary ml-3">{'Отгрузка'}</Link>
                </div>

                <div className="col-md-12">
                  <div className="row">
                    { template }
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Stock;

// <StockItem key={index} updateData={this.updateData} item={item} preItem={<div className="col-md-12 mb-2"><h4>{'#' + item.collection.id} {item.collection.nameEN}</h4></div>} />


// <button className="btn btn-secondary">Отгрузка</button>

// <div className="col-md-12"><h4>Коллекция: Adileta Rose</h4></div>
// <div className="col-md-12"><h5>Коллекция: Adileta Rose</h5></div>

// <div className="col-md-12"><h1>Коллекция: Adileta Rose</h1></div>
// <div className="col-md-12"><h2>Коллекция: Adileta Rose</h2></div>
// <div className="col-md-12"><h3>Коллекция: Adileta Rose</h3></div>
// <div className="col-md-12"><h4>Коллекция: Adileta Rose</h4></div>
// <div className="col-md-12"><h5>Коллекция: Adileta Rose</h5></div>
// <div className="col-md-12"><h6>Коллекция: Adileta Rose</h6></div>
// <div className="col-md-12"><h7>Коллекция: Adileta Rose</h7></div>

// <div className="col-md-6">
//   <div className="form-group">
//     <textarea className="input-main input-border mb-4" placeholder="Вставьте сюда остаток из 1С" rows="5"></textarea>
//     <button className="btn btn-secondary form-button mb-4">Обновить</button>
//   </div>
// </div>
