import React, { Component } from 'react';
import Layout from '../Layout';
import ClientItem from './ClientItem';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import { DOMAIN_NAME_API } from '../../Constants'
import axios from 'axios'

class Clients extends Component {
  constructor(props) {
      super(props);
      this.state = {
        clients: [],
        loaded: false
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
    this.reloadClients();
  }
  reloadClients(){
    axios.get(DOMAIN_NAME_API + '/clients')
    .then(res => {
      var clients = res.data.clients;
      this.setState({
        clients: clients,
        loaded: true
      })
    });
  }
  render() {
    let helmetTitle = 'Клиенты - CRM Фабрика Фурнитуры';
    // const selectedLanguage = localStorage.getItem('lang');
    // const interfaceLocale = locale[selectedLanguage];
    return (
      <Layout match={this.props.match} history={this.props.history} title={<Link to={'/clients/new'} className="btn btn-secondary ml-2">{'Новый клиент'}</Link>}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row">
            {
              this.state.loaded ?
              (
                this.state.clients.map((client, index) => {
                    return (
                      <ClientItem key={client.id} client={client} />
                    )
                })
              ) : ''
            }
          </div>
        </div>
      </Layout>
    );
  }
}

export default Clients;
