import React, { Component } from 'react';

class Error404 extends Component {
  // eslint-disable-next-line
  constructor(props) {
      super(props);
  }
  clickButton(){
    this.props.history.goBack();
  }
    render() {
        return (
          <div className="container-fluid" id="wrapper">
                <div className="row">
                  <main className="col-lg-6 m-auto">
                    <div className="text-center mt-5">
                      <h1 className="mb-0 no-border">Ошибка 404</h1>
                      <h3 className="mt-0 mb-5 text-muted">Страница не найдена</h3>
                      <p>Страница, которую вы ищете не найдена. Используйте кнопку "назад" в браузере или кнопку снизу, чтобы вернутсья обратно.</p>
                      <br />
                      <button className="btn btn-lg btn-primary" onClick={this.clickButton.bind(this)}>Вернуться обратно</button>
                      </div>
                  </main>
                  </div>
              </div>
        )
    }
}

export default Error404;
