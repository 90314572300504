import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../Layout';
import CategorySelect from './CategorySelect';
// import CollectionButtonDelete from './CollectionButtonDelete';
import FabricsEditor from './FabricsEditor';
import FeaturesEditor from './FeaturesEditor';
import CaresEditor from './CaresEditor';
import Dropzone from 'react-dropzone'
import RichTextEditor from 'react-rte';
import Image from '../Image'

import update from 'immutability-helper';
import axios from 'axios'
import { httpRequest } from '../../Functions.js'
import {Helmet} from "react-helmet";
import svgPlaceholder from '../../svg-placeholder.svg';

import { DOMAIN_NAME_API, IMAGE_SERVER, CDN_SERVER} from '../../Constants'
import { randomInteger } from '../../Functions'

import * as localeRU from './locale_ru.json'
import * as localeEN from './locale_en.json'
// import * as featuresJSON from './features.json'
// import * as caresJSON from './cares.json'

const locale = {
    "ru":localeRU.default,
    "en":localeEN.default
  }

// const features = featuresJSON.default.features;
// const cares = caresJSON.default.cares;

class CollectionPage extends Component {
  constructor(props) {
      super(props);
      this.state = {
          collection: {
            nameRU: '',
            nameEN: '',
            description: '',
            active: 0,
            sale: 0,
            new: 0,
            custom: 0
          },
          presentation: null,
          video: null,
          certificate: null,
          categoryNameEN: '',
          categories: [],
          editor: RichTextEditor.createValueFromString('Описание коллекции', 'html'),
          modalActive: false,
          modalInput: '',
          loaded: false
      };
  }
  componentDidMount() {
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
    // axios.get(DOMAIN_NAME_API + '/categories')
    // .then(res => {
    //   var categories = res.data.categories;
    //     this.setState({
    //       categories: categories
    //     })
    // })
    // .then(()=>{
    //   this.reloadColection();
    // })

    axios.all([
      axios.get(DOMAIN_NAME_API + '/categories'),
      axios.get(DOMAIN_NAME_API + '/presentations/'+this.props.match.params.id),
      axios.get(DOMAIN_NAME_API + '/videos/'+this.props.match.params.id),
      axios.get(DOMAIN_NAME_API + '/certificates/'+this.props.match.params.id)
    ])
    .then(axios.spread((categoriesRes, presentationsRes, videosRes, certificatesRes) => {
      // do something with both responses
      // console.log(presentationsRes.data, videosRes.data);

      this.setState({
        categories: categoriesRes.data.categories,
        presentation: presentationsRes.data.status !== 'not_found' ? presentationsRes.data.presentation[0] : null,
        video: videosRes.data.status !== 'not_found' ? videosRes.data.video[0] : null,
        certificate: certificatesRes.data.status !== 'not_found' ? certificatesRes.data.certificate[0] : null
      });

    }))
    .then(()=>{
      this.reloadColection();
    })
    .catch( error => {
      // handle error
      console.log(error);
    });


  }
  reloadColection(){
    axios.get(DOMAIN_NAME_API + '/collections/'+this.props.match.params.id)
    .then(res => {
      let descriptionLoaded = 'Описание коллекции';
      if (res.data.description !== ''){
        descriptionLoaded = res.data.description;
      }
      let categorySelected = this.state.categories.filter( category => ( category.id.toString().indexOf(res.data.categoryID.toString()) !== -1 ) );
      this.setState({
        collection: res.data,
        categoryNameEN: categorySelected[0]['nameEN'],
        categorySlug: categorySelected[0]['slug'],
        loaded: true,
        editor: RichTextEditor.createValueFromString(descriptionLoaded, 'html')
      })
    });
  }
  // nameRU
  _handleForm1 = (event) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {nameRU: {$set: event.target.value}});
    this.setState({ collection: newData },()=> { this.saveCollection() });
  }
  // nameEN
  _handleForm2 = (event) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {nameEN: {$set: event.target.value}});
    this.setState({ collection: newData },()=> { this.saveCollection() });
  }
  // material
  _handleForm3 = (event) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {material: {$set: event.target.value}});
    this.setState({ collection: newData },()=> { this.saveCollection() });
  }
  // resistance
  _handleForm4 = (event) => {
    const initialData = this.state.collection;
    if (event.target.validity.valid){
      let newData = update(initialData, {resistance: {$set: event.target.value}});
      this.setState({ collection: newData },()=> { this.saveCollection() });
    }
  }
  // width
  _handleForm5 = (event) => {
    const initialData = this.state.collection;
    if (event.target.validity.valid){
      let newData = update(initialData, {width: {$set: event.target.value}});
      this.setState({ collection: newData },()=> { this.saveCollection() });
    }
  }
  // density
  _handleForm6 = (event) => {
    const initialData = this.state.collection;
    if (event.target.validity.valid){
      let newData = update(initialData, {density: {$set: event.target.value}});
      this.setState({ collection: newData },()=> { this.saveCollection() });
    }
  }
  _handleEditor = (value) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {description: {$set: value.toString('html')}});
    this.setState({
      collection: newData,
      editor: value
    },()=> { this.saveCollection() });
  }
  updateData = (value,data) => {
    // console.log(value,data);
    switch(value) {
      // case 'ADD_COLLECTION':
      //   this.reloadColections();
      //   console.log('ADD_COLLECTION');
      //   break;
      case 'UPDATE_CATEGORY':
        const initialData = this.state.collection;
        let newData = update(initialData, {categoryID: {$set: data.id}});
        this.setState({ collection: newData },()=> { this.saveCollection() });
        console.log('UPDATE_COLLECTION');
        break;
        case 'DELETE_CATEGORY':
          this.deleteCollection();
          break;
      default:
        break;
    }
  }
onDrop(files) {
  if(files.length === 1){
    let file = files[0];
    this.loadImageInServer(file);
  } else {
    files.forEach(file => {
      this.loadImageInServer(file)
    });
  }
}
loadImageInServer(image){

  let formData = new FormData();
  formData.append('image',image);

  httpRequest.post(IMAGE_SERVER + '/upload', formData)
  .then((data) => {
    const initialData = this.state.collection;
    let newData = update(initialData, {image: {$set: data.imageURL}});
    setTimeout(()=>{
      this.setState({ collection: newData },()=> { this.saveCollection() });
    }, 1200)
  })
  .catch((error) => {
    console.log(error)
  });

  // let data = new FormData();
  // data.append('image',image);
  //
  // const config = {
  //     headers: { 'content-type': 'multipart/form-data' }
  // };
  //
  // axios.post( IMAGE_SERVER + '/imageUpload2.php', data, config)
  //      .then((res) => {
  //        console.log(res);
  //        const initialData = this.state.collection;
  //        let newData = update(initialData, {image: {$set: res.data.imageURL}});
  //        this.setState({ collection: newData },()=> { this.saveCollection() });
  // });
}
onDropPresentation(files) {
  if(files.length === 1){
    let file = files[0];
    this.loadPresentationInServer(file);
  } else {
    files.forEach(file => {
      this.loadPresentationInServer(file)
    });
  }
}
loadPresentationInServer(presentation){
  let data = new FormData();
  data.append('presentation',presentation);

  const config = {
      headers: { 'content-type': 'multipart/form-data' }
  };

  axios.post( IMAGE_SERVER + '/upload', data, config)
       .then((res) => {
         console.log(res.data.url);
         const url = DOMAIN_NAME_API + '/presentations';
         let config = { headers: { 'content-type': 'application/json', 'token': localStorage.getItem('token') }};
         axios.post(url, {
          	"collectionID": this.props.match.params.id,
          	"url": res.data.url
          }, config)
          .then((res2) => {
            this.setState({
              presentation: res2.data
            });
          });

  });
}
_handleClickDeletePresentation = event => {
  axios.delete(DOMAIN_NAME_API + '/presentations/'+this.state.presentation.id)
  .then(
    () => {
      this.setState({
        presentation: null
      });
    }
  )
}
onDropCertificate(files) {
  if(files.length === 1){
    let file = files[0];
    this.loadCertificateInServer(file);
  } else {
    files.forEach(file => {
      this.loadCertificateInServer(file)
    });
  }
}
loadCertificateInServer(certificate){
  let data = new FormData();
  data.append('certificate',certificate);

  const config = {
      headers: { 'content-type': 'multipart/form-data' }
  };

  axios.post( IMAGE_SERVER + '/upload', data, config)
       .then((res) => {
         console.log(res.data.url);
         const url = DOMAIN_NAME_API + '/certificates';
         let config = { headers: { 'content-type': 'application/json', 'token': localStorage.getItem('token') }};
         axios.post(url, {
          	"collectionID": this.props.match.params.id,
          	"url": res.data.url
          }, config)
          .then((res2) => {
            this.setState({
              certificate: res2.data
            });
          });

  });
}
_handleClickDeleteCertificate = event => {
  axios.delete(DOMAIN_NAME_API + '/certificates/'+this.state.certificate.id)
  .then(
    () => {
      this.setState({
        certificate: null
      });
    }
  )
}
  saveCollection(){
    const url = DOMAIN_NAME_API + '/collections/'+this.props.match.params.id;
    let collection = this.state.collection;
    const config = {
        headers: { 'token': localStorage.getItem('token') }
    };
      axios.put(url, collection, config)
      // .then(
      //   this.reloadColection(),
      // );
  }
  deleteCollection(){
    const url = DOMAIN_NAME_API + '/collections/'+this.props.match.params.id;
    const config = {
        headers: { 'token': localStorage.getItem('token') }
    };
      axios.delete(url, config)
      .then(
        res=>{
          window.location = '/collections';
        }
      )
    let collection = this.state.collection;
      axios.put(url, collection, config)
      // .then(
      //   this.reloadColection(),
      // );
  }
  _handleCheckboxChangeNew = event => {
    const initialData = this.state.collection;
    let status = (this.state.collection.new === 0 ? 1 : 0);
    let newData = update(initialData, {new: {$set: status}});
    this.setState( { collection: newData } );
    const url = DOMAIN_NAME_API + '/collections/new/'+this.props.match.params.id;
    const config = {
        headers: { 'token': localStorage.getItem('token') }
    };
      axios.put(url, {new:status}, config);
  }
  _handleCheckboxChangeSale = event => {
    const initialData = this.state.collection;
    let status = (this.state.collection.sale === 0 ? 1 : 0);
    let newData = update(initialData, {sale: {$set: status}});
    this.setState( { collection: newData } );
    const url = DOMAIN_NAME_API + '/collections/sale/'+this.props.match.params.id;
    const config = {
        headers: { 'token': localStorage.getItem('token') }
    };
      axios.put(url, {sale:status}, config);
  }
  _handleCheckboxChangeActive = event => {
    const initialData = this.state.collection;
    let status = (this.state.collection.active === 0 ? 1 : 0);
    let newData = update(initialData, {active: {$set: status}});
    this.setState( { collection: newData } );
    const url = DOMAIN_NAME_API + '/collections/active/'+this.props.match.params.id;
    const config = {
        headers: { 'token': localStorage.getItem('token') }
    };
      axios.put(url, {active:status}, config);
  }
  _handleCheckboxChangeCustom = event => {
    const initialData = this.state.collection;
    let status = (this.state.collection.custom === 0 ? 1 : 0);
    let newData = update(initialData, {custom: {$set: status}});
    this.setState( { collection: newData } );
    const url = DOMAIN_NAME_API + '/collections/custom/'+this.props.match.params.id;
    const config = {
        headers: { 'token': localStorage.getItem('token') }
    };
      axios.put(url, {custom:status}, config);
  }
  // Modal Input
  _handleModalInput = (event) => {
    this.setState({ modalInput: event.target.value });
  }
  _handleClickSelectVideo = event => {
    this.setState({
      modalActive: true
    })
  }
  _handleClickSaveVideo = event => {

    const url = DOMAIN_NAME_API + '/videos';
    let config = { headers: { 'content-type': 'application/json', 'token': localStorage.getItem('token') }};
    axios.post(url, {
       "collectionID": this.props.match.params.id,
       "url": this.state.modalInput
     }, config)
     .then((res) => {
       this.setState({
         video: res.data,
         modalInput: '',
         modalActive: false
       });
       window.$('#exampleModalCenter').modal('hide');
     });
  }
  _handleClickDeleteVideo = event => {
    axios.delete(DOMAIN_NAME_API + '/videos/'+this.state.video.id)
    .then(
      () => {
        this.setState({
          video: null
        });
      }
    )
  }
    render() {
      const selectedLanguage = localStorage.getItem('lang');
      const interfaceLocale = locale[selectedLanguage];
      let collection = this.state.collection,
          resistance = '';

      if (collection){
        if (collection.resistance){
          resistance = this.state.collection.resistance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          // resistance = this.state.collection.resistance.toLocaleString('ru')
        }
      }
      let helmetTitle = 'Коллекция ' + this.state.collection.nameRU + ' - CRM Фабрика Фурнитуры';
      let urlQR = encodeURI("/qr/"+this.state.categorySlug+"/"+this.state.collection.slug);
      // let urlPDF = encodeURI("/pdf/"+this.state.categoryNameEN.replace(' ','-').toLowerCase()+"/"+this.state.collection.nameEN.replace(' ','-').toLowerCase());
      let urlPDF = encodeURI( IMAGE_SERVER + "/pdf?collectionID="+this.state.collection.id + '&urlQR=' + this.state.categorySlug+"/"+this.state.collection.slug );
      // console.log(this.state);
      return (
        <Layout match={this.props.match} history={this.props.history}>
          <Helmet>
            <title>{helmetTitle}</title>
          </Helmet>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
              {
                this.state.loaded ? (
                  <div className="row">
                    <div className="col-md-6">
                      <CategorySelect match={this.props.match} locale={interfaceLocale} defaultID={this.state.collection.categoryID} updateData={this.updateData} />
                      <input type="text" className="input-main-collection-page mb-4" placeholder={interfaceLocale['searchForm']} value={this.state.collection.nameRU} onChange={this._handleForm1.bind(this)} />
                      <input type="text" className="input-main-collection-page mb-5" placeholder={interfaceLocale['searchForm']} value={this.state.collection.nameEN} onChange={this._handleForm2.bind(this)} />

                      <form className="form-inline mb-2">
                        <div className="form-group">
                          <input type="text" className="input-main" placeholder={'Состав ткани'} value={this.state.collection.material} onChange={this._handleForm3.bind(this)} />
                        </div>
                        <div className="form-group">
                          <p className="text-muted input-text-example">{this.state.collection.material !== '' ? this.state.collection.material : interfaceLocale['desctiprion']['material']}</p>
                        </div>
                      </form>
                      <form className="form-inline mb-2">
                        <div className="form-group">
                          <input type="text" className="input-main" placeholder={'Стойкость'} value={this.state.collection.resistance} onChange={this._handleForm4.bind(this)} pattern="[0-9]*" inputMode="numeric" />
                        </div>
                        <div className="form-group">
                          <p className="text-muted input-text-example">{resistance +' '+ interfaceLocale['desctiprion']['resistance']}</p>
                        </div>
                      </form>
                      <form className="form-inline mb-2">
                        <div className="form-group">
                          <input type="text" className="input-main" placeholder={'Ширина ткани'} value={this.state.collection.width} onChange={this._handleForm5.bind(this)} pattern="[0-9]*" inputMode="numeric" />
                        </div>
                        <div className="form-group">
                          <p className="text-muted input-text-example">{this.state.collection.width +' '+ interfaceLocale['desctiprion']['fabricWidth']}</p>
                        </div>
                      </form>
                      <form className="form-inline mb-5">
                        <div className="form-group">
                          <input type="text" className="input-main" placeholder={'Плотность ткани'} value={this.state.collection.density} onChange={this._handleForm6.bind(this)} pattern="[0-9]*" inputMode="numeric" />
                        </div>
                        <div className="form-group">
                          <p className="text-muted input-text-example">{this.state.collection.density +' '+ interfaceLocale['desctiprion']['density']}</p>
                        </div>
                      </form>
                      <RichTextEditor
                        value={this.state.editor ? this.state.editor : RichTextEditor.createValueFromString('Описание коллекции','html')}
                        onChange={this._handleEditor}
                        className="editor-main mb-5"
                        toolbarClassName={'toolbar-category-description'}
                      />

                    </div>
                    <div className="col-md-6">
                      <div className="image-item" style={{marginBottom: 1+'rem'}}>
                        <Image url={collection.image} height={600} />
                        <Dropzone onDrop={this.onDrop.bind(this)}>
                          {({getRootProps, getInputProps}) => (
                            <section>
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="image-img-overlay">
                                  <h5 className="image-title">{interfaceLocale['imageUpload']}</h5>
                                </div>
                              </div>
                            </section>
                          )}
                        </Dropzone>
                      </div>
                      <div className="row">
                        <div className="col-md-7">
                          <div className="row">
                            <div className="col-md-12 mb-2">
                              <Link to={urlQR} target="_blank" className="btn btn-secondary btn-sm form-button"><i className="fas fa-qrcode"></i> QR-Code</Link>
                              <a className="btn btn-secondary btn-sm form-button " href={urlPDF} target="_blank" rel="noopener noreferrer"><i className="fas fa-file-pdf"></i> PDF</a>
                            </div>
                            <div className="col-md-12 mb-2">
                              {
                                this.state.presentation
                                ? (<><a href={CDN_SERVER + this.state.presentation.url} target="_blank" className="btn btn-secondary btn-sm mr-2 " rel="noopener noreferrer"><i className="fas fa-presentation"></i>  Открыть презентацию</a><button className="btn btn-secondary btn-sm form-button " onClick={()=>{this._handleClickDeletePresentation()}}><i className="fas fa-trash"></i></button></>)
                                : (<Dropzone onDrop={this.onDropPresentation.bind(this)}>
                                    {({getRootProps, getInputProps}) => (
                                      <section>
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <button className="btn btn-secondary btn-sm form-button "><i className="fas fa-presentation"></i> Загрузить презентацию</button>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>)
                              }
                            </div>
                            <div className="col-md-12 mb-2">
                              {
                                this.state.video
                                ? (<><a className="btn btn-secondary btn-sm form-button " href={this.state.video.url} target="_blank" rel="noopener noreferrer"><i className="fas fa-video"></i> Открыть видео</a><button className="btn btn-secondary btn-sm form-button " onClick={()=>{this._handleClickDeleteVideo()}}><i className="fas fa-trash"></i></button></>)
                                : <button className="btn btn-secondary btn-sm form-button " data-toggle="modal" data-target="#exampleModalCenter" onClick={()=>{this._handleClickSelectVideo()}}><i className="fas fa-video"></i> Загрузить видео</button>
                              }
                            </div>
                            <div className="col-md-12 mb-2">
                              {
                                this.state.certificate
                                ? (<><a href={CDN_SERVER + this.state.certificate.url} target="_blank" className="btn btn-secondary btn-sm mr-2 " rel="noopener noreferrer"><i className="fas fa-presentation"></i>  Открыть сертификат</a><button className="btn btn-secondary btn-sm form-button " onClick={()=>{this._handleClickDeleteCertificate()}}><i className="fas fa-trash"></i></button></>)
                                : (<Dropzone onDrop={this.onDropCertificate.bind(this)}>
                                    {({getRootProps, getInputProps}) => (
                                      <section>
                                        <div {...getRootProps()}>
                                          <input {...getInputProps()} />
                                          <button className="btn btn-secondary btn-sm form-button "><i className="fas fa-presentation"></i> Загрузить сертификат</button>
                                        </div>
                                      </section>
                                    )}
                                  </Dropzone>)
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="form-group row">
                            <label className="col-md-7 col-form-label">Видимость</label>
                            <div className="col-md-5 col-form-label">
                              <label className="custom-control material-switch">
                                <input type="checkbox" className="material-switch-control-input" defaultChecked={this.state.collection.active} onChange={this._handleCheckboxChangeActive} />
                                <span className="material-switch-control-indicator" />
                              </label>
                            </div>
                            <label className="col-md-7 col-form-label">Новинки</label>
                            <div className="col-md-5 col-form-label">
                              <label className="custom-control material-switch">
                                <input type="checkbox" className="material-switch-control-input" defaultChecked={this.state.collection.new} onChange={this._handleCheckboxChangeNew} />
                                <span className="material-switch-control-indicator" />
                              </label>
                            </div>
                            <label className="col-md-7 col-form-label">Распродажа</label>
                            <div className="col-md-5 col-form-label">
                              <label className="custom-control material-switch">
                                <input type="checkbox" className="material-switch-control-input" defaultChecked={this.state.collection.sale} onChange={this._handleCheckboxChangeSale} />
                                <span className="material-switch-control-indicator" />
                              </label>
                            </div>
                            <label className="col-md-7 col-form-label">Под заказ</label>
                            <div className="col-md-5 col-form-label">
                              <label className="custom-control material-switch">
                                <input type="checkbox" className="material-switch-control-input" defaultChecked={this.state.collection.custom} onChange={this._handleCheckboxChangeCustom} />
                                <span className="material-switch-control-indicator" />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-md-6">
                      <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(4,4)} /></p>
                      <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                      <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                      <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                      <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                      <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                      <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                    </div>
                    <div className="col-md-6">
                      <div className="image-item" style={{marginBottom: 1+'rem'}}>
                        <img src={svgPlaceholder} className="img-fluid rounded" alt="alt" />
                      </div>
                    </div>
                  </div>
                )
              }
              {
                this.state.loaded ?
                <div className="row">
                  <div className="col-md-12">
                    <FeaturesEditor collectionID={this.props.match.params.id} />
                  </div>
                  <div className="col-md-12">
                    <CaresEditor collectionID={this.props.match.params.id} />
                  </div>
                  <div className="col-md-12">
                    <FabricsEditor locale={interfaceLocale} defaultID={this.props.match.params.id} updateData={this.updateData} />
                  </div>
                </div>
                : ''
              }

              </div>
            </div>
          </div>

          <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-custom" role="document">
              <div className="modal-content">


                <div className="modal-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="text-center">Вставьте ссылку на видео</h5>
                        <input type="text" className="input-main-collection-page mb-4" placeholder={'https://www.youtube.com/watch?v=Ybz6P-l9YHc или https://vimeo.com/192593280'} value={this.state.modalInput} onChange={this._handleModalInput.bind(this)} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Закрыть</button>
                    {
                      this.state.modalInput !== '' ? <button type="button" className="btn btn-primary" onClick={()=>{this._handleClickSaveVideo()}}>Сохранить</button> : ''
                    }
                </div>
              </div>
            </div>
          </div>

        </Layout>
      );
    }
}

export default CollectionPage;

// <Link to={urlPDF} target="_blank" className="btn btn-secondary btn-sm form-button"><i class="fas fa-file-pdf"></i> PDF</Link>

// <picture className="intrinsic intrinsic--4x3 rounded">
//   {
//     collection.image ?
//     <>
//       <source srcset={CDN_SERVER + webpSource + '?h=600'} type="image/webp" />
//       <source srcset={CDN_SERVER + jpgSource + '?h=600'} type="image/jpeg" />
//       <img className="intrinsic-item rounded" src={CDN_SERVER + jpgSource + '?h=600'} alt="Collection" />
//     </>
//     : <img src={CDN_SERVER + '/images/no-image.jpg'} className="intrinsic-item rounded" alt="" />
//   }
// </picture>
// <button className="btn btn-secondary " onClick={this._handleChangeImage.bind(this)}>Изменить изображение</button>
