import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import FabricButtonDelete from './FabricButtonDelete'
import Image from '../Image'
import ColorsEditor from './ColorsEditor'
import update from 'immutability-helper';
import axios from 'axios'
import { httpRequest } from '../../Functions.js'
import { DOMAIN_NAME_API, IMAGE_SERVER } from '../../Constants'

// const Image = ({ url, height }) => {
//   let webpSource = url,
//       jpgSource = url.replace('.webp','.jpg');
//   return (
//     <picture className="intrinsic intrinsic--4x3 rounded">
//       {
//         url ?
//         <>
//           <source srcset={CDN_SERVER + webpSource + '?h=' + height} type="image/webp" />
//           <source srcset={CDN_SERVER + jpgSource + '?h=' + height} type="image/jpeg" />
//           <img className="intrinsic-item rounded" src={CDN_SERVER + jpgSource + '?h=' + height} alt="Collection" />
//         </>
//         : <img src={CDN_SERVER + '/static/crm/images/no-image.jpg'} className="intrinsic-item rounded" alt="" />
//       }
//     </picture>
//   )
// }


class FabricsItem extends Component {
  constructor(props) {
      super(props);
      let defaultFabric = {
        nameRU: '',
        nameEN: '',
        collectionID: '',
        image: '',
        rapport: '',
      }
      this.state = {
        fabric: this.props.fabric || defaultFabric,
        focused: false
      }
  }
  componentDidMount(){
    // axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
  componentWillUnmount(){
    this.setState(
      {
        fabric: {
          nameRU: '',
          nameEN: '',
          collectionID: '',
          image: '',
          rapport: ''
        },
        focused: false
      }
    )
  }
  // nameRU
  _handleForm1 = (event) => {
    const initialData = this.state.fabric;
    let newData = update(initialData, {nameRU: {$set: event.target.value}});
    if (this.props.type === "edit"){
      this.setState({ fabric: newData },()=> { this.saveFabric() });
    } else if (this.props.type === "new"){
      this.setState({ fabric: newData },()=> { this.createFabric() });
    }
  }
  // nameEN
  _handleForm2 = (event) => {
    const initialData = this.state.fabric;
    let newData = update(initialData, {nameEN: {$set: event.target.value}});
    if (this.props.type === "edit"){
      this.setState({ fabric: newData },()=> { this.saveFabric() });
    } else if (this.props.type === "new"){
      this.setState({ fabric: newData },()=> { this.createFabric() });
    }
  }
  _handleDeleteButton = () => {
    this.props.updateData('DELETE_FABRIC',this.props.fabricIndex);
    // this.setState(
    //   {
    //     nameRU: '',
    //     nameEN: '',
    //     collectionID: '',
    //     image: '',
    //     rapport: '',
    //   }
    // )
  }
  onDrop(files) {
    if(files.length === 1){
      let file = files[0];
      this.loadImageInServer(file);
    } else {
      files.forEach(file => {
        this.loadImageInServer(file)
      });
    }
  }
  onDrop2(files) {
    if(files.length === 1){
      let file = files[0];
      this.loadImageInServerRapport(file);
    } else {
      files.forEach(file => {
        this.loadImageInServerRapport(file)
      });
    }
  }
  loadImageInServer(image){

    let formData = new FormData();
    formData.append('image',image);

    httpRequest.post(IMAGE_SERVER + '/upload', formData)
    .then((data) => {
      const initialData = this.state.fabric;
      let newData = update(initialData, {image: {$set: data.imageURL}});
      if (this.props.type === "edit"){
        setTimeout(()=>{
          this.setState({ fabric: newData },()=> { this.saveFabric() });
        }, 1200)
      } else if (this.props.type === "new"){
        setTimeout(()=>{
          this.setState({ fabric: newData },()=> { this.createFabric() });
        }, 1200)
      }

    })
    .catch((error) => {
      console.log(error)
    });

    // let data = new FormData();
    // data.append('image',image);
    //
    // const config = {
    //     headers: { 'content-type': 'multipart/form-data' }
    // };

    // axios.post(IMAGE_SERVER + '/imageUpload2.php', data, config)
    //      .then((res) => {
    //        const initialData = this.state.fabric;
    //        let newData = update(initialData, {image: {$set: res.data.imageURL}});
    //        if (this.props.type === "edit"){
    //          this.setState({ fabric: newData },()=> { this.saveFabric() });
    //        } else if (this.props.type === "new"){
    //          this.setState({ fabric: newData },()=> { this.createFabric() });
    //        }
    // });
  }
  loadImageInServerRapport(image){

    let formData = new FormData();
    formData.append('image',image);

    httpRequest.post(IMAGE_SERVER + '/upload', formData)
    .then((data) => {
      const initialData = this.state.fabric;
      let newData = update(initialData, {rapport: {$set: data.imageURL}});
      if (this.props.type === "edit"){
        setTimeout(()=>{
          this.setState({ fabric: newData },()=> { this.saveFabric() });
        }, 1200)
      } else if (this.props.type === "new"){
        setTimeout(()=>{
          this.setState({ fabric: newData },()=> { this.createFabric() });
        }, 1200)
      }
    })
    .catch((error) => {
      console.log(error)
    });

    // let data = new FormData();
    // data.append('image',image);
    //
    // const config = {
    //     headers: { 'content-type': 'multipart/form-data' }
    // };

    // axios.post(IMAGE_SERVER + '/imageUpload2.php', data, config)
    //      .then((res) => {
    //        const initialData = this.state.fabric;
    //        let newData = update(initialData, {rapport: {$set: res.data.imageURL}});
    //        if (this.props.type === "edit"){
    //          this.setState({ fabric: newData },()=> { this.saveFabric() });
    //        } else if (this.props.type === "new"){
    //          this.setState({ fabric: newData },()=> { this.createFabric() });
    //        }
    // });
  }
  createFabric(){
    if (this.state.fabric.nameRU !== '' && this.state.fabric.nameEN !== '' && this.state.fabric.image !== '' && this.state.fabric.rapport !== '' && this.state.focused !== true){
      const url = DOMAIN_NAME_API + '/fabrics';
      const config = {
          headers: { 'token': localStorage.getItem('token') }
      };
      let fabric = this.state.fabric;
      fabric.collectionID = this.props.collectionID;
      var now = new Date();
      fabric.created = now.getTime()/1000;

      // this.props.updateData("ADD_FABRIC",this.state.fabric)

        axios.post(url, fabric, config)
        .then( (res) => {
          this.props.updateData("ADD_FABRIC",res.data);
          this.setState({
            fabric: {
              nameRU: '',
              nameEN: '',
              collectionID: '',
              image: '',
              rapport: ''
            },
            focused: false
          });
      } );
    }
  }
  saveFabric(){
    const url = DOMAIN_NAME_API + '/fabrics/'+this.state.fabric.id;
    let fabric = this.state.fabric;
    const config = {
        headers: { 'token': localStorage.getItem('token') }
    };
      axios.put(url, fabric, config)
  }
  _handleFocus = (e) => {
    if (this.props.type === "new"){
      this.setState({
        focused: true
      })
    }
  }
  _handleUnFocus = (e) => {
    if (this.props.type === "new"){
      this.setState({
        focused: false
      },()=>{ this.createFabric() })
    }
  }
  render() {
    let fabric = this.state.fabric;
    const interfaceLocale = this.props.locale;
    return (
      <div className="col-md-12 mb-3">
        <div className="row">
          <div className="col-md-2">
            <div className="image-item">
              <Image url={fabric.image} height={375} />
              <Dropzone onDrop={this.onDrop.bind(this)}>
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="image-img-overlay">
                        <h5 className="image-title">&nbsp;</h5>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
          <div className="col-md-2">
            <div className="image-item">
              <Image url={fabric.rapport} height={375} />
              <Dropzone onDrop={this.onDrop2.bind(this)}>
                {({getRootProps, getInputProps}) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="image-img-overlay">
                        <h5 className="image-title">&nbsp;</h5>
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>
          <div className="col-md-4">
            <input type="text" className="input-main-collection-page mb-4" placeholder={interfaceLocale['fabric']['nameRU']} value={this.state.fabric.nameRU} onChange={this._handleForm1.bind(this)} onFocus={this._handleFocus.bind(this)} onBlur={this._handleUnFocus.bind(this)} />
            <input type="text" className="input-main-collection-page mb-5" placeholder={interfaceLocale['fabric']['nameEN']} value={this.state.fabric.nameEN} onChange={this._handleForm2.bind(this)} onFocus={this._handleFocus.bind(this)} onBlur={this._handleUnFocus.bind(this)} />
          </div>
          <div className="col-md-4" style={{textAlign: 'right'}}>
            {
              this.state.fabric.nameRU !== '' && this.state.fabric.nameEN !== '' && this.state.fabric.image !== '' ? <FabricButtonDelete fabricID={this.state.fabric.id} locale={interfaceLocale} updateData={this._handleDeleteButton.bind(this)} /> : ''
            }
            {
              this.props.type === "edit" ? <ColorsEditor fabricID={this.state.fabric.id} /> : ''
            }
          </div>
        </div>
      </div>
    );
  }
}

export default FabricsItem;

// <ul className="nav">
//   {
//     colorList.map(
//       (color, index) => {
//             return (
//               <li className="nav-item" key={index}>
//                 <button className="nav-link color-button" style={{ color: color.interfaceCode ? color.interfaceCode : color.code }}>
//                   <i className="fas fa-circle"></i> <span>{color.nameRU}</span>
//                 </button>
//               </li>
//             )}
//     )
//   }
// </ul>

// <img src={fabric.image && fabric.image !== '' ? CDN_SERVER + fabric.image  + '?w=500' : CDN_SERVER + '/images/no-image.jpg'} className="img-fluid rounded" alt="alt" />
// <img src={fabric.rapport && fabric.rapport !== '' ? CDN_SERVER + fabric.rapport  + '?w=500' : CDN_SERVER + '/images/no-image.jpg'} className="img-fluid rounded" alt="alt" />

// function FabricItem() {
//   let fabric = this.props.fabric;
//   return (
//     <div className="col-md-12">
//       <div className="row">
//         <div className="col-md-2">
//           <img src={Generals.IMAGE_SERVER + fabric.image} className="img-fluid rounded" alt="alt" />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default FabricItem;
