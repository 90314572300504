import React, { useState, useEffect, useReducer, useContext } from 'react'
import Layout from '../components/Layout'
import {Helmet} from "react-helmet"
import SelectClient from '../common/SelectClientNeo'

import SelectFabrics from '../components/deliveries/SelectFabrics2'
import SuppliedFabrics from '../components/deliveries/SuppliedFabrics'
import { DOMAIN_NAME_API } from '../Constants'
import axios from 'axios'
import deliveryReducer from '../reducers/DeliveryReducer'
import DeliveryContext from '../context/DeliveryContext'
// import { addVAT, getVAT, formatPrice } from '../Functions'
import { currencyList } from '../Constants'

const SelectCurrency = (props) => {
  const [currency,setCurrency] = useState(0);
  const { dispatch } = useContext(DeliveryContext)

  const handleChangeCurrency = (e,index) => {
    e.preventDefault()
    setCurrency(index)
    dispatch({
      type: 'SELECT_CURRENCY',
      payload: index
    })
  }

  return <div className="col-md-3 mb-4">
    <div className="btn-group">
      <button value={currency} data-toggle="dropdown" className="btn btn-secondary dropdown-toggle margin" aria-expanded="false" style={{textTransform: 'none'}}>{currencyList[currency]} <span className="caret" /></button>
      <ul className="dropdown-menu" x-placement="bottom-start" >
        {
          currencyList.map( (currencyItem, index) => {
            return <li key={index}><button className="dropdown-item" style={{cursor:'pointer'}} onClick={ (e) => {handleChangeCurrency(e,index)} }>{currencyItem}</button></li>
          })
        }
      </ul>
    </div>
  </div>

}

function NewDeliveryPage(props){
  const [state, dispatch] = useReducer(deliveryReducer, {
    suppliers: [],
    suppliedFabrics: [],
    selectedSupplier: null,
    selectedCurrency: 0
  })

  useEffect(() => {
    axios.defaults.headers.common['token'] = localStorage.getItem('token')
    async function fetchData() {
      const response = await axios.get(`${DOMAIN_NAME_API}/clients?options=WHERE providerStatus=1`)
      // const response = await axios.get(`${DOMAIN_NAME_API}/clients`)
      dispatch({
        type: 'SET_SUPPLIERS',
        payload: response.data.clients
      })
    }
    fetchData()
  },[])

  const createDelivery = async () => {
    async function postData(){
      let formattedFabrics = []

      for (let fabric of state.suppliedFabrics) {
        formattedFabrics.push({
          fabricID: fabric.id,
          priceID: null,
          price: +fabric.price*100,
          length: +fabric.length*100,
          comments: ''
        })
      }

      let config = { headers: { 'content-type': 'application/json', 'token': localStorage.getItem('token') }}

      const response = await axios.post(DOMAIN_NAME_API + '/deliveries', {
        clientID: state.selectedSupplier.id,
        comments: state.comments || '',
        status: 1,
        currency: state.selectedCurrency,
        suppliedFabrics: formattedFabrics
      }, config);
      console.log(response)

      window.location = '/deliveries';
    }
    postData()
    // try {
    //
    // } catch (e) {
    //   console.log(e);
    // }


    // console.log('Оформить доставку',{
    //   clientID: state.selectedSupplier.id,
    //   comments: state.comments || '',
    //   status: 1,
    //   currency: state.selectedCurrency,
    //   suppliedFabrics: formattedFabrics
    // })

    // console.log('Оформить доставку',state.selectedSupplier,state.selectedCurrency,state.suppliedFabrics)
    // const response = await axios.post(DOMAIN_NAME_API + '/requests',
    //   {
    //     clientID: this.state.client.id,
    //     comments: this.state.comments,
    //     status: 1
    //   });

    // for (let fabric of state.suppliedFabrics) {
    //   await axios.post(DOMAIN_NAME_API + '/requestedFabrics',
    //   {
    //     requestID: response.data.id,
    //     fabricID: fabric.id,
    //     comments: "",
    //     length: +fabric.requestedLength*100
    //   })
    //     .catch((error)=>console.log(error))
    // }

  }

  let helmetTitle = 'Новая поставка - CRM Фабрика Фурнитуры'
  return (
    <DeliveryContext.Provider value={{ state, dispatch }}>
      <Layout match={props.match} history={props.history}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12">
              <h1>Поставка</h1>
            </div>
            <SelectClient items={state.suppliers} placeholder="Выбрать поставщика" customClass="mb-4" />
            <SelectCurrency />
            {
              state.selectedSupplier && state.selectedCurrency !== 0 ?
              <>
                {
                  state.suppliedFabrics.length !== 0 ?
                  <>
                    <div className="col-md-3 mb-4" style={{textAlign: 'right'}}>
                      <button className="btn btn-secondary mr-2" onClick={()=>{createDelivery()}}>Оформить</button>
                    </div>
                    <SuppliedFabrics items={state.suppliedFabrics} customClass="" />
                  </> : ''
                }
                <SelectFabrics />
              </>
              : ''
            }
          </div>
        </div>
      </Layout>
    </DeliveryContext.Provider>
  )
}

export default NewDeliveryPage

// const [suppliedFabrics, updateSuppliedFabrics] = useState([])
// const [selectedSupplier, setSelectedSupplier] = useState(null)

// const updateData = (value,data) => {
//   if(value && data){
//     switch(value) {
//       case 'ADD_CLIENT':
//         dispatch({
//           type: 'SELECT_SUPPLIER',
//           payload: data
//         })
//         break;
//       default:
//         break;
//     }
//   }
// }

// let price = 10000
// let priceWithVAT = addVAT(price)
// let test = formatPrice(priceWithVAT)
// let test2 = formatPrice(getVAT(priceWithVAT))
// <h5>{`Тестовый расчет НДС: сумма с НДС ${test} в том числе ${test2} НДС 20%`}</h5>
