import React, { Component } from 'react';
import SelectColor from './SelectColor';
import axios from 'axios'
import update from 'immutability-helper';
import { DOMAIN_NAME_API } from '../../Constants'
// import * as colorsJSON from './colors.json'
// const colors = colorsJSON.default.colors;

const colorsListRes = { data: { colors: [] } }
colorsListRes.data.colors =  [
  {
    id: 1,
    nameRU: 'Красный',
    nameEN: 'Red',
    code: '#ff0000',
    interfaceCode: '#e74c3c'
  },
  {
    id: 2,
    nameRU: 'Розовый',
    nameEN: 'Pink',
    code: '#f06eaa',
    interfaceCode: '#ea6ba1' // #c484a2
  },
  {
    id: 3,
    nameRU: 'Оранжевый',
    nameEN: 'Orange',
    code: '#f7941d',
    interfaceCode: '#e67e22'
  },
  {
    id: 4,
    nameRU: 'Желтый',
    nameEN: 'Yellow',
    code: '#fff200',
    interfaceCode: '#f1c40f'
  },
  {
    id: 5,
    nameRU: 'Зеленый',
    nameEN: 'Green',
    code: '#01a650',
    interfaceCode: '#04a454'
  },
  {
    id: 6,
    nameRU: 'Синий',
    nameEN: 'Blue',
    code: '#0072bb',
    interfaceCode: '#0574bc'
  },
  {
    id: 7,
    nameRU: 'Фиолетовый',
    nameEN: 'Purple',
    code: '#662d91',
    interfaceCode: '#74449c'
  },
  {
    id: 8,
    nameRU: 'Черный',
    nameEN: 'Black',
    code: '#000000',
    interfaceCode: '#060c11'
  },
  {
    id: 9,
    nameRU: 'Серый',
    nameEN: 'Gray',
    code: '#959595',
    interfaceCode: '#6e7279'
  },
  {
    id: 10,
    nameRU: 'Бейдж',
    nameEN: 'Beige',
    code: '#c6b299'
  },
  {
    id: 11,
    nameRU: 'Коричневый',
    nameEN: 'Brown',
    code: '#7d4900',
    interfaceCode: '#966413'
  },
  {
    id: 12,
    nameRU: 'Белый',
    nameEN: 'White',
    code: '#ffffff',
    interfaceCode: '#eeeeee' // #e9e9e6
  }
];

class ColorsEditor extends Component {
  constructor(props) {
      super(props);
      this.state = {
          colors: [],
          colorsList: [],
          downloaded: true,
          random: ''
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
    // axios.get(DOMAIN_NAME_API + '/colors/' + this.props.collectionID)
    // .then(res => {
    //   let formattedcolors = [];
    //   res.data.colors.forEach((item, i) => {
    //     let result = colors.filter( care => (care.id === item.colorID ) );
    //     let care = result[0];
    //     care.colorID = item.id;
    //     formattedcolors.push(care);
    //   });
    //   this.setState({
    //     colors: formattedcolors
    //   })
    // });

    axios.all([
      // axios.get(DOMAIN_NAME_API + '/data/colorsList'), // colorsListRes
      axios.get(DOMAIN_NAME_API + '/colors/' + this.props.fabricID)
    ])
    .then(axios.spread((colorsRes) => {

      let formattedColors = [];
      const colors = colorsListRes.data.colors;
      colorsRes.data.colors.forEach((item, i) => {
        let result = colors.filter( color => (color.id === item.colorID ) );
        let color = result[0];
        let newColor = {
          id: color.id,
          nameRU: color.nameRU,
          nameEN: color.nameEN,
          code: color.code,
          interfaceCode: color.interfaceCode || color.code,
          colorID: item.id
        }
        formattedColors.push(newColor);
      });
      this.setState({
        colorsList: colors,
        colors: formattedColors
      })
    }))
  }
  _handleRemove = (event,index) => {
    axios.delete(DOMAIN_NAME_API + '/colors/' + this.state.colors[index]['colorID'])
    .then(res => {
      this.setState((prevState) => ({
        colors: update(prevState.colors, {$splice: [[index, 1]]})
      }));
    })
    // console.log(this.state.colors[index]);
  }
  updateData = (value,data) => {
    if(data){
      axios.post(DOMAIN_NAME_API + '/colors',
      {colorID: data.id, fabricID: this.props.fabricID})
      .then(res => {
        data.colorID = res.data.id;
        this.setState({
          colors: update(this.state.colors, {$push: [data]})
        });
      });
    }
  }
    render() {
      let template = '',
          excludeColors = [];
      if (this.state.colors.length !== 0){
        template = this.state.colors.map((item, index) => {
          console.log('item',item);
            return (
              <span key={index} className="badge care-item" style={{ color: item.interfaceCode ? item.interfaceCode : item.code }}><i className="fas fa-circle"></i> <span>{item.nameRU}</span><span className="care-item-remove" onClick={(e)=>{this._handleRemove(e,index)}} >x</span></span>
            )
        });

        this.state.colorsList.forEach((item, i) => {
          let result = this.state.colors.filter( color => (color.id === item.id ) );
          if (result.length === 0) {
            let newColor = {
              id: item.id,
              nameRU: item.nameRU,
              nameEN: item.nameEN,
              code: item.code,
              interfaceCode: item.interfaceCode || item.code
            }
            excludeColors.push(newColor);
          }
        });

      } else {
        excludeColors = this.state.colorsList;
      }
      return (
        <div className="row">
          <div className="col-md-12">
            {
              this.state.downloaded ? (
                <div className="row">
                  <div className="col-md-12 mb-2" style={{ textAlign: 'left' }}>
                    { template }
                    <SelectColor updateData={this.updateData} items={excludeColors}  placeholder="Добавить цвет" />
                  </div>
                </div>
              ) : ''
            }
          </div>
        </div>
      );
    }
}

export default ColorsEditor;
