import React, { Component } from 'react';
import UniversalSelect from './UniversalSelect';
import axios from 'axios'
import update from 'immutability-helper';
import { DOMAIN_NAME_API } from '../../Constants'
// import * as featuresJSON from './features.json'
// const featuresData = featuresJSON.default.features;
// console.log('import',featuresData);

class FeaturesEditor extends Component {
  constructor(props) {
      super(props);
      this.state = {
          features: [],
          featuresList: [],
          downloaded: true,
          random: ''
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');

    axios.all([
      axios.get(DOMAIN_NAME_API + '/data/featuresList'),
      axios.get(DOMAIN_NAME_API + '/features/' + this.props.collectionID)
    ])
    .then(axios.spread((featuresListRes, featuresRes) => {
      // data = {
      //   originalUrl: req.originalUrl,
      //   categories: categoriesRes.data.categories,
      //   collections: collectionsRes.data.collections,
      // };

      let formattedFeatures = [];
      // let features = this.props.data;
      const features = featuresListRes.data.features;
      featuresRes.data.features.forEach((item, i) => {
        let result = features.filter( feature => (feature.id === item.featureID ) );
        let feature = result[0];
        let newFeature = {
          id: feature.id,
          nameRU: feature.nameRU,
          url: feature.url,
          featureID: item.id
        }
        // feature.featureID = item.id;
        formattedFeatures.push(newFeature);
        // formattedFeatures.push(feature);
      });
      this.setState({
        featuresList: features,
        features: formattedFeatures
      })
    }))

    // axios.get(DOMAIN_NAME_API + '/features/' + this.props.collectionID)
    // .then(res => {
    //   let formattedFeatures = [];
    //   // let features = this.props.data;
    //   const features = featuresData;
    //   res.data.features.forEach((item, i) => {
    //     let result = features.filter( feature => (feature.id === item.featureID ) );
    //     let feature = result[0];
    //     let newFeature = {
    //       id: feature.id,
    //       nameRU: feature.nameRU,
    //       url: feature.url,
    //       featureID: item.id
    //     }
    //     // feature.featureID = item.id;
    //     formattedFeatures.push(newFeature);
    //     // formattedFeatures.push(feature);
    //   });
    //   this.setState({
    //     features: formattedFeatures
    //   })
    // });
  }
  _handleRemove = (event,index) => {
    axios.delete(DOMAIN_NAME_API + '/features/' + this.state.features[index]['featureID'])
    .then(res => {
      this.setState((prevState) => ({
        features: update(prevState.features, {$splice: [[index, 1]]})
      }));
    })
    // console.log(this.state.features[index]);
  }
  updateData = (value,data) => {
    if(data){
      axios.post(DOMAIN_NAME_API + '/features',
      {featureID: data.id, collectionID: this.props.collectionID})
      .then(res => {
        data.featureID = res.data.id;
        this.setState({
          features: update(this.state.features, {$push: [data]})
        });
      });
    }
  }
    render() {
      let template = '',
          excludeFeatures = [];
      if (this.state.features.length !== 0){
        template = this.state.features.map((feature, index) => {
            return (
              <span key={index} className="badge badge-secondary feature-item">{feature.nameRU}<span className="feature-item-remove" onClick={(e)=>{this._handleRemove(e,index)}} >x</span></span>
            )
        });
        // excludeFeatures = shit.filter( item => (item.featureID === undefined ) )
        // excludeFeatures = this.props.data.filter( item => (item.featureID === undefined ) )
        this.state.featuresList.forEach((item, i) => {
          let result = this.state.features.filter( feature => (feature.id === item.id ) );
          if (result.length === 0) {
            // let feature = result[0];
            let newFeature = {
              id: item.id,
              nameRU: item.nameRU,
              url: item.url
            }
            excludeFeatures.push(newFeature);
          }
          // feature.featureID = item.id;
          // formattedFeatures.push(feature);
        });
        // excludeFeatures = shit.filter(feature => {
        //   const result = this.state.features.filter( item => (item.id === feature.id ) );
        //   console.log("result",result);
        //   if (result.length > 0) {
        //     return result;
        //   }
        // })

      } else {
        excludeFeatures = this.state.featuresList;
        // excludeFeatures = this.props.data;
      }
      // console.log('================== start =======================');
      // console.log('shit', shit);
      // console.log('this.state.features', this.state.features);
      // console.log('excludeFeatures', excludeFeatures);
      // console.log('=================== end ========================');
      return (
        <div className="row">
          <div className="col-md-12">
            {
              this.state.downloaded ? (
                <div className="row">
                <div className="col-md-12 mb-2"><h3>Характеристики</h3></div>
                  <div className="col-md-12 mb-2">
                    { template }
                    <UniversalSelect updateData={this.updateData} items={excludeFeatures} placeholder="Добавить новую характеристику" />
                  </div>
                </div>
              ) : ''
            }
          </div>
        </div>
      );
    }
}

export default FeaturesEditor;
