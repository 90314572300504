import React, { Component } from 'react';
import Layout from '../Layout';
import AddCategory from './AddCategory';
import ListItem from './ListItem';
import {Helmet} from "react-helmet";
import { DOMAIN_NAME_API } from '../../Constants'
import { randomInteger } from '../../Functions'

import axios from 'axios'
import * as localeRU from './locale_ru.json'
import * as localeEN from './locale_en.json'
const locale = {
    "ru":localeRU.default,
    "en":localeEN.default
  }

class Categories extends Component {
  constructor(props) {
      super(props);
      this.state = {
          categories: [],
          loaded: false
      };
  }
  componentDidMount() {
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
    this.reloadCategories();
  }
  reloadCategories(){

      fetch(DOMAIN_NAME_API + '/categories')
       .then(response => response.json())
       .then((data) => {
         // console.log(data);
         let categories = data.categories;
         this.setState({
           categories: categories,
           loaded: true
         });
      })
      .catch((err)=>{
        console.log(err);
      })

    // axios.get(DOMAIN_NAME_API + '/categories')
    // .then(res => {
    //   var categories = res.data.categories;
    //   this.setState({
    //     categories: categories,
    //     loaded: true
    //   })
    // });

  }
  updateData = (value) => {
    switch(value) {
      case 'ADD_CATEGORY':
        this.reloadCategories();
        console.log('ADD_CATEGORY');
        break;
      case 'UPDATE_CATEGORY':
        this.reloadCategories();
        console.log('UPDATE_CATEGORY');
        break;
        case 'DELETE_CATEGORY':
          this.reloadCategories();
          console.log('DELETE_CATEGORY');
          break;
      default:
        break;
    }
  }
    render() {
      const selectedLanguage = localStorage.getItem('lang');
      const interfaceLocale = locale[selectedLanguage];
      let helmetTitle = 'Категории - CRM Фабрика Фурнитуры';
      return (
        <Layout match={this.props.match} history={this.props.history}>
          <Helmet>
            <title>{helmetTitle}</title>
          </Helmet>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-12">
                <AddCategory locale={interfaceLocale} updateData={this.updateData} />
                <div className="row">
                { this.state.loaded ?
                  (this.state.categories ? (
                    this.state.categories.map((category, index) => {
                        return (
                          <ListItem key={index} locale={interfaceLocale} updateData={this.updateData} category={category} />
                        )
                    })
                  ) : '') :
                  (
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                        </div>
                        <div className="col-md-3">
                          <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                        </div>
                        <div className="col-md-4">
                          <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                        </div>
                        <div className="col-md-3">
                          <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                        </div>
                        <div className="col-md-4">
                          <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                        </div>
                        <div className="col-md-3">
                          <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                        </div>
                        <div className="col-md-4">
                          <p><i className={"loading-placeholder fas fa-square-full-"+randomInteger(3,7)} /></p>
                        </div>
                      </div>
                    </div>
                  )
                }
                </div>
              </div>
            </div>
          </div>
        </Layout>
      );
    }
}

export default Categories;

// {
//   this.state.categories ? (
//     this.state.categories.map((category, index) => {
//         return (
//           <div className="col-md-12" key={index}>
//             <div className="row">
//               <div className="col-md-3">
//                 <p>{category.nameRU}</p>
//               </div>
//               <div className="col-md-3">
//                 <p>{category.nameEN}</p>
//               </div>
//               <div className="col-md-4">
//                 <button type="submit" className="btn btn-primary form-button mb-4">{interfaceLocale['categoryItem']['buttonChange']}</button>
//                 <button type="submit" className="btn btn-primary form-button mb-4">{interfaceLocale['categoryItem']['buttonDelete']}</button>
//               </div>
//             </div>
//           </div>
//         )
//     })
//   ) : ''
// }
