import React, { useState } from 'react';
import { toFormatRollLength, formatFloat } from '../../Functions'
import axios from 'axios'
import { DOMAIN_NAME_API } from '../../Constants'
axios.defaults.headers.common['token'] = localStorage.getItem('token');

function RollItem( props ){
  // console.log(props);
  const [roll, changeRoll] = useState(props.roll)
  const [cutLength, setCutLength] = useState(roll.requestedLength <= roll.balance ? roll.requestedLength/100 : roll.balance/100)

  const changeHandler = (event) => {
    // parseFloat(event.target.value.replace(/[^.\d]+/g,"").replace( /^([^\.]*\.)|\./g, '$1' ))
    // eslint-disable-next-line
    let value = event.target.value.replace(/[^.\d]+/g,"").replace( /^([^\.]*\.)|\./g, '$1' );
    if (+value <= +roll.requestedLength/100 && +value <= +roll.balance/100) {
      setCutLength(value)
    }
  }

  const cutRoll = async (roll,requestedCut) => {
    try {
      const response = await axios.put(`${DOMAIN_NAME_API}/rolls/${roll.id}`,{ balance: ((+roll.balance)-(+requestedCut*100)), virgin: 0 });
      if (response.data.status === 'ok') {
        changeRoll({
          balance: response.data.roll.balance,
          status: 1,
          virgin: 0
        })

        const newRoll = await axios.post(`${DOMAIN_NAME_API}/rolls`,{
          fabricID: roll.fabricID,
          balance: requestedCut*100,
          status: 1,
          virgin: 0
        });

        props.updateData('ADD_ROLL',newRoll.data.roll);
        // console.log('newRoll', newRoll.data.roll);
      }
    } catch (e) {
      console.error(e)
    }
  }

  const defectRoll = (data) => {
    props.updateData('DELETE_ROLL',data);
  }

  // console.log(roll.balance,cutLength*100);
  let ostatok = formatFloat(((+roll.balance)-(+cutLength*100))/100);
  return (
    <div className="col-md-12">
      <div className="roll-block">
        <div className="roll-title">
          <h5>{roll.balance/100 + ' ' + toFormatRollLength(roll.balance/100)}</h5>
        </div>
        <div className="roll-item-with-input">
          <input
            type="text"
            className="input-cut"
            name="length"
            placeholder={0}
            value={cutLength}
            onChange={changeHandler}
          />
        </div>
        <div className="roll-cut">
          <button className="btn btn-secondary btn-lg" onClick={()=>{cutRoll(roll,+cutLength)}}>Отрезать {(cutLength !== '' ? cutLength : 0) + ' ' + toFormatRollLength(cutLength)}</button>
        </div>
        <div className="roll-cut">
          <button className="btn btn-secondary btn-lg" onClick={()=>{defectRoll({ index: props.arrayIndex })}}>Брак {(cutLength !== '' ? cutLength : 0) + ' ' + toFormatRollLength(cutLength)}</button>
        </div>
        <div className="roll-item">
          <h5>
            {
              ostatok > 0 ? 'Остаток ' + ostatok + ' ' + toFormatRollLength(ostatok) : ''
            }
          </h5>
        </div>
      </div>
    </div>
  )
}

export default RollItem;
// <button className="btn btn-secondary btn-lg">Отрезать {(cutLength.length !== '' ? cutLength.length : 0) + ' ' + toFormatRollLength(cutLength.length)}</button>

// export default ({ roll }) => {
//   let zapas = 0;
//   let cut = 0;
//     return (
//       <div className="col-md-12">
//         <div className="roll-block">
//           <div className="roll-title">
//             <h5>{roll.balance + ' ' + toFormatRollLength(roll.balance)}</h5>
//           </div>
//           <div className="roll-item">
//             <input type="text" className="input-main" placeholder={0} value={cut} onChange={this._handleSearchForm.bind(this)}/>
//           </div>
//           <div className="roll-item">
//             <h5>{roll.balance + ' ' + toFormatRollLength(roll.balance)}</h5>
//           </div>
//           {
//             // roll.balance > roll.requestedLength ?
//             // <>
//             //   <div className="roll-cut">
//             //     <h5></h5>
//             //     <button className="btn btn-secondary btn-lg">Отрезать {formatFloat(roll.requestedLength+zapas) + ' ' + toFormatRollLength(roll.requestedLength+zapas)}</button>
//             //   </div>
//             //   <div className="roll-otval">
//             //     <h5>Остаток: {formatFloat(roll.balance-roll.requestedLength-zapas) + ' ' + toFormatRollLength(roll.balance-roll.requestedLength-zapas)}&nbsp;&nbsp;&nbsp; Запас: {zapas + ' ' + toFormatRollLength(zapas)}</h5>
//             //   </div>
//             // </>
//             // : <div className="roll-otval">
//             //     <h5>Недостаточно длины рулона</h5>
//             //   </div>
//           }
//         </div>
//       </div>
//     );
// };

// <div className="roll-cut">
//   <h5>Отрезать {roll.requestedLength + ' ' + declOfNum(roll.requestedLength*10, ['метр', 'метра', 'метров'])}</h5>
// </div>
