export default function(state,action){
  switch (action.type) {
    case "INITIAL_PRICELISTS":
      return {
        ...state, priceLists: action.payload
      }
    case "INITIAL_COLLECTIONS":
      return {
        ...state, collections: action.payload
      }
    // case "UPDATE_PRICELISTS":
    //   let initialPriceLists = state.priceLists
    //   if (initialPriceLists[action.payload.index]['prices'].length !== 0) {
    //
    //   } else {
    //
    //   }
    //
    //   return {
    //     ...state, priceLists: action.payload.prices
    //   }
    case "SELECT_PRICELIST":
      return {
        ...state,
        selectedPriceList: action.payload.index,
        selectedPriceListID: action.payload.id
      }
    case "ADD_FABRIC":
      return {
        ...state, 
        suppliedFabrics: [...state.suppliedFabrics, action.payload]
      }
    case "ADD_COLLECTION_PRICE":
      let initialCollections = state.collections
      let collectionIndex = initialCollections.findIndex(item => item.id === action.payload.resPrice.collectionID)
      initialCollections[collectionIndex]['prices'].push(action.payload.resPrice)

      return {
        ...state,
        initialCollections
      }
    case "ADD_FABRIC_PRICE":
      let initialCollections2 = state.collections
      // let collectionIndex = initialCollections.findIndex(item => item.id === action.payload.collectionID)
      // initialCollections[collectionIndex]['prices'].push(action.payload)
      console.log(action.payload)
      return {
        ...state,
        initialCollections2
      }
    case "DELETE_FABRIC":
      // assigning the list to temp variable
      const temp = [...state.suppliedFabrics];
      // removing the element using splice
      temp.splice(action.payload.rowID, 1);

      return {
        ...state, suppliedFabrics: temp
      }
    default:
      return state
  }
}
