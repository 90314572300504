import React, { Component } from 'react';
// import { DOMAIN_NAME_API } from '../../Constants'
import { toFormatRollLength, formatFloat } from '../../Functions'
import axios from 'axios'
// import Image from '../Image'

const statusColors = ["#BDC3C7","#3FC380","#3498db"];

class RequestItem extends Component {
  constructor(props) {
      super(props);
      this.state = {
        fabricsVisible: false
      }
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
  openFabrics(){
    this.setState({
      fabricsVisible: this.state.fabricsVisible ? false : true
    })
  }
render() {
  let item = this.props.item,
      totalBalance = 0;
  let template = item.fabrics.map((fabricItem,index)=>{
    totalBalance = totalBalance + fabricItem.length;
    return  <tr key={index}>
              <td>{fabricItem.description.collection.nameEN} {fabricItem.description.nameEN}</td>
              <td>{fabricItem.length/100 + ' ' + toFormatRollLength(fabricItem.length/100)}</td>
            </tr>
    // return (<p key={index} style={{paddingLeft: 1+'rem'}}>{fabricItem.length + ' ' + declOfNum(fabricItem.length*10, ['метр', 'метра', 'метров'])}</p>)
  })
  return (
    <div className="requests-block ml-4">
      <div className="requests-item-title">
        <h5><em className="fa fa-circle pr-2" style={{color: statusColors[item.status]}} />{item.client.nameRU}</h5>
        <p>
            <i className="fa fa-clock-o"></i>&nbsp;&nbsp;{item.date.format('HH:mm')}
            &nbsp;&nbsp;
            <i className="fa fa-calendar"></i>&nbsp;&nbsp;{item.date.format('DD MMMM YYYY')}
        </p>
      </div>
      <div className="requests-item-description">
        {
          this.state.fabricsVisible ?
          <>
            <p onClick={()=>{this.openFabrics()}}>{formatFloat(totalBalance/100) + ' ' + toFormatRollLength(totalBalance/100)}</p>
            <table className="table table-borderless table-sm" style={{marginLeft: 1+'rem'}}><tbody>{template}</tbody></table>
          </>
          : <p onClick={()=>{this.openFabrics()}}>{formatFloat(totalBalance/100) + ' ' + toFormatRollLength(totalBalance/100)} {'('+item.fabrics.length+')'}</p>
        }
      </div>
      <div className="requests-item-message">
          <p>{item.comments}</p>
      </div>
    </div>

  );
  }
}

export default RequestItem;

// <div className="col-md-12 mb-3">
//   <div className="row">
//     <div className="col-md-4">
//       <h5>{item.client.nameRU}</h5>
//       <p>
//           <i className="fa fa-clock-o"></i>&nbsp;&nbsp;{item.date.format('HH:mm')}
//           &nbsp;&nbsp;
//           <i className="fa fa-calendar"></i>&nbsp;&nbsp;{item.date.format('DD MMMM YYYY')}
//       </p>
//     </div>
//     <div className="col-md-3 pl-0">
//       {
//         this.state.fabricsVisible ?
//         <>
//           <p style={{marginTop: 1+'rem'}} onClick={()=>{this.openFabrics()}}>{formatFloat(totalBalance) + ' ' + declOfNum(formatFloat(totalBalance)*10, ['метр', 'метра', 'метров'])}</p>
//           {template}
//         </>
//         : <p style={{marginTop: 1+'rem'}} onClick={()=>{this.openFabrics()}}>{formatFloat(totalBalance) + ' ' + declOfNum(formatFloat(totalBalance)*10, ['метр', 'метра', 'метров'])} {'('+item.fabrics.length+')'}</p>
//       }
//     </div>
//     <div className="col-md-3">
//
//     </div>
//   </div>
// </div>
