import React, { useEffect, useReducer } from 'react'
import Layout from '../components/Layout'
import { Helmet } from "react-helmet"
import { Link } from 'react-router-dom';
import deliveryReducer from '../reducers/DeliveryReducer'
import DeliveryContext from '../context/DeliveryContext'
import axios from 'axios'
import { DOMAIN_NAME_API } from '../Constants'
import DeliveryItem from '../components/deliveries/DeliveryItem'

function DeliveriesPage(props){
  const [state, dispatch] = useReducer(deliveryReducer, {
    deliveries: []
  })

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${DOMAIN_NAME_API}/deliveries`)
      dispatch({
        type: 'INITIAL_DELIVERIES',
        payload: response.data.deliveries
      })
      console.log(response.data.deliveries)
    }
    fetchData()
  },[])

  let helmetTitle = 'Поставки - CRM Фабрика Фурнитуры'
  return (
    <DeliveryContext.Provider value={{ state, dispatch }}>
      <Layout match={props.match} history={props.history} title={<Link to={'/deliveries/new'} className="btn btn-secondary ml-2">{'Новая поставка'}</Link>}>
        <Helmet>
          <title>{helmetTitle}</title>
        </Helmet>
        <div className="col-md-12">
          <div className="row pl-4 pr-4">
            {
              state.deliveries.map( (delivery) => {
                return <DeliveryItem delivery={delivery} key={delivery.id} />
              })
            }
          </div>
        </div>
      </Layout>
    </DeliveryContext.Provider>
  )
}

export default DeliveriesPage

// <div className="col-md-12">
//   <h5>Список поставок</h5>
// </div>
