import React, { Component } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { DOMAIN_NAME_API } from '../../Constants'

class ListItem extends Component {
  constructor(props) {
      super(props);
      this.state = {
        form1: this.props.category.nameRU || '',
        form2: this.props.category.nameEN || '',
        image: this.props.category.image || null,
        active: this.props.category.active || 0,
        description: this.props.category.description || 'Описание категории',
        clickToConfirm: {
          updateButton: false,
          deleteButton: false
        }
      };
  }
  componentDidMount(){
    axios.defaults.headers.common['token'] = localStorage.getItem('token');
  }
// _handleClick(){
//
//   console.log("signin",this.state);
//   // axios.post(Generals.DOMAIN_NAME_API + '/categories',
//   //   {
//   //     nameRU: this.state.form1,
//   //     nameEN: this.state.form2
//   //   })
//   // .then(
//   //   () => {
//   //     this.setState({ form1: '', form2: '' });
//   //     this.props.updateData('ADD_CATEGORY');
//   //   }
//   // )
//
// }
_handleForm1 = (event,categoryID) => {
  // event.preventDefault();
  event.persist();
  this.setState({form1: event.target.value},()=>{ this._handleClickToConfirm(event,"confirmChange",categoryID) });
}
_handleForm2 = (event,categoryID) => {
  // event.preventDefault();
  event.persist();
  this.setState({form2: event.target.value},()=>{ this._handleClickToConfirm(event,"confirmChange",categoryID) });
}
_handleCheckboxChangeActive = event => {
  let status = (this.state.active === 0 ? 1 : 0);
  this.setState( { status: status } );

  const url = DOMAIN_NAME_API + '/categories/status';
  const config = {
      headers: { 'token': localStorage.getItem('token') }
  };

  axios.put(url, {
    categoryID: this.props.category.id,
    active: status
  }, config);
}
_handleClickToConfirm(e,buttonName,categoryID){
  e.preventDefault();
  e.stopPropagation();
  switch (buttonName) {
    case "buttonChange":
    // console.log(e,buttonName,categoryID);
      this.setState({
        clickToConfirm: {
          updateButton: true,
          deleteButton: false
        }
      });
      break;
      case "confirmChange":
      // console.log(e,buttonName,categoryID);
      const url = DOMAIN_NAME_API + '/categories/'+categoryID;
        axios.put(url,
          {
            nameRU: this.state.form1,
            nameEN: this.state.form2,
            image: this.state.image,
            description: this.state.description
          })
        .then(
          () => {
            this.setState({
              clickToConfirm: {
                updateButton: false,
                deleteButton: false
              }
            });
            this.props.updateData('UPDATE_CATEGORY');
          }
        )
        break;
      case "buttonDelete":
      console.log(e,buttonName,categoryID);
        this.setState({
          clickToConfirm: {
            updateButton: false,
            deleteButton: true
          }
        });
        break;
        case "confirmDelete":
        // console.log(e,buttonName,categoryID);
          axios.delete(DOMAIN_NAME_API + '/categories/'+categoryID)
          .then(
            () => {
              this.setState({
                clickToConfirm: {
                  updateButton: false,
                  deleteButton: false
                }
              });
              this.props.updateData('DELETE_CATEGORY');
            }
          )
          break;
    default:
      break;
  }
}
render() {
  const interfaceLocale = this.props.locale;
  let category = this.props.category;
  // let index = this.props.key;
  return (
    <div className="col-md-12 mb-3">
      <div className="row">
        <div className="col-md-3">
          <input type="text" className={"input-main-collection-page mb-1 "+ this.state.form1Class} placeholder={interfaceLocale['addCategory']['form1']} value={this.state.form1} onChange={(e)=>{this._handleForm1(e,category.id)}} />
        </div>
        <div className="col-md-3">
          <input type="text" className={"input-main-collection-page mb-1 "+ this.state.form2Class} placeholder={interfaceLocale['addCategory']['form2']} value={this.state.form2} onChange={(e)=>{this._handleForm2(e,category.id)}} />
        </div>
        <div className="col-md-6">
        {
          this.state.clickToConfirm.deleteButton ?
          <button type="submit" className="btn btn-secondary form-button mb-1" onClick={(e)=>{ this._handleClickToConfirm(e,"confirmDelete",category.id) }}>{interfaceLocale['categoryItem']['buttonConfirm']}</button> :
          <button type="submit" className="btn btn-secondary form-button mb-1" onClick={(e)=>{ this._handleClickToConfirm(e,"buttonDelete",category.id) }}>{interfaceLocale['categoryItem']['buttonDelete']}</button>
        }
        <Link className={"btn btn-secondary form-button mb-1"} to={"/categories/"+ category.id}>{interfaceLocale['categoryItem']['buttonEdit']}</Link>
        <label className="custom-control material-switch mb-1" style={{display: 'inline-block'}}>
          <input type="checkbox" className="material-switch-control-input" defaultChecked={this.state.active} onChange={this._handleCheckboxChangeActive} />
          <span className="material-switch-control-indicator" />
        </label>
        </div>
      </div>
    </div>
  );
  }
}

export default ListItem;

// {
//   this.state.clickToConfirm.updateButton ?
//   <input type="text" className={"input-main mb-4 "+ this.state.form1Class} placeholder={interfaceLocale['addCategory']['form1']} value={this.state.form1} onChange={this._handleForm1.bind(this)} required /> :
//   <h6 className="input-title">{this.state.form1 !== '' ? this.state.form1 : category.nameRU}</h6>
// }
// </div>
// <div className="col-md-3">
// {
//   this.state.clickToConfirm.updateButton ?
//   <input type="text" className={"input-main mb-4 "+ this.state.form2Class} placeholder={interfaceLocale['addCategory']['form2']} value={this.state.form2} onChange={this._handleForm2.bind(this)} required /> :
//   <h6 className="input-title">{this.state.form2 !== '' ? this.state.form2 : category.nameEN}</h6>
// }
// </div>
// <div className="col-md-4">
// {
//   this.state.clickToConfirm.updateButton ?
//   <button type="submit" className="btn btn-primary form-button mb-4" onClick={(e)=>{ this._handleClickToConfirm(e,"confirmChange",category.id) }}>{interfaceLocale['categoryItem']['buttonConfirm']}</button> :
//   <button type="submit" className="btn btn-primary form-button mb-4" onClick={(e)=>{ this._handleClickToConfirm(e,"buttonChange",category.id) }}>{interfaceLocale['categoryItem']['buttonChange']}</button>
// }
// {
//   this.state.clickToConfirm.deleteButton ?
//   <button type="submit" className="btn btn-primary form-button mb-4" onClick={(e)=>{ this._handleClickToConfirm(e,"confirmDelete",category.id) }}>{interfaceLocale['categoryItem']['buttonConfirm']}</button> :
//   <button type="submit" className="btn btn-primary form-button mb-4" onClick={(e)=>{ this._handleClickToConfirm(e,"buttonDelete",category.id) }}>{interfaceLocale['categoryItem']['buttonDelete']}</button>
// }
